@use 'sass:color';

$text: #1a1a1a !default;
$gl-indigo: #e6197f !default;
$gl-purple: #92278f !default;
$in-yellow: #d7df23 !default;
$in-green: #39b54a !default;
$co-red: #ae1b1f !default;
$co-orange: #f7941d !default;
$ma-cyan: #27aae1 !default;
$ma-blue: #262262 !default;
$sl-gray: color.mix(#1a1a1a, white, 10%) !default;
$round-button: 500px !default;
