.tokenize > .tokens-container {
  position: relative;
  list-style: none;
  padding: 0 0 5px 5px;
  height: auto;
  min-height: 34px;
  cursor: text;
  margin: 0 0 10px 0;
}

.tokenize.focus > .tokens-container {
  outline: 0;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.tokenize > .tokens-container.input-sm {
  padding: 0 0 4px 4px;
  min-height: 30px;
}

.tokenize > .tokens-container.input-lg {
  padding: 0 0 9px 9px;
  min-height: 46px;
}

.tokenize > .tokens-container > .token {
  padding: 0 1.2em 0 5px;
  background-color: #eff2f7;
  border-radius: 2px;
}

.tokenize > .tokens-container > .token, .tokenize > .tokens-container > .placeholder, .tokenize > .tokens-container > .token-search {
  border: 1px solid #cdd5e3;
  display: inline-block;
  margin: 5px 5px 0 0;
  position: relative;
  vertical-align: middle;
}

.tokenize.sortable > .tokens-container > .token {
  cursor: move;
}

.tokenize.single > .tokens-container > .token {
  display: block;
  border-color: #fff;
  background-color: transparent;
}

.tokenize.sortable > .tokens-container > .token.shadow {
  border-color: #ccc;
  background-color: #ccc;
  filter: alpha(opacity=50);
  opacity: 0.2;
}

.tokenize > .tokens-container > .placeholder, .tokenize > .tokens-container > .token-search {
  padding: 0;
  border-color: #fff;
}

.tokenize > .tokens-container > .placeholder {
  color: #ccc;
}

.tokenize > .tokens-container > .token-search > input {
  padding: 0;
  margin: 0;
  line-height: 1em;
  border: 1px solid #fff;
  background: transparent;
  border-left: none;
  border-right: none;
  outline: none;
  width: 100%;
}

.tokenize > .tokens-container > .token-search > input::-ms-clear {
  display: none;
}

.tokenize > .tokens-container.input-sm > .placeholder, .tokenize > .tokens-container.input-sm > .token-search, .tokenize > .tokens-container.input-sm > .token {
  margin: 4px 4px 0 0;
}

.tokenize > .tokens-container.input-lg > .placeholder, .tokenize > .tokens-container.input-lg > .token-search, .tokenize > .tokens-container.input-lg > .token {
  margin: 9px 9px 0 0;
}

.tokenize > .tokens-container > .token.pending-delete {
  background-color: #5b72a4;
  border-color: #425c96;
  color: #fff;
}

.tokenize > .tokens-container > .token > .dismiss {
  position: absolute;
  right: 5px;
  color: #a9b9d8;
  text-decoration: none;
  cursor: pointer;
}

.tokenize > .tokens-container > .token.pending-delete > .dismiss {
  color: #aaa;
}

.tokenize-dropdown {
  position: absolute;
  display: none;
}

.tokenize-dropdown > .dropdown-menu {
  min-height: 10px;
  width: 100%;
  display: block;
  margin: -1px 0 0 0;
}

.tokenize-dropdown > .dropdown-menu li {
  cursor: pointer;
}

.tokenize-dropdown > .dropdown-menu li > a .tokenize-highlight {
  font-weight: bold;
}

.tokenize-dropdown > .dropdown-menu li:not(.active) a:hover, .tokenize-dropdown > .dropdown-menu li:not(.active) a:focus {
  background-color: transparent;
}

.form-control {
  //   display: block;
  width: 100%;
  height: 34px;
  // padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
