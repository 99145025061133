@use 'sass:math';
@use 'sass:color';

@import 'colors';
@import 'normalize';
@import 'tokenize2';
@import 'html/fontawesome/scss/fontawesome.scss';
@import 'html/fontawesome/scss/brands.scss';
@import 'html/fontawesome/scss/duotone.scss';
@import 'html/fontawesome/scss/light.scss';
@import 'html/fontawesome/scss/regular.scss';
@import 'html/fontawesome/scss/solid.scss';
@import 'html/fontawesome/scss/thin.scss';

* {
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
  width: 100%;
}

html {
  font-size: 16px;
}

@keyframes use-move {
  0% {
    bottom: -1000px;
  }

  100% {
    bottom: 0;
  }
}
@keyframes use-remove {
  0% {
    bottom: 0;
  }

  100% {
    bottom: -1000px;
  }
}

.use {
  position: fixed;
  bottom: 0;
  z-index: 9;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 20px;
  background: $sl-gray;
  border-top: solid 2px $text;
  animation: use-move 0.75s ease;

  &.accepted {
    bottom: -1000px;
    width: 100%;
    animation: use-remove 0.75s ease;
  }

  &__actions {
    padding: 0 0 0 20px;
    border-left: solid 1px rgba(black, 0.5);

    #accept--btn {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.bookmarks {
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  li.bookmark--items {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &.use--inline {
      height: 22px !important;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 40px);
    }

    input,
    .checkbox,
    label,
    &.bookmarks__none {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    label {
      height: 30px;
    }

    button {
      width: 40px;
      transform: scale(0.6, 0.6);

      &#cookie_policy {
        padding: 0;
        background: none;
        border: none;
        transform: scale(1, 1);
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
      border-bottom: solid 1px rgba($text, 0.25);
    }
  }
}

body {
  background-color: color.mix($text, white, 2);

  body {
    &.video,
    &.faqs,
    &.customer-discovery,
    &.training {
      .trails {
        margin-bottom: 0;
      }
    }
  }

  .wrap {
    width: 100%;
    margin: 0 auto;
    @media only screen and (min-device-width: 0) and (max-device-width: 1024px) {
      width: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text;
    font-weight: 400;
    font-family: Nunito, sans-serif;
    line-height: math.div(38px, 16px);
    text-decoration: none;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 1.5;

    &.rad-grp {
      margin-bottom: 0;
      font-weight: 400 !important;
      font-size: 13px;
    }
  }

  p,
  form {
    color: $text;
    font-weight: 400;
    font-size: 16px;
    font-family: Nunito, sans-serif;
    line-height: 1.5;
    text-decoration: none;

    &.copre {
      font-weight: 700 !important;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

/* NAVIGATION */
header {
  z-index: 90;

  nav {
    a {
      color: $text;
      text-decoration: none;
    }
  }
}

.nav {
  &__bar {
    display: flex;
    flex-flow: row wrap;
    gap: 16px 160px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 16px;

    @media only screen and (max-width: 768px) {
      gap: 16px 0;
      gap: 0;
    }

    &--logo {
      order: 2;

      #global-logo {
        width: 200px;

        @media only screen and (max-width: 768px) {
          width: 130px;
        }
      }

      @media only screen and (max-width: 768px) {
        order: 3;
      }
    }

    &--menus {
      order: 3;
      padding-top: 16px;

      @media only screen and (max-width: 768px) {
        order: 2;
        padding-top: 0;
      }
    }
  }

  &__top {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    justify-content: flex-end;
    order: 1;
    width: 100%;

    a {
      font-size: math.div(14rem, 16);
      letter-spacing: -0.1px;

      &:hover {
        color: black;
        text-decoration: underline;
      }
    }

    @media only print {
      display: none;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 16px;
    }
  }

  &__bottom {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      width: 28px;
    }

    .menu {
      z-index: 91 !important;
      display: flex;
      flex-flow: row nowrap;
      align-self: flex-end;
      justify-content: space-between;
      width: 100% !important;
      margin: 0;
      padding: 0;
      transform: translate3d(0, 0, 1px);

      @media only screen and (max-width: 768px) {
        height: 28px;
      }

      &--item {
        order: 2;

        &:not(:first-of-type) {
          align-self: flex-start;
          order: 1;
        }

        @media only screen and (max-width: 768px) {
          order: 1;
          width: 28px;
          font-size: math.div(24px, 16px) + rem;

          &:not(:first-of-type) {
            order: 2;
          }

          &:first-of-type {
            height: 28px !important;
          }
        }
        @media only screen and (max-width: 768px) {
          &:hover,
          &:active,
          &:focus,
          &:focus-within {
            // & > li {
            display: inline-block;

            // }

            .drop-down__menu {
              top: 0;
              left: 0;
              z-index: 999;
              display: block;
              margin: 0;
              font-size: math.div(16px, 16px) + rem;
              visibility: visible !important;
              opacity: 1 !important;

              a {
                flex-flow: column nowrap !important;
              }
            }
          }
        }

        @media only print {
          display: none;
        }
      }

      &-item {
        display: inline-block;
      }

      &-initiator {
        display: none;
        width: 28px;
        height: 28px;
        border-radius: 5px;

        @media only screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      & li {
        list-style-type: none;

        &.active {
          cursor: pointer;
        }
      }
    }

    .menu-items {
      z-index: 99;
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      justify-content: flex-end;
      float: left;
      padding-left: 0 !important;
      white-space: nowrap;

      @media only screen and (max-width: 768px) {
        justify-content: space-around;
        height: 0;
        margin-top: 0;
      }
    }

    .drop-down {
      &__menu {
        gap: 0 16px;

        @media only screen and (max-width: 768px) {
          position: relative;
          top: -18px;
          left: -999em;
          gap: 0;
          padding: 2px 0 0;
          opacity: 0;
          transition: top ease-in-out 0.25s, opacity ease-in-out 0.25s, left ease 0s;
        }

        &--item {
          display: flex;
          align-self: flex-end;
          justify-content: space-around;

          // margin: 0 10px 10px 0;
          margin: 0;

          a {
            font-weight: 500;
            font-size: math.div(18rem, 16);
            letter-spacing: -0.1px;
          }

          @media only screen and (max-width: 768px) {
            display: block;
            flex-flow: column nowrap;
            justify-content: flex-start;
            min-width: 300px;
            margin: 0;
            text-align: left;
            background: white;
            box-shadow: 0 2px 4px rgba($text, 0.25);

            a {
              display: block;
              padding: 8px 16px;
              color: color.mix($text, white, 50);
              font-weight: 500;
              font-size: math.div(16rem, 16);
            }

            &:hover {
              background: color.mix($text, white, 20%);
            }

            &:not(:last-of-type) {
              border-bottom: solid 1px color.mix($text, white, 15%);
            }
          }

          .search__icon {
            a {
              span {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-around;
              }

              svg {
                position: relative;
                top: 7px;
                margin-top: -7px;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }

          .resource {
            &__initiator {
              display: block;
              cursor: pointer;

              & > a {
                padding: 0;

                & > i {
                  @media only screen and (max-width: 480px) {
                    display: none;
                  }
                }
              }

              @media only screen and (max-width: 768px) {
                padding: 10px 20px;
                color: $gl-purple;
              }
            }

            &__dropdown {
              &--menu {
                position: absolute;
                top: 0;
                left: -999em;
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                height: fit-content;
                padding: 16px 4px 0;
                list-style-type: none;
                border-radius: 0;
                opacity: 0;
                transition: top ease-in-out 0.25s, opacity ease-in-out 0.25s, left ease 0s;

                @media only screen and (max-width: 768px) {
                  flex-flow: column nowrap;
                  padding: 20px 0;
                }

                &:hover,
                &:focus {
                  display: flex;
                }
              }

              &--item {
                margin: 0;
                text-align: left;
                box-shadow: 0 2px 4px rgba($text, 0.25);

                a {
                  display: flex;
                  flex-flow: row-reverse nowrap;
                  justify-content: flex-start;
                  color: color.mix($text, white, 50);
                  font-weight: 500;
                  font-size: math.div(16rem, 16);

                  & > span {
                    display: flex;
                    align-content: flex-start;
                    align-items: center;
                    align-self: flex-start;
                    justify-content: flex-start;
                    order: 1;
                    width: 100%;
                    min-width: 300px;
                    padding: 8px 16px;
                    text-align: left;
                    background: color.mix($text, white, 1);

                    i {
                      display: none;
                      margin-right: 10px;
                      color: color.mix($text, white, 30%);
                    }
                  }

                  p {
                    display: none;
                    order: 2;
                    padding: 8px;
                    background: color.mix($text, white, 2%);
                    border-right: 1px solid color.mix($text, white, 25%);
                  }

                  &:hover {
                    span {
                      background: color.mix($text, white, 20%);
                    }

                    p {
                      color: $text;
                    }
                  }
                }

                @media only screen and (max-width: 768px) {
                  display: none;
                  margin: 0;
                  padding: 0 !important;
                  border: unset;

                  p {
                    display: none;
                  }
                }

                &:not(:last-of-type) {
                  border-bottom: 1px solid color.mix($text, white, 15%);
                }

                h2 {
                  color: $gl-purple;
                  font-weight: 600 !important;
                  font-size: math.div(16px, 16px) + rem;

                  @media only screen and (max-width: 768px) {
                    font-weight: 400 !important;
                  }
                }

                p {
                  color: color.mix($text, white, 60%);
                  font-size: math.div(13px, 16px) + rem;
                }

                h2,
                p {
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }

          &.resource__menu {
            &--item {
              &:hover > .resource__dropdown--menu,
              &:active > .resource__dropdown--menu,
              &:focus > .resource__dropdown--menu,
              &:focus-within > .resource__dropdown--menu {
                top: 18px;
                right: 16px;
                left: auto;
                display: flex;
                opacity: 1;
                @media only screen and (max-width: 768px) {
                  left: -999em;
                }
              }
            }
          }
        }
      }
    }
  }
}

.alert {
  display: block;
  margin-bottom: 1rem;
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
  padding: 10px;
  border: 1px solid transparent;
  border: 2px solid transparent;
  border-radius: 0.25rem;

  &-heading {
    color: inherit;
  }

  &-link {
    font-weight: 700;
  }

  &-dismissible {
    padding-right: 2.5rem;
  }

  &-dismissible .close {
    position: relative;
    top: -0.125rem;
    right: -1.25rem;
    color: inherit;
  }

  &-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d0e9c6;
  }

  &-success hr {
    border-top-color: #c1e2b3;
  }

  &-success &-link {
    color: #2b542c;
  }

  &-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bcdff1;
  }

  &-info hr {
    border-top-color: #a6d5ec;
  }

  &-info &-link {
    color: #245269;
  }

  &-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faf2cc;
  }

  &-warning hr {
    border-top-color: #f7ecb5;
  }

  &-warning &-link {
    color: #66512c;
  }

  &-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebcccc;
  }

  &-danger hr {
    border-top-color: #e4b9b9;
  }

  &-danger &-link {
    color: #843534;
  }
}

/* =============================================
=            Margins & Paddings            =
============================================= */
.no {
  &-padding {
    padding: 0 !important;
  }
}

.right {
  float: right !important;

  &-none {
    margin-right: 0 !important;
  }

  &-margin {
    margin-right: 20px !important;
    @media only screen and (max-device-width: 768px) {
      margin-right: 10px !important;
    }

    &-none {
      @extend .right-none;
    }

    &-half {
      margin-right: 10px !important;
    }
  }

  &-padding {
    padding-right: 20px !important;

    &-none {
      padding-right: 0 !important;
    }

    &-half {
      padding-right: 10px !important;
    }

    &-tiny {
      padding-right: 5px !important;
    }
  }
}

.top {
  &-none {
    margin-top: 0 !important;
  }

  &-margin {
    margin-top: 20px !important;

    &-none {
      @extend .top-none;
    }

    &-half {
      margin-top: 10px !important;
    }
  }

  &-padding {
    padding-top: 20px !important;

    &-none {
      padding-top: 0 !important;
    }

    &-half {
      padding-top: 10px !important;
    }

    &-tiny {
      padding-top: 5px !important;
    }
  }
}

.bottom {
  &-none {
    margin-bottom: 0 !important;
  }

  &-margin {
    margin-bottom: 20px !important;

    &-none {
      @extend .bottom-none;
    }

    &-half {
      margin-bottom: 10px !important;
    }
  }

  &-padding {
    padding-bottom: 20px !important;

    &-none {
      padding-bottom: 0 !important;
    }

    &-half {
      padding-bottom: 10px !important;
    }

    &-tiny {
      padding-bottom: 5px !important;
    }
  }
}

.left {
  float: left !important;
  @media only screen and (max-device-width: 480px) {
    float: none !important;
  }

  &-none {
    margin-left: 0 !important;
  }

  &-margin {
    margin-left: 20px !important;
    @media only screen and (max-device-width: 768px) {
      margin-left: 10px !important;
    }

    &-none {
      @extend .left-none;
    }

    &-half {
      margin-left: 10px !important;
    }
  }

  &-padding {
    padding-left: 20px !important;

    &-none {
      padding-left: 0 !important;
    }

    &-half {
      padding-left: 10px !important;
    }

    &-tiny {
      padding-left: 5px !important;
    }
  }
}

/* =====  End of Margins & Paddings  ====== */
.notify {
  z-index: 1001;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  list-style-type: none;

  lh {
    width: 100%;
    color: color.adjust(red, $lightness: -10%);
    font-weight: 700;
    font-size: math.div(12px, 16px) + rem;
    text-transform: uppercase;
  }

  & > li {
    width: 20px;
    height: 20px;
  }

  & > li > ul {
    @extend .notify;
    position: absolute;
    top: 40px;
    right: 0;
    float: right;
    width: max-content;
    min-width: 300px;
    padding: 20px;
    border-radius: 10px;
  }

  & > li > ul > li {
    width: max-content;
    padding: 10px 0;
    color: $text;
  }

  &__dropdown {
    display: none;
    background: white;
    box-shadow: 2px 2px 4px slategray;
  }

  &:hover &__dropdown,
  &:focus &__dropdown,
  &:active &__dropdown {
    display: inline-block;
  }
}

.notification {
  position: relative;
  top: -25px;
  right: -3px;
  z-index: 9;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 0;
  text-align: center;
  background: color.adjust(red, $lightness: -10%);
  border: solid 1px white;
  border-radius: 50%;
  transform: scale(0.6);
  transition: ease-in-out all 0.25s;

  &:hover {
    transform: scale(0.7);

    b {
      opacity: 1;
    }
  }

  &__type {
    $color1: color.mix($text, white, 20%);
    width: 0;
    overflow-x: visible;
    color: $color1;
    font-weight: 700;
    font-size: math.div(16px, 16px) + rem;
    line-height: 1;

    &:before {
      font-family: $fa-style-family, serif;
      content: fa-content($fa-var-bell);
    }
  }

  b {
    z-index: 10;
    color: white;
    font-size: math.div(13px, 16px) + rem;
    line-height: 1;
    opacity: 1;
  }

  &__high {
    background: red;
    border: solid 1px color.adjust(red, $lightness: -10%);
  }

  &__medium {
    background: $co-orange;
    border: solid 1px color.adjust($co-orange, $lightness: -10%);
  }

  &__low {
    background: $ma-cyan;
    border: solid 1px color.adjust($ma-cyan, $lightness: -10%);
  }
}

.page {
  &__tip {
    position: relative;
    top: 10px;
    left: 0;
    z-index: 99;
    display: inline;
    float: left;
    clear: none;
    margin: 0 0 -100%;
    padding: 10px;
    overflow-y: visible;
    color: white;
    background: $ma-cyan;
    border-radius: 5px;
    box-shadow: 2px 2px 4px slategray;

    &:before {
      position: relative;
      top: -10px;
      left: 10px;
      display: block;
      width: 0;
      height: 0;
      margin-top: -10px;
      border-color: transparent transparent $ma-cyan transparent;
      border-style: solid;
      border-width: 0 7.5px 10px;
      content: '';
    }

    p {
      margin: 0;
      padding: 0;
      color: white;
    }

    &--label {
      margin-left: 10px;
      padding: 0.2rem 0.5rem;
      color: white;
      font-weight: 700;
      font-size: math.div(10px, 16px) + rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      background: color.mix($gl-purple, white, 75%);
      border-radius: 3px;
      cursor: pointer !important;
    }

    &--close {
      padding: 0.2rem 0.5rem;
      text-align: right;
      cursor: pointer !important;
    }

    &--action {
      padding: 0.2rem 0.5rem;
      color: color.adjust($ma-cyan, $hue: -130deg) !important;
      color: white !important;
      font-weight: 700;
      text-align: right;
      text-shadow: 1px 1px black;
      cursor: pointer !important;
    }
  }
}

.content {
  z-index: 2;
  height: 80%;

  h1 {
    margin-top: 0;
  }
}

.container {
  width: 100%;
  margin: 0;
  padding: 0;

  & + .container {
    margin-top: 80px;

    &.near {
      margin-top: 0;
    }

    &.beside {
      margin-top: 20px;
    }
  }

  &.latest {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    h2 {
      margin: 0;

      a {
        color: $gl-purple;

        &:visited {
          color: $gl-purple;
        }
      }
    }

    p {
      margin: 0;
      margin-left: 10px;
    }

    &.panel {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      h1 {
        width: 100%;
        padding: 0 10px;
      }
    }
  }

  &.panel {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    h1 {
      width: 100%;
      padding: 0 10px;
    }
  }

  &.centered {
    padding: 20px 5%;

    &.keyed-row {
      border: solid rgba($text, 0.3);
      border-width: 1px 0;
    }

    &.opp {
      background-color: white;

      h2 {
        margin-top: 0;
      }

      .opp-tiles {
        display: inline-flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-between;
        width: 100%;

        .tile,
        a .tile {
          margin: 0 !important;
          @media only screen and (max-device-width: 480px) {
            margin-bottom: 20px 20px 0 20px !important;
          }
        }
      }
    }

    &.prod {
      display: inline-flex;
      flex-flow: row wrap;
      align-content: flex-start;
      justify-content: space-between;
      margin-top: 0 !important;
      background-color: white;

      &.landing {
        &.odd {
          background: rgba($sl-gray, 1);
        }

        &.even {
          background: white;
        }

        &.penultimate {
          background: rgba($co-orange, 0.1);
          background: rgba($sl-gray, 1);
        }

        &.ultimate {
          background: white;
        }

        .prod-group-head {
          width: 100%;

          &.prod-icon {
            margin-top: 20px;

            #register-icon,
            #add-pitch-icon,
            #relax-icon,
            #browse-search-icon,
            #register-interest-icon,
            #apply-icon,
            #alliance-icon,
            #markets-icon,
            #government-icon {
              width: 150px;
              height: auto;
              @media only screen and (max-device-width: 680px) {
                width: 80%;
              }
              @media only screen and (max-device-width: 480px) {
                width: 50%;
              }

              .a {
                fill: white;
              }

              .b {
                fill: rgba($co-orange, 0.3);
              }

              .c {
                fill: rgba($co-orange, 1);
              }

              .d {
                fill: rgba($co-red, 1);
              }

              .e {
                fill: rgba($co-red, 1);
              }

              .f {
                fill: $co-red;
              }
            }

            #alliance-icon,
            #markets-icon,
            #government-icon {
              .a {
                fill: white;
              }

              .b {
                fill: rgba($gl-indigo, 0.3);
              }

              .c {
                fill: rgba($gl-indigo, 0.6);
              }

              .d {
                fill: rgba($gl-purple, 0.6);
              }

              .e {
                fill: rgba($gl-purple, 1);
              }

              .f {
                fill: $gl-purple;
              }
            }

            #approval-icon {
              width: 64px;
              height: auto;

              .svg-icon {
                fill: rgba($text, 0.2);
              }
            }
          }
        }

        h2 {
          font-weight: 300 !important;
          text-transform: uppercase;
        }

        h3 {
          line-height: 1.5;
        }

        &.global {
          h2 {
            color: $gl-purple;
          }

          h3 {
            color: $gl-indigo;
          }
        }

        &.consortium {
          h2 {
            color: $co-red;
          }

          h3 {
            color: $co-orange;
          }
        }

        &.investment {
          h2 {
            color: $in-green;
          }

          h3 {
            color: $in-yellow;
          }
        }

        &.marketplace {
          h2 {
            color: $ma-blue;
          }

          h3 {
            color: $ma-cyan;
          }
        }
      }

      .tile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: auto;
        margin: 0;

        &.air {
          background: none;

          h3 {
            flex-grow: 0;
            order: 1;
            font-weight: 600;
            font-size: 16px;
          }

          p {
            flex-grow: 3;
            order: 2;
            color: rgba($text, 0.8);
          }

          span {
            flex-grow: 0;
            order: 3;
          }

          a {
            display: inline-block;
            padding: 5px 10px;
          }
        }
        @media only screen and (max-device-width: 480px) {
          margin: 0 0 20px;
        }

        h2 {
          flex-grow: 0;
          order: 1;
        }

        p {
          &.twi-info {
            flex-grow: 1;
            order: 2;
            margin: 0;
          }

          &.twi-show {
            flex-grow: 0;
            order: 3;
            margin-top: 5px;

            a {
              color: $gl-purple;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &.wide {
    display: block;
    width: 100%;
    margin: 0;
    padding: 40px;
    text-align: left;

    @media only screen and (max-device-width: 768px) {
      padding: 20px;
    }

    &.latest {
      display: block;
      background: rgba(black, 0.025);
    }

    &.popular {
      background: rgba(black, 0.075);
    }

    &.popular-pitch {
      margin-top: 20px;
      padding-top: 20px;
      background: rgba(black, 0.075);
      border-top: solid 1px rgba(black, 0.1);
      border-bottom: solid 1px rgba(black, 0.1);
    }

    .applet {
      margin: 0 auto !important;
    }
  }
}

.trails {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  justify-content: flex-start;
  padding: 0 16px 10px;
  line-height: 1;
  white-space: nowrap;

  a {
    color: color.mix($text, white, 50);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a,
  span {
    order: 1;
    font-size: math.div(14rem, 16);
  }

  span {
    color: color.mix($text, white, 50);
  }

  b {
    display: none;
    order: 2;
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    text-transform: capitalize;
    text-overflow: ellipsis;

    &:hover {
      overflow: visible;
    }
  }
}

.applet {
  // width: 80%;
  margin: 0 auto;

  aside {
    display: inline;
  }

  p {
    padding-right: 10px;
    padding-left: 10px;
  }

  &.lite {
    width: 320px !important;
    @media only screen and (max-device-width: 768px) {
      width: 100% !important;
    }
  }

  &.med {
    width: 620px !important;
    @media only screen and (max-device-width: 620px) {
      width: 100% !important;
    }
  }

  &.max {
    width: 1000px !important;

    .field-short {
      width: 490px;
    }
    @media only screen and (max-device-width: 1000px) {
      width: 100% !important;
    }
    @media only screen and (max-device-width: 768px) {
      width: 100% !important;
    }
  }
}

.mandatory {
  position: relative;
  top: 0;
  margin-left: -0.2rem;

  // color: red;
  font-size: math.div(16px, 16px) + rem;
}

.additional {
  $color1: $text;
  position: absolute !important;
  z-index: 1 !important;
  float: left !important;
  padding: 5px !important;
  color: $color1;
  font-weight: 400 !important;
  font-size: unset !important;
  font-size: math.div(12px, 16px) + rem !important;
  line-height: 1;
  letter-spacing: 0;
  text-align: left !important;
  text-transform: none !important;
  background: white !important;
  border: solid 1px rgba($color1, 0.25) !important;
  border-radius: 3px !important;
  box-shadow: 2px 2px 3px rgba(black, 0.25) !important;
  transition: all 0.15s !important;

  &--icon {
    display: inline-flex;
    transform: scale(0.75);

    svg {
      path {
        fill: color.mix($color1, white, 50%);
      }
    }
  }
}

.help {
  @extend .additional;
  margin-left: -20px;

  &__icon {
    padding: 5px;
    color: dodgerblue;
  }
}

.need-help {
  color: red;
}

form {
  .addmoney {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .show-currency {
      order: 2;
      height: 30px;
      margin: 0 10px 10px 0;
      padding-top: 2px;
      color: $gl-indigo;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .fieldset {
    margin-right: 0 !important;
    margin-bottom: 30px;
    margin-left: 0 !important;
    padding: 10px;
    background-color: rgba(188, 190, 192, 20%);
    border: 0;

    legend {
      position: relative;
      top: -40px;
      left: -10px;
      padding: 3px 10px;
      color: #fff;
      font-weight: 700;
      background-color: rgba($gl-indigo, 1);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    & + .fieldset {
      margin-top: 50px;
    }
  }

  fieldset {
    margin-bottom: 30px;
    padding: 10px;
    background-color: rgba(188, 190, 192, 20%);
    border: 0;
    border-radius: 5px;

    fieldset {
      margin-bottom: 10px;
      padding: 0;
      background: none;

      legend {
        top: 0;
        color: $text;
        background: none;
      }
    }

    & h2 {
      margin-top: 0;
    }

    &.field-long {
      width: 100% !important;

      &.reg-coserv {
        float: right !important;
        width: 490px !important;
      }
    }

    &.field-short {
      width: 300px;
      @media only screen and (max-device-width: 680px) {
        float: none !important;
        width: 100% !important;
        margin-bottom: 30px !important;
      }
      @media only screen and (max-device-width: 480px) {
        width: 100%;
      }

      &.reg-cochk {
        float: right !important;
      }
    }

    legend {
      position: relative;
      top: -15px;
      left: -10px;
      padding: 3px 10px;
      color: #fff;
      font-weight: 700;
      background-color: rgba($gl-indigo, 1);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      &.legend__inner {
        top: 25px;
        left: 0;
        margin-bottom: 20px;
        padding: 0;
        color: $text;
        background: none;
        border-radius: 0;

        &.wide {
          top: 20px;
          display: block;
          width: calc(100%);
          padding: 10px 0 5px;
          border-bottom: solid 1px rgba($text, 0.25);
        }
      }

      .invest {
        background-color: rgba($in-yellow, 1);
      }

      .market {
        background-color: rgba($ma-cyan, 1);
      }

      .consort {
        background-color: rgba($in-yellow, 1);
      }
    }

    label {
      display: inline-block;
      width: 100%;
      height: 20px;
      padding: 0;
      overflow-y: visible;
    }
  }

  // <!–[if IE]>

  // fieldset {
  //   display: block;
  // }

  // <![endif]–>

  textarea {
    width: 100%;
    padding: 5px;
    border-width: 0;
    border-radius: 5px;

    &:focus {
      box-shadow: 0 0 10px rgba($text, 0.6);
    }

    &[disabled] {
      padding: 10px;
      background: rgba($sl-gray, 1);
      border: 0;
    }

    &.message {
      height: 30vh;
    }
  }

  select,
  textarea {
    border-bottom: solid 2px rgba($text, 0.25) !important;
  }

  input {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    padding: 5px;
    border: none;
    border-radius: 5px;

    &[type='tel'],
    &[type='email'],
    &[type='password'],
    &[type='text'],
    &[type='number'],
    &[type='date'] {
      border-bottom: solid 2px rgba($text, 0.25);
    }

    &:focus {
      box-shadow: 0 0 10px rgba($text, 0.6);
    }

    &[disabled] {
      background: rgba($sl-gray, 0.5);
    }

    &#dirt {
      width: 0;
      height: 0;
      margin: 0 !important;
      padding: 0 !important;
      font-size: 0;
      background-color: $sl-gray;
    }

    &.neg {
      background-color: rgba(188, 190, 192, 20%) !important;
    }

    &[type='checkbox'] {
      width: auto !important;
      height: auto;

      &.em-checkbox {
        width: 10% !important;
      }
    }

    &[type='radio'] {
      &.em-radio {
        width: 10% !important;
      }

      &.own-radio {
        width: 10% !important;
      }
    }

    &.btn-default {
      width: auto;
      padding: 5px 10px;
      color: $text;
      font-weight: 400;
      text-decoration: none;
      background: rgba(188, 190, 192, 40%);
      border-radius: 20px;
    }

    &.btn-primary {
      @extend .btn-default;
      color: #fff;
      background-color: $gl-purple;
    }

    &.ph-set-2 {
      height: 30px;
      margin-left: -4px !important;
      border-left: solid 2px rgba($sl-gray, 1);
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.esv-lbl {
      width: 20% !important;
    }

    &.esv-cur {
      flex-grow: 0;
      order: 1;
      width: 5rem !important;
    }

    &.esv-amt {
      flex-grow: 1;
      order: 2;
      width: 65.5% !important;
      @media only screen and (max-device-width: 620px) and (min-device-width: 0) {
        width: 100% !important;
      }
    }
  }

  .neg-wrap {
    margin-bottom: 0 !important;
    padding: 10px;
    background-color: transparent !important;

    &.field-short {
      width: 300px;
      @media only screen and (max-device-width: 680px) {
        float: none !important;
        width: 100% !important;

        // margin-bottom: 30px !important;
      }

      &.reg-copy {
        background: rgba($ma-cyan, 0.3) !important;
        @media only screen and (max-device-width: 768px) {
          float: none !important;
          width: 100% !important;
          margin-bottom: 30px !important;
        }
      }
    }

    .neg-content {
      padding: 10px 0;
    }
  }

  select {
    @extend input;
    padding: 0;

    &[multiple] {
      height: auto !important;
      overflow-y: scroll;

      option {
        $color1: $text;
        $color2: color.mix($color1, white, 50%);
        width: calc(100% - 10px);
        margin: 5px;
        padding: 10px;
        border-radius: 3px;

        &:before {
          position: relative;
          top: 2.5px;
          display: inline-block;
          box-sizing: border-box;
          width: 20px;
          height: 20px;

          // font-weight: 900;
          margin-right: 0.5rem;
          font-size: math.div(16px, 16px) + rem;
          font-family: $fa-style-family, sans-serif;
          border: solid 2px $color2;
          border-radius: 3px;
          content: '';
        }

        &[selected] {
          background-color: white !important;

          &:before {
            font-weight: 900;
            content: fa-content($fa-var-check);
          }
        }
      }

      &:focus {
        option {
          background: unset;
        }
      }
    }

    option {
      padding: 5px;
    }
  }

  label {
    @extend input;
    height: auto !important;
    margin-bottom: 3px;
    font-size: math.div(13px, 16px) + rem;

    &.em-pro-lbl {
      display: inline-block;
      width: 85% !important;
    }

    &.own-lbl {
      display: inline-block;
      width: 85% !important;
    }
  }

  .list {
    &--none {
      padding: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .select {
    &__list {
      ul {
        display: inline-block;
        width: 100%;
        margin-top: 0;
        padding: 10px;
      }

      &--item {
        list-style-type: none;

        &:not(:last-child) {
          display: block;
          padding-bottom: 10px;
          content: '';
        }
      }
    }

    &__added {
      z-index: 99;
      display: block !important;
      width: 100%;
      height: 150px;
      padding: 10px;
      overflow-y: scroll;
      background: white;
      border-bottom: 2px solid rgba($text, 0.25);
    }

    &__switch {
      display: inline-block;
      width: 80px;
      height: 197px;
      padding: auto 0;

      &--buttons {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 80px;

        button {
          margin: 10px auto;
        }
      }
    }

    &__selected {
      width: calc(50% - 40px);
    }
  }
}

.form {
  &__label {
    &--aria {
      &-hidden {
        position: relative;
        top: 0;
        left: -999em;
      }
    }
  }
}

.input {
  &__checkbox {
    $color1: $text;
    $color2: color.mix($color1, white, 50%);
    position: relative;
    display: block;
    height: auto;

    ul {
      margin: 0 !important;
      padding: 0 !important;
      list-style-type: none;

      li {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 0;
        padding: 0;

        input[type='checkbox'],
        input[type='radio'] {
          position: absolute;
          left: -9999em;
        }

        label {
          z-index: 6;
          display: inline-block;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          min-height: 20px !important;
          margin: 0;

          // position: relative;
          padding: 10px 10px 10px 40px;
          line-height: 1;
          vertical-align: middle;
          cursor: pointer;
        }

        .checkbox,
        .radio {
          position: absolute;
          top: 5px;
          left: 10px;
          display: block;
          width: 20px;
          height: 20px;
          background: rgba($color2, 0.1);
          border: 2px solid $color2;
          border-radius: 3px;

          // z-index: 5;
        }

        input[type='checkbox']:checked ~ .checkbox:before,
        input[type='radio']:checked ~ .radio:before {
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: $color1;
          font-weight: 700;
          font-family: $fa-style-family, sans-serif;
          line-height: 1;
          transform: scale(0.9);
          content: fa-content($fa-var-check);
        }

        input[type='checkbox']:focus ~ label,
        input[type='radio']:focus ~ label {
          outline: 1px dashed $color2;
        }
      }
    }
  }

  &__radio {
    $color1: $text;
    $color2: color.mix($color1, white, 50%);
    @extend .input__checkbox;

    &--inline {
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        & > li {
          & + li {
            margin-left: 30px;
          }
        }
      }
    }

    ul {
      li {
        .radio {
          border-radius: 100%;
        }

        input[type='radio']:checked ~ .radio:before {
          font-weight: 900;
          content: fa-content($fa-var-circle);
        }
      }
    }
  }

  &__plus {
    &--circle {
      width: calc(100% - 55px);
      margin-bottom: 20px;
    }
  }
}

.label {
  &__block {
    display: block;
    height: auto;
    margin-bottom: 0;
  }
}

.mlots,
#mtargs {
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  & li:not(:last-child) {
    .mlots li:after {
      border-bottom: 1px solid $text;
    }
  }

  &__input {
    &--desc {
      display: inline-block;
      width: calc(70% - 10px);
      margin-right: 10px;
    }

    &--partners {
      display: inline-block;
      width: calc(27.5% - 0px);
    }
  }
}

a {
  &.btn {
    width: auto !important;
    padding: 5px 10px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    background-color: rgba($in-yellow, 1);
    border: 0;
    border-radius: 20px;

    &:hover {
      cursor: pointer !important;
    }

    &-default {
      color: $text;
      background-color: rgba($text, 0.25);
    }

    &-ghost {
      margin-top: -1px;
      padding: 5px 10px;
      color: $text;
      font-weight: 400;
      font-size: calc(12 / 16 * 1rem);
      background: transparent;
      border: solid 1px $text;

      &:hover {
        color: black;
        font-weight: 700;
        border-color: black;
      }
    }
  }
}

// TOKENIZE2
.dropdown-menu {
  padding: 10px 0;
  background: white;
  border: solid 1px $sl-gray;

  li {
    list-style-type: none;

    &.dropdown-item {
      padding: 5px 10px;

      &:hover {
        background: rgba($gl-indigo, 0.3);
      }
    }
  }
}

.btn-block {
  display: block !important;
  width: 100% !important;
  text-align: center;
}

.slu-serv-desc {
  //  @extend .slu-serv;
  width: 80%;
  margin-left: 10px;
  @media only screen and (max-device-width: 480px) {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0;
  }
}

.slu-serv {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-width: 20%;
  height: 60px;
  min-height: 240px;

  @media only screen and (max-device-width: 480px) {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .slu-serv-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    order: 1;
    min-height: 80px;
    margin-bottom: 10px;
  }

  .slu-serv-bxlbl {
    order: 1;
    width: 120px;
  }

  .slu-serv-desc {
    order: 1;
  }
}

.contact {
  &__copy {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 20px;
    border: solid 1px $gl-purple;

    &--heading,
    &--label,
    &--txt {
      margin: 0;
    }

    &--heading {
      display: block;
      order: 1;
      width: 100%;
    }

    &--label {
      display: inline;
      order: 2;
      width: 50px;

      h3 {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    &--icon {
      display: inline-block;
      order: 2;
      width: 50px;
      height: 40px !important;
      margin: 10px 0;

      svg {
        width: 40px;

        &#Layer_1 {
          height: 40px;
        }
      }
    }

    &--txt {
      display: inline;
      order: 2;
      width: calc(100% - 50px);
    }

    &--center {
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      padding: 8px 0 !important;
    }
  }

  &__other {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 20px;
  }
}

.tile {
  order: 1 !important;
  width: math.div(100%, 3.3) !important;
  margin: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: $sl-gray;
  @media only screen and (max-device-width: 480px) {
    width: 100% !important;
    margin-bottom: 10px;
  }

  &.tile-opp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    background-color: $sl-gray;

    .opp-head {
      display: flex;
      flex-direction: column;
      flex-grow: 3;
      justify-content: flex-end;
      order: 1;
      padding: 10px;
      background: $gl-purple;

      /* Old browsers */

      /* FF3.6-15 */

      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #92278f 0%, #e6197f 100%);

      &.consortium {
        background: #ae1b1f;

        /* Old browsers */

        /* FF3.6-15 */

        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, #ae1b1f 0%, #f7941d 100%);
      }

      &.investment {
        background: #39b54a;

        /* Old browsers */

        /* FF3.6-15 */

        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, #39b54a 0%, #d7df23 100%);
      }

      &.marketplace {
        background: #262262;

        /* Old browsers */

        /* FF3.6-15 */

        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, #262262 0%, #27aae1 100%);
      }

      h3 {
        flex-grow: 1;
        align-content: flex-end;
        align-items: baseline;
        order: 1;
        margin: 0;
        color: white;
        font-weight: 700;
        line-height: 1.2;
      }

      p {
        order: 2;
        color: white;
        font-weight: 400;
      }
    }

    .opp-det {
      order: 2;
      padding: 10px;
    }

    p {
      margin-top: 5px;
      margin-bottom: 0;

      &.opp-typ {
        text-transform: uppercase;
      }

      &.opp-vd {
        & a {
          color: $gl-purple;
          font-weight: 700;
        }
      }
    }

    a {
      padding: 0;
    }
  }

  &.tile-w-icon {
    min-height: 270px;
    background-color: transparent;
    border: solid 1px $gl-indigo;

    p:last-child {
      margin-bottom: 0;
    }

    h2 {
      color: $gl-indigo;
      font-weight: 400 !important;
    }

    a {
      padding: 0;
    }
  }
  @media only screen and (max-device-width: 480px) {
    width: 100%;
  }

  & h2 {
    margin: 0;
    color: $gl-indigo;
  }

  & a {
    display: block;
    padding: 10px 0;
    color: $text;
    text-decoration: none;
  }
}

.srch {
  display: flex;
  flex-flow: row wrap;
  width: 100% !important;

  @media only screen and (max-device-width: 480px) {
    display: block;
  }

  &-rslt {
    & > .opp-tiles {
      a {
        display: flex;
        flex-flow: column nowrap;

        // align-items: baseline;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
      }

      & > .tile {
        border: solid 0 rgba($gl-indigo, 0.6) !important;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(black, 0.15), 0 5px 15px rgba(black, 0.1);

        &.consortium.opportunity {
          border-color: rgba($co-orange, 0.6) !important;

          .opp-head {
            * {
              color: $co-red;
            }

            & p:last-of-type {
              color: $co-orange;
            }
          }

          .opp-div {
            &:before {
              background: #ae1b1f;

              /* Old browsers */

              /* FF3.6-15 */

              /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(135deg, #ae1b1f 0%, #f7941d 100%);
            }
          }

          .opp-det {
            p {
              color: $co-orange;
            }
          }
        }

        &.marketplace.opportunity {
          border-color: rgba($ma-cyan, 0.6) !important;

          .opp-head {
            * {
              color: $ma-blue;
            }

            & p:last-of-type {
              color: $ma-cyan;
            }
          }

          .opp-div {
            &:before {
              background: #262262;

              /* Old browsers */

              /* FF3.6-15 */

              /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(135deg, #262262 0%, #27aae1 100%);
            }
          }

          .opp-det {
            p {
              color: $ma-cyan;
            }
          }
        }

        .opp-head,
        .opp-det {
          padding: 10px;
          background: white;
        }

        .opp-head {
          display: flex;
          flex-grow: 3;
          justify-content: flex-start;
          font-size: math.div(18px, 16px) + rem;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          h3 {
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            flex-grow: 3;
            order: 3 !important;
            min-height: 50px;
          }

          * {
            order: 3 !important;
            color: $gl-purple;
            letter-spacing: -1px;
          }

          & p:last-of-type {
            order: 1 !important;
            margin: 0 0 10px;
            color: $gl-indigo;
            font-weight: 600;
            font-size: math.div(16px, 16px) + rem;
            letter-spacing: -0.5px;
            text-transform: uppercase;
          }
        }

        .opp-div {
          order: 2 !important;
          margin-bottom: 10px;
          background: white;

          &:before {
            display: block;
            width: calc(100% + 20px);
            height: 1px;
            margin: 0 -10px;
            opacity: 0.5;
            content: '';
          }
        }

        .opp-det {
          flex-grow: 0;
          max-height: 66px;
          padding-top: 0;

          & p {
            margin: 0 0 5px;
            color: $gl-indigo;
            font-size: math.div(16px, 16px) + rem;
            letter-spacing: -0.5px;

            &.opp-val {
              font-weight: 700;
              font-size: math.div(18px, 16px) + rem;
            }

            &.opp-dl {
              margin-bottom: 0;
            }

            &.opp-ld {
              color: $text !important;
              font-weight: 600;
              font-size: math.div(18px, 16px) + rem !important;
            }

            &.opp-vd {
              display: none;
              margin-bottom: 0;
            }
          }
        }

        .opp-loc {
          flex-grow: 0;
          order: 3;
          max-height: 45px;
          padding: 10px;
          background: rgba($sl-gray, 0.5) !important;
          border-top: solid 1px rgba($text, 0.25);

          .opp-ld {
            margin: 0;
          }
        }

        &-opp {
          background: white !important;
        }
      }
    }
  }
}

.search {
  &__result {
    display: flex;
    flex-flow: column nowrap;
    width: calc((100% - (20px * 1)) / 2);
    text-decoration: none !important;

    // padding: 20px 20px 10px;
    border-radius: 5px;

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }

    &.consortium {
      $color1: color.mix($text, white, 5%);
      $color2: $gl-purple;
      background: linear-gradient(173deg, $color1 50%, $color2 150%);
      border: solid 1px color.mix($color2, white, 20%);

      ul > li:first-child,
      ul > li:first-child + li {
        color: $color2;
      }
    }

    &.marketplace {
      $color1: color.mix($text, white, 5%);
      $color2: $ma-blue;
      background: linear-gradient(173deg, $color1 50%, $color2 150%);
      border: solid 1px color.mix($color2, white, 20%);

      ul > li:first-child,
      ul > li:first-child + li {
        color: $color2;
      }
    }

    a {
      color: unset;
      text-decoration: none;
    }

    h3 {
      flex: 1 0 auto;
      margin: 0 !important;

      // text-decoration: underline !important;
      padding: 16px;
      color: $text;
      font-weight: 600;
      font-size: math.div(18px, 16px) + rem;
      line-height: 1.2;
      letter-spacing: -0.5px;
      border-bottom: solid 1px color.mix($text, white, 40%);
    }

    p {
      margin: 0;
    }

    ul {
      display: flex;
      flex: 0 1 auto;
      flex-flow: row wrap;
      margin: 0;
      padding: 16px;

      & > li {
        color: $text;

        &:first-child,
        &:first-child + li {
          font-weight: 500;
          font-size: math.div(16px, 16px) + rem;
          letter-spacing: -0.5px;
          list-style-type: none;
        }

        &:not(:first-child) {
          margin-bottom: 10px;
          color: color.mix($text, white, 70%);
          list-style-position: inside;
          list-style-type: disc;
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }

    h4 {
      display: none;
    }

    &s {
      display: flex;
      flex-flow: wrap;
      gap: 20px;

      @media only screen and (max-width: 1000px) {
        flex-flow: column nowrap;
      }
    }
  }
}

.admin {
  &-control {
    width: 100% !important;
    padding: 0 16px;

    &-panels {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      margin: 0;
      padding: 0 0 16px;

      // width: 100%;
      list-style-type: none;

      & > li {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;
        width: calc((100% - (20px * 2)) / 3);
        padding: 20px;
        background: color.mix(white, $gl-purple, 10%);

        // flex: 1 0 auto;
        border-radius: 4px;

        @media only screen and (max-width: 768px) {
          width: calc((100% - (20px * 1)) / 2);
        }

        h2 {
          width: 100%;
          margin: 0;
          color: color.mix(white, $gl-purple, 60%);
          font-weight: 400 !important;
          line-height: 1;
          letter-spacing: 0.05rem;
          text-transform: uppercase;

          &:after {
            display: block;
            margin: 0 -20px;
            padding-bottom: 20px;
            border-bottom: 1px solid color.mix(white, $gl-purple, 50%);
            content: '';
          }
        }

        a {
          color: white;
          text-decoration: none;

          &:not(:first-of-type) {
            padding-top: 16px;
            border-top: 1px dashed color.mix(white, $gl-purple, 50%);
          }
        }
      }
    }
  }

  &__sign {
    &-in {
      &s {
        @extend .sign-ins;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: clamp(260px, calc(100vh - 105px), 50vh);

        form {
          width: 300px;
          margin: 0 !important;
          padding: 24px !important;
          background: color.mix($gl-purple, white, 60%);
          border: solid 1px $gl-purple;
          border-radius: 4px;

          // @media only screen and (max-screen-width: )

          fieldset {
            margin: 0 !important;
            padding: 0 !important;
            background: transparent;

            & > label {
              line-height: 1;
            }
          }
        }

        & + footer > .footer {
          &-logo,
          &-wrap {
            display: none;
          }
        }
      }
    }
  }
}

label.lbl-offset {
  float: left;
  margin-left: -999rem;
}

.co-info {
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba($sl-gray, 0.3);

  &:before {
    display: block;
    width: 100%;
    margin: 0.015rem 0;
    border-top: solid 4px $gl-indigo;
    content: '';
  }

  .coi-typ {
    letter-spacing: 0.025rem;
    text-transform: uppercase;
  }

  .coi-rng {
    margin: 0 !important;
    padding: 5px 10px;
    background-color: white;
  }

  .coi-dsc {
    @extend .coi-rng;
    margin-top: 10px !important;
    background-color: transparent !important;
  }
}

.extend__p {
  margin-bottom: 20px;
  padding: 10px 20px;
  border: solid 1px rgba($text, 0.3);

  p {
    margin: 0;
    padding: 0;
    color: $gl-indigo;
    font-weight: 700;
  }
}

.coa-info {
  @extend .co-info;
  padding: 0;
  background-color: transparent;

  &:before {
    margin: 0;
    border: 0;
  }

  h2 {
    margin-top: 0 !important;
    font-weight: 700;
    font-size: 18px;

    a {
      color: $gl-purple !important;
    }
  }

  .container {
    padding: 20px;
    background-color: rgba($sl-gray, 0.3);

    &.small {
      input {
        margin-bottom: 0;
      }

      &.endform {
        background-color: transparent !important;
      }

      & + .small {
        margin-top: 20px !important;
      }
    }
  }
}

.coe-info {
  @extend .coa-info;

  .container {
    &.small.evi-doc {
      background-color: transparent !important;

      & + .small {
        margin-top: 0 !important;
        border-top: solid 4px $text;
      }
    }
  }
}

.co-dere {
  @extend .coa-info;

  .container {
    select {
      min-height: 40vh !important;

      option {
        padding: 5px;
      }
    }

    &.small {
      & + .small {
        margin-top: 0 !important;
      }
    }
  }
}

.co-aad {
  @extend .coa-info;

  .container {
    p {
      &:first-child {
        &:after {
          display: block;
          width: 100%;
          margin: 0 auto 0.5rem;
          border-bottom: solid 1px rgba($text, 0.3);
          content: '';
        }
      }
    }
  }
}

.cog-info {
  @extend .coe-info;

  .container {
    &.small.guid-doc {
      @extend .evi-doc;
    }
  }
}

.fof-bnr {
  position: relative;
  width: 100%;
  text-align: center;
  background: #92278f;

  /* Old browsers */

  /* FF3.6-15 */

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(120deg, #92278f 0%, #e6197f 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    mix-blend-mode: screen;
  }

  .hero {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-device-width: 960px) {
      top: 15%;
      left: 20%;
      transform: translate(-15%, -20%);
    }

    h1 {
      color: $gl-purple;
    }
  }
}

// HOME
.hom-bnr {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 70vh;

  &.global {
    background: $gl-purple;
    background: url('../images/bigstock-Successful-Team--116512568.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      height: auto;
      background: url('../images/bigstock-Successful-Team--116512568_1024px.jpg');
    }
  }

  &.consortium {
    background: $co-red;
    background: url('../images/consortium/bigstock-LONDON-UK--JANUARY------90004613b.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      background: url('../images/consortium/bigstock-LONDON-UK--JANUARY------90004613b_1024px.jpg');
    }
  }

  &.investment {
    background: $in-green;
    background: url('../images/investment/bigstock-Young-Business-Team-Working-To-83940896.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      background: url('../images/investment/bigstock-Young-Business-Team-Working-To-83940896-1024px.jpg');
    }
  }

  &.marketplace {
    background: $ma-blue;
    background: url('../images/marketplace/bigstock-Beautiful-Dubai-cityscape-bir-85111427-2560px.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      background: url('../images/marketplace/bigstock-Beautiful-Dubai-cityscape-bir-85111427-1024px.jpg');
    }
  }

  &.about {
    background: url('../images/about/Sluamor-About-2560px.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      background: url('../images/about/Sluamor-About-1024px.jpg');
      background-position: 50% 50%;
    }
  }

  &.podcast {
    margin-bottom: 30px;
    background: url('../images/podcast/podcast-no-title-2560px.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    @media only screen and (max-device-width: 800px) {
      background: url('../images/podcast/podcast-no-title-1024px.jpg');
      background-position: 50% 50%;
    }
  }

  &.four-oh-four {
    height: 80vh;
    background: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  &.survey {
    $color1: color.mix($in-green, white, 90%);
    $color2: $in-yellow;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 100px;
    padding: 20px 40px;
    background: linear-gradient(355deg, $color1 10%, $color2 80%), url('../images/market-research/0002-customer-discovery-survey.jpg');
    background-size: cover;
    background-blend-mode: multiply;
  }

  .container {
    &.small {
      align-self: flex-end;
      margin: 20px;
      padding: 20px;

      &.hero {
        max-width: 50rem !important;
        background-color: rgba(black, 0.3);
        @media only screen and (max-device-width: 1000px) {
          width: 100% !important;
        }

        &.four-oh-four {
          h1 {
            color: $gl-indigo;
          }

          p,
          p a {
            color: $text;
            font-weight: 700;
            text-shadow: none;
          }
          max-width: 100% !important;
          max-height: 60vh;
          background: none;
        }
      }
    }
  }

  h1 {
    margin-bottom: 0;
    color: white;
    font-weight: 500;
    font-size: 2rem;
  }

  p {
    color: white;
    font-weight: 200;
    font-size: math.div(20px, 16px) + rem;
    text-shadow: 2px 2px 2px rgba($gl-purple, 1);

    & a {
      color: white;
    }
  }
}

// body[data-title='"home"'],
body[data-title='"services"'] {
  header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
  }

  nav {
    & a,
    & .menu-initiator,
    & .resource__initiator {
      color: white;
    }

    & .cls-1,
    & .cls-2,
    & .cls-3,
    & .cls-4 {
      fill: white;
    }

    @media only screen and (max-device-width: 768px) {
      .drop-down__menu--item {
        & a svg {
          fill: $gl-purple;
        }
      }
    }
  }
}

.home {
  &-v2 {
    width: 100%;
    padding: 20px;

    @keyframes ping-button {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(1.1);
      }
    }

    &__btn {
      display: inline-block;
      padding: 0.35rem 1.5rem;
      color: white;
      font-weight: 600;
      font-size: math.div(24px, 16px) + rem;
      text-decoration: none;
      background: $in-green;
      background: linear-gradient(345deg, color.mix($in-green, $in-yellow, 55%) 0%, $in-green 100%);
      border-radius: 500px;
      box-shadow: 0 4px 8px rgba(black, 0.25);
      cursor: pointer;

      &:hover {
        box-shadow: 0 1px 1px rgba(black, 0.45);
        animation: ping-button 0.5s alternate;
        animation-iteration-count: 2;
        animation-fill-mode: forwards;
      }

      &:active {
        color: color.adjust($in-green, $lightness: -25%);
        box-shadow: unset !important;
      }

      &--big {
        padding: 0.5rem 2rem;
        font-size: math.div(32px, 16px) + rem;
        box-shadow: 0 4px 8px rgba(black, 0.25);

        &:hover {
          box-shadow: 0 2px 2px rgba(black, 0.45);
        }
      }

      &--optional {
        $color1: color.mix($text, white, 50%);
        color: color.adjust($color1, $lightness: 0%);
        background: white;
        background: linear-gradient(15deg, rgba(white, 0.75) 35%, $sl-gray 120%);
      }

      &.continue__btn {
        animation: ping-button 0.75s infinite alternate ease-in-out;
        animation-iteration-count: 10;
      }

      @media only screen and (max-device-width: 680px) {
        font-size: math.div(24px, 16px) + rem;
      }
    }

    &__page {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      min-height: calc(100vh - 0px);
      background-size: cover;
      background-blend-mode: multiply;

      li,
      p {
        font-size: math.div(20px, 16px) + rem;
      }

      &.one {
        $color1: rgba($gl-purple, 1);
        $color2: rgba($gl-indigo, 1);
        min-height: calc(100vh + 48px);
        background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
        background-blend-mode: multiply;

        h1 {
          color: color.adjust($color2, $hue: 180deg) !important;
        }

        h2,
        h3,
        p {
          color: color.adjust($color2, $lightness: 25%) !important;
        }

        label {
          color: white;
        }
      }

      &.two,
      &.four {
        .home-v2__call-to-action--headline {
          order: 1;
        }

        .home-v2__call-to-action--item.big-vectta {
          order: 3;
          margin-right: 0;
          margin-left: 20px;
          text-align: right;
        }

        .home-v2__call-to-action--item:last-of-type {
          order: 2;
        }
      }

      &.three,
      &.five {
        .home-v2__call-to-action--item.big-vectta {
          text-align: left;
        }
      }

      &.six {
        $color1: rgba($sl-gray, 1);
        $color2: rgba(white, 1);
        background: linear-gradient(0deg, $color1 10%, $color2 80%);
        background-blend-mode: multiply;
      }

      &:not(:first-of-type) {
        min-height: 100vh;
      }

      h1 {
        margin-top: 0;
        color: $gl-purple;
        font-weight: 200;
        font-size: math.div(46px, 16px) + rem;
        letter-spacing: -1px;
        text-align: center;

        @media only screen and (max-device-width: 680px) {
          font-size: math.div(32px, 16px) + rem;
          line-height: 1.15;
        }
      }

      h2 {
        margin-top: 0 !important;
        margin-bottom: 0.25rem;
        font-weight: 600 !important;
        text-transform: capitalize;
      }

      &--menu {
        display: flex;

        // padding: 20px 0 20px 0 !important;
        // position: relative;
        // bottom: -20px;
        gap: 16px;
        text-align: center;

        // width: 100%;
        list-style-type: none;

        a {
          color: white;
          font-size: math.div(14rem, 16);
          letter-spacing: -0.1px;
        }
        @media only screen and (max-device-width: 680px) {
          flex-flow: row wrap;
          justify-content: space-between;
        }
      }
    }

    &__call-to-action {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      padding: 0;
      list-style-type: none;

      @media only screen and (max-device-width: 680px) {
        flex-flow: column nowrap;
        justify-content: flex-start;
      }

      lh {
        display: block;
        width: 100%;

        // margin: 0 0 40px 0;

        @media only screen and (max-device-width: 680px) {
          margin: 0 0 20px;
        }
      }

      ul,
      ol {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        margin: 0;
        padding: 0 !important;
        list-style-type: none;
        padding-inline-start: 0;

        lh {
          width: 100%;
          text-align: left;

          h3 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            font-weight: 500 !important;
            font-size: math.div(22px, 16px) + rem;
            line-height: 1.25;

            @media only screen and (max-device-width: 680px) {
              font-size: math.div(20px, 16px) + rem;
            }

            &:before {
              display: inline-block;
              padding-right: 0.5rem;
              font-family: $fa-style-family, sans-serif;
            }
          }
        }

        .fold {
          height: 0;
          overflow-y: hidden;
          animation-name: fold-expand;
          animation-duration: 0.25s;
          animation-iteration-count: 1;
          animation-direction: reverse;
          animation-fill-mode: forwards;

          &ed {
            h3 {
              text-decoration: underline solid;

              &:before {
                font-weight: 700;
                font-family: $fa-style-family, serif;
                transform: rotate(0deg);
                content: fa-content($fa-var-chevron-right) !important;
              }
            }
          }
        }

        .expand {
          animation-name: fold-expand;
          animation-duration: 0.25s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: backwards;

          p {
            // color: $text !important;
            letter-spacing: -0.01rem;
          }

          &ed {
            h3 {
              &:before {
                font-weight: 700;
                font-family: $fa-style-family, serif;
                transform: rotate(90deg);
                content: fa-content($fa-var-chevron-right);
              }
            }
          }

          &able {
            cursor: pointer !important;
          }
        }

        @keyframes fold-expand-icon-spin {
          0% {
            content: fa-content($fa-var-chevron-right);
          }

          100% {
            content: fa-content($fa-var-chevron-down);
          }
        }

        @keyframes fold-expand {
          0% {
            height: 1px;
            overflow: hidden;
          }

          100% {
            height: 3rem;
            overflow: visible;
          }
        }

        li {
          width: 100%;
          min-width: 0;
          text-align: left;

          @media only screen and (max-device-width: 680px) {
            min-width: 0;
          }
        }
      }

      li {
        // min-width: 1000px;
        width: 100%;
        font-weight: 300;
        text-align: center;

        @media only screen and (max-device-width: 680px) {
          min-width: unset;
        }

        &.home-v2__call-to-action--item {
          width: calc((80% - 20px) / 2);
          min-width: calc((80% - 20px) / 2);

          @media only screen and (max-device-width: 680px) {
            width: 100%;
            min-width: 100%;
            margin: 0;
          }

          &:not(:last-of-type) {
            margin-right: 20px;

            @media only screen and (max-device-width: 680px) {
              margin-bottom: 0;
            }

            a {
              @media only screen and (max-device-width: 680px) {
                margin-bottom: 20px !important;
              }
            }
          }

          p {
            margin-top: 0 !important;
            font-weight: 300;
            line-height: 1.5;
          }

          &.big-vectta {
            svg {
              width: 70%;
              height: auto;
              background-position: 13% 52%;
              background-size: initial;

              // border-radius: 500px;
              // background: linear-gradient(0deg, $ma-blue -20%, $ma-cyan 66%);
              background-blend-mode: multiply;

              @keyframes sound {
                0% {
                  transform: scale(1, 1);
                  transform-origin: 80% 48%;
                }

                100% {
                  transform: scale(1.1, 1);
                  transform-origin: 80% 48%;
                }
              }

              @keyframes flap-left {
                0% {
                  transform: rotate(0deg);
                  transform-origin: 25% 45%;
                }

                100% {
                  transform: rotate(-10deg);
                  transform-origin: 25% 45%;
                }
              }

              @keyframes flap-right {
                0% {
                  transform: rotate(0deg);
                  transform-origin: 40% 40%;
                }

                100% {
                  transform: rotate(10deg);
                  transform-origin: 40% 40%;
                }
              }
            }
          }

          &.near {
            display: flex;
            flex-flow: row nowrap;
            gap: 16px;
            width: unset;
            min-width: unset;
            text-align: center;

            @media only screen and (max-device-width: 680px) {
              margin: 0 auto;
            }
          }
        }

        &.home-v2__call-to-action--form {
          display: block;

          @media only screen and (max-device-width: 680px) {
            display: flex;
            flex-flow: column nowrap;

            // min-width: 100%;
            width: 100%;
            margin: 0;

            form {
              margin: 0;

              label,
              select {
                display: block;
                width: 100%;
                margin: 0 0 20px;
              }

              label {
                margin-bottom: 10px;
                font-size: math.div(16px, 16px) !important;
              }
            }
          }

          & + li > h3 {
            line-height: 1.25 !important;
          }
        }
      }

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;

        @media only screen and (max-device-width: 680px) {
          flex-flow: column nowrap;
          width: 100%;
          margin: unset;
        }

        * {
          font-family: Nunito, sans-serif;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }

      label {
        width: auto;
        color: $text;
        font-weight: 700;
        font-size: math.div(24px, 16px) + rem;

        @media only screen and (max-device-width: 680px) {
          width: 100%;
          font-size: math.div(24px, 16px) + rem;
        }
      }

      select {
        box-sizing: border-box;
        width: unset;
        min-width: 40%;
        height: 64px;
        margin-bottom: 0;
        padding: 10px 64px 10px 10px;
        overflow: hidden;
        font-weight: 700;
        font-size: math.div(24px, 16px) + rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: white;
        background-color: linear-gradient(173deg, color.mix($text, white, 5%) -10%, white 66%);
        background-image: url('../images/icons/sm-cheveron-down.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        border: solid 1px color.mix($text, white, 25%) !important;
        appearance: none;

        @media only screen and (max-device-width: 680px) {
          min-width: unset;
        }

        &:active,
        &:focus {
          background: white;
          background-color: linear-gradient(173deg, color.mix($text, white, 5%) -10%, white 66%);
          background-image: url('../images/icons/sm-cheveron-down.svg');
          background-repeat: no-repeat;
          background-position: right;
          background-size: contain;
        }
      }

      option {
        margin: 0 0 20px !important;
        padding: 20px !important;
        font-size: math.div(24px, 16px) + rem;
      }

      h1 {
        width: calc(100%);
        margin: 0 0 40px !important;
        line-height: 1.15 !important;

        @media only screen and (max-device-width: 680px) {
          width: 100%;
        }
      }

      &--summary {
        p {
          margin: 0 !important;
        }

        ul {
          position: relative;
          left: calc(1rem + 10px);
          width: calc(100% - 1rem - 10px);
          margin-bottom: 20px;
          line-height: 1.5;
          list-style-type: none;

          li {
            margin-bottom: 0;
            list-style-position: outside;

            &:before {
              display: inline-block;
              margin-left: calc(-1rem - 10px);
              padding-right: 10px;
              color: rgba($text, 0.5);
              color: inherit;
              font-weight: 700;
              font-family: $fa-style-family, sans-serif;
              text-align: left;
              content: fa-content($fa-var-check);
            }
          }
        }
      }
    }

    &__service {
      &--consortium {
        $color1: rgba($co-red, 1);
        $color2: rgba($co-orange, 1);
        background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
        background-size: cover;
        background-blend-mode: multiply;

        h1 {
          color: color.adjust($color2, $hue: 120deg) !important;
        }

        h2,
        h3,
        ul,
        ol {
          color: color.adjust($color2, $lightness: 35%);
        }

        p {
          color: color.adjust($color2, $lightness: 25%);
          font-size: math.div(18px, 16px) + rem;
        }
      }

      &--marketplace {
        $color1: rgba($ma-blue, 1);
        $color2: rgba($ma-cyan, 1);
        background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
        background-size: cover;
        background-blend-mode: multiply;

        h1 {
          color: color.adjust($color2, $hue: 240deg) !important;
        }

        h2,
        h3,
        ul,
        ol {
          color: color.adjust($color2, $lightness: 45%);
        }

        p {
          color: color.adjust($color2, $lightness: 35%);
          font-size: math.div(18px, 16px) + rem;
        }
      }
    }

    &__testimonial {
      width: calc((100% / 4) - 20px);
      padding: 20px;
      background-color: color.mix(white, $text, 99);
      border-radius: 10px;
      box-shadow: 0 2px 16px rgba($text, 0.25);

      @media only screen and (max-device-width: 480px) {
        width: 100%;
      }

      & > ul {
        margin: 0;
        padding-left: 0;
        list-style-type: none;

        & > li {
          color: color.mix($text, white, 50%);
          font-size: math.div(16px, 16px) + rem;

          .fa {
            color: color.mix($text, white, 25%);
          }

          &:last-child {
            color: $text;
            font-weight: 600 !important;
            font-size: math.div(13px, 16px) + rem;
            text-align: right;

            span {
              display: block;
            }
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      &:not(:last-of-type) {
        margin-right: 20px;

        @media only screen and (max-device-width: 480px) {
          margin: 0 0 20px;
        }
      }

      &s {
        min-height: auto !important;

        &--container {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          margin: 0;
          padding-left: 0;
          list-style-type: none;

          @media only screen and (max-device-width: 480px) {
            flex-flow: column nowrap;
          }
        }
      }
    }

    &__startup {
      $color1: rgba($co-red, 1);
      $color2: rgba($co-orange, 1);
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-size: cover;
      background-blend-mode: multiply;

      h1 {
        color: color.adjust($color2, $hue: 120deg) !important;
      }

      h2,
      h3,
      ul,
      ol {
        color: color.adjust($color2, $lightness: 35%);
      }

      p {
        color: color.adjust($color2, $lightness: 25%);
        font-size: math.div(18px, 16px) + rem;
      }
    }
  }

  &-v3 {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: calc(85vh - 105px);
    padding: 20px;
    background: color.mix($text, white, 2%);
    background: linear-gradient(color.mix($text, white, 20%), color.mix($text, white, 95%)), url('../images/iStock-1151808641-01.jpg');
    background-size: cover;
    background-blend-mode: screen;

    @media only screen and (max-width: 768px) {
      min-height: calc(100vh - 89px);
    }

    & > h1 {
      &:first-of-type {
        width: 50%;
        margin: 0;
        color: $gl-purple;
        font-size: calc(48rem / 16);
        line-height: 1.1;
        text-align: center;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          line-height: 1.2;
        }
        @media only screen and (max-width: 640px) {
          font-size: calc(36rem / 16);
          text-align: left;
        }
      }
    }

    & > p {
      &:first-of-type {
        width: 50%;
        margin: 0;
        padding-top: 16px;
        color: $gl-indigo;
        font-size: calc(24rem / 16);
        line-height: 1.1;
        text-align: center;

        @media only screen and (max-width: 1000px) {
          width: 100%;
        }

        @media only screen and (max-width: 640px) {
          padding-top: 8px;
          font-size: calc(20rem / 16);
          text-align: left;
        }

        & + p {
          width: 50%;
          margin: 0;
          color: $gl-indigo;
          font-size: calc(24rem / 16);
          text-align: center;

          @media only screen and (max-width: 1000px) {
            width: 100%;
            padding-top: 16px;
          }

          @media only screen and (max-width: 640px) {
            padding-top: 8px;
            font-size: calc(20rem / 16);
            text-align: left;
          }
        }
      }
    }

    &__choice {
      &s {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        justify-content: center;
        width: 90%;
        padding-top: 64px;

        @media only screen and (max-width: 1000px) {
          width: 100%;
          padding-top: 32px;
        }

        & > a {
          display: flex;
          flex-flow: row nowrap;
          gap: 8px;
        }
      }
      min-width: calc(20% - 16px);
      padding: 8px 20px;
      font-weight: 900;
      white-space: nowrap;
      text-align: center;
      text-decoration: none;
      background: white;
      border: solid 2px color.mix($text, white, 20%);
      border-radius: 50px;
      box-shadow: 0 1px 2px color.mix($text, white, 40%), 0 2px 4px color.mix($text, white, 20%);

      @media only screen and (max-width: 640px) {
        width: 100%;
      }

      &--m {
        &-buy {
          color: $text;

          i {
            // padding-right: 4px;
            color: $ma-blue;
          }
        }

        &-supply {
          color: $text;

          i {
            // padding-right: 4px;
            color: $ma-cyan;
          }
        }

        &-intel {
          color: $text;

          i {
            padding-right: 4px;
            color: $in-green;
          }
        }
      }

      &--c {
        &-buy {
          color: $text;

          i {
            // padding-right: 4px;
            color: $co-red;
          }
        }

        &-supply {
          color: $text;

          i {
            // padding-right: 4px;
            color: $co-orange;
          }
        }
      }
    }

    &__m {
      &-buyers {
        @extend .home-v3;
        flex-flow: row wrap;
        gap: 32px 128px;
        min-height: calc(100vh + 1px);
        background: linear-gradient(color.mix($ma-blue, $ma-cyan, 80%), $ma-blue);

        @media only screen and (max-width: 1000px) {
          flex-flow: column;
        }

        & > section:first-of-type {
          display: flex;
          flex-flow: row wrap;
          width: 50%;
          padding: 0 32px 0 0;

          @media only screen and (max-width: 1000px) {
            order: 2 !important;
            width: 100%;
            padding: 0 16px;
          }

          @media only screen and (max-width: 640px) {
            padding: 0;
          }

          & > h1:first-of-type {
            width: 100%;
            margin: 0;
            color: $ma-cyan;
            font-size: calc(48rem / 16);
            line-height: 1.2;

            @media only screen and (max-width: 640px) {
              font-size: calc(36rem / 16);
            }
          }

          & > h2:first-of-type {
            // width: 100%;
            margin: 0;
            color: color.mix($ma-blue, white, 20%);
            font-weight: 400 !important;
            font-size: calc(24rem / 16);
            line-height: 1.2;

            @media only screen and (max-width: 640px) {
              font-size: calc(20rem / 16);
            }

            &:after {
              display: block;
              width: 33%;
              padding-top: 16px;
              border-bottom: solid 2px $ma-cyan;
              content: '';
            }
          }

          & > p:first-of-type {
            z-index: 999;
            margin: 0;
            padding-top: 16px;
            color: color.mix($ma-blue, white, 20%);
            font-size: calc(18rem / 16);
            text-align: left;
          }
        }

        & > section:not(:first-of-type) {
          width: 25%;

          @media only screen and (max-width: 1000px) {
            // order: 1 !important;
            width: 50%;
          }
        }

        &--asserts {
          position: relative;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 16px 0 16px 0;
          font-size: calc(18rem / 16);
          list-style-type: none;
          visibility: visible;
          opacity: 1;
          transition: left 0.25s ease-in-out;

          & > li {
            display: flex;
            gap: 8px;
            height: fit-content;
            color: color.mix($ma-blue, white, 30%);
            // font-size: calc(18rem / 16);

            &:not(:first-child) {
              padding-top: 8px;
            }

            &:before {
              padding-left: 0;
              color: white;
              font-weight: 300;
              font-size: calc(20.69rem / 16);
              font-family: $fa-style-family, serif;
              content: fa-content($fa-var-check);
            }
          }

          & + .home-v3__learn--steps {
            color: color.mix($ma-blue, white, 30%);
          }
        }
      }

      &-suppliers {
        @extend .home-v3__m-buyers;
        background: linear-gradient(color.mix($ma-cyan, $ma-blue, 80%), $ma-cyan);

        & > section:first-of-type {
          order: 2;

          & > h1:first-of-type {
            color: $ma-blue;
          }

          & > h2:first-of-type {
            color: color.mix($ma-cyan, white, 20%);

            &:after {
              border-bottom: solid 2px $ma-blue;
            }
          }

          & > p:first-of-type {
            color: color.mix($ma-cyan, white, 20%);
          }
        }

        & > section:not(:first-of-type) {
          order: 1;
        }

        &--asserts {
          @extend .home-v3__m-buyers--asserts;

          & > li,
          & + .home-v3__learn--steps > li {
            color: color.mix($ma-cyan, white, 30%) !important;
          }
        }
      }

      &-intelligence {
        @extend .home-v3__m-buyers;
        background: linear-gradient(color.mix($in-green, $in-yellow, 80%), $in-green);

        & > section:first-of-type {
          & > h1:first-of-type {
            color: $in-yellow;
          }

          & > h2:first-of-type {
            color: color.mix($in-green, white, 20%);

            &:after {
              border-bottom: solid 2px $in-yellow;
            }
          }

          & > p:first-of-type {
            color: color.mix($in-green, white, 20%);
          }

          & + section {
            // border: solid 10px color.mix($in-green, white, 20%);
            border-radius: 500px;
          }
        }

        &--asserts {
          @extend .home-v3__m-buyers--asserts;

          & > li,
          & + .home-v3__learn--steps > li {
            color: color.mix($in-green, white, 30%);
          }
        }

        &--icons {
          display: flex;
          flex-flow: row nowrap;
          gap: -48px;
        }
      }
    }

    &__c {
      &-buyers {
        @extend .home-v3__m-buyers;
        background: linear-gradient(color.mix($co-red, $co-orange, 80%), $co-red);

        & > section:first-of-type {
          order: 1;

          & > h1:first-of-type {
            color: $co-orange;
          }

          & > h2:first-of-type {
            color: color.mix($co-red, white, 20%);

            &:after {
              border-bottom: solid 2px $co-orange;
            }
          }

          & > p:first-of-type {
            color: color.mix($co-red, white, 20%);
          }
        }

        & > section:not(:first-of-type) {
          order: 2;
        }

        &--asserts {
          @extend .home-v3__m-buyers--asserts;

          & > li,
          & + .home-v3__learn--steps > li {
            color: color.mix($co-red, white, 30%);
          }
        }
      }

      &-suppliers {
        @extend .home-v3__m-buyers;
        background: linear-gradient(color.mix($co-orange, $co-red, 80%), $co-orange);

        & > section:first-of-type {
          order: 2;

          & > h1:first-of-type {
            color: $co-red;
          }

          & > h2:first-of-type {
            color: color.mix($co-orange, white, 20%);

            &:after {
              border-bottom: solid 2px $co-red;
            }
          }

          & > p:first-of-type {
            color: color.mix($co-orange, white, 20%);
          }
        }

        & > section:not(:first-of-type) {
          order: 1;
        }

        &--asserts {
          @extend .home-v3__m-buyers--asserts;

          & > li,
          & + .home-v3__learn--steps > li {
            color: color.mix($co-orange, white, 30%);
          }
        }
      }
    }

    &__industries {
      @extend .home-v3__m-buyers;
      min-height: unset;
      background: color.mix($ma-cyan, white, 40%);

      h2 {
        display: flex !important;
        flex-flow: row nowrap;
        justify-content: space-between;
        // width: 100%;
        width: calc(75% + 128px);
        margin: 0;

        & > span {
          padding: 4px 16px;
          font-size: calc(14rem / 16);
          background: color.mix($text, white, 2.5%);

          & > a {
            text-decoration: none;
          }
        }
      }
    }

    &__industry {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      justify-content: start;
      width: calc(75% + 128px);
      margin: 0 auto;
      padding: 16px;
      list-style-type: none;

      &--list-item {
        position: relative;
        top: 0;
        left: 0;
        width: calc((100% - (16px * 3) ) / 4);
        // text-align: center;
        background: color.mix($text, white, 2.5%);
        // border: solid 1px;
        border-radius: 2px;
        box-shadow: 0 0 0 color.mix($text, white, 40%);
        transition: all .25s ease-in-out;

        &:hover {
          top: 2px;
          box-shadow: 0 2px 4px color.mix($text, white, 20%);
        }

        & > a {
          display: flex;
          flex-flow: row nowrap;
          gap: 16px;
          align-items: center;
          min-height: 88px;
          padding: 16px;
          color: color.mix($ma-blue, white, 60%);
          font-weight: 700;
          letter-spacing: -0.1px;
          text-decoration: none;

          & > i {
            color: $ma-blue;
          }
        }
      }
    }

    &__start {
      &--btn {
        display: inline-block;
        order: 6;
        margin-bottom: 16px;
        padding: 16px 36px;
        color: white;
        font-weight: 700;
        font-size: calc(20rem / 16);
        font-family: Arial, Helvetica, sans-serif;
        white-space: nowrap;
        text-decoration: none;
        background: $in-green;
        border: solid 1px white;
        border-radius: 50px;
        transition: background 0.15s ease-in;

        @media only screen and (max-width: 640px) {
          display: block;
          width: 100%;
          margin-bottom: 0;
          padding: 8px 24px;
        }

        &:hover {
          background: darken($in-green, 10%);
        }
      }

      &--steps {
        display: none;
        order: 4;
        padding-top: 16px;
        color: color.mix($text, white, 20%);

        & > li:not(:first-of-type) {
          padding-top: 8px;
        }
      }
    }

    &__learn {
      &--btn {
        @extend .home-v3__start--btn;
        order: 5;
        margin-right: 16px;
        background: color.mix($text, white, 80%);

        @media only screen and (max-width: 640px) {
          margin-right: 0;
          margin-bottom: 16px;
        }

        &:hover {
          background: darken(color.mix($text, white, 80%), 10%);
        }

        &.active {
          background: darken(color.mix($text, white, 80%), 10%);

          & ~ .home-v3__m-buyers--asserts {
            left: -99em;
            height: 0;
            padding: 0 0 0 40px;
            overflow: hidden;
            opacity: 0;
            transition: left 0.25s ease-in-out;
            clip: rect(1px,1px,1px,1px);
          }

          & ~ .home-v3__m-suppliers--asserts {
            left: -999em;
            height: 0;
            padding: 0 0 0 40px;
            overflow: hidden;
            opacity: 0;
            transition: left 0.25s ease-in-out;
            clip: rect(1px,1px,1px,1px);
          }

          & ~ .home-v3__c-buyers--asserts {
            left: -999em;
            height: 0;
            padding: 0 0 0 40px;
            overflow: hidden;
            opacity: 0;
            transition: left 0.25s ease-in-out;
            clip: rect(1px,1px,1px,1px);
          }

          & ~ .home-v3__c-suppliers--asserts {
            left: -999em;
            height: 0;
            padding: 0 0 0 40px;
            overflow: hidden;
            opacity: 0;
            transition: left 0.25s ease-in-out;
            clip: rect(1px,1px,1px,1px);
          }

          & ~ .home-v3__learn--steps {
            left: 0;
            height: auto;
            padding: 16px 0 16px 40px;
            font-size: calc(18rem / 16);
            opacity: 1;
            transition: left 0.25s ease-in-out;
          }
        }
      }

      &--steps {
        @extend .home-v3__m-buyers--asserts;
        position: relative;
        top: 0;
        left: -99em;
        height: 0;
        margin: 0;
        padding: 0;
        padding-left: 40px;
        overflow: hidden;
        color: color.mix($text, white, 20%);
        // font-size: calc(20rem / 16);
        list-style: none;
        opacity: 0;
        transition: left 0.25s ease-in-out;
        clip: rect(1px, 1px, 1px, 1px);
        counter-reset: li;

        & > li:not(:first-of-type) {
          padding-top: 8px;
        }

        li {
          counter-increment: li;
        }

        & li:before {
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          color: white;
          font-family: Nunito, sans-serif;
          content: counter(li);
        }
      }
    }

    &__next {
      order: 4 !important;

      & > ul {
        padding: 0;
        list-style-type: none;
      }
      width: 100% !important;

      & > ul,
      & ul > li > p {
        margin: 0;
      }
    }
  }
}

.to {
  &__top {
    &--btn {
      position: fixed;
      right: 20px;
      bottom: 20px;
      float: right;
      width: 60px;
      height: 60px;
      padding: 20px;
      background: rgba(white, 0.8);
      border-radius: 500px;
      box-shadow: 0 2px 4px rgba($text, 0.65);

      &:hover {
        animation-name: ping-button;
        animation-duration: 0.25s;
      }

      * {
        color: color.mix($text, white, 50%);
      }

      svg {
        transform: scale(2);
      }
    }
  }
}

// CONTACT
p.cadd-txt {
  margin-bottom: 0;

  & + .cadd-txt {
    margin-top: 0;
  }
}

// SIGN IN
.sign {
  &-ins {
    @media only screen and (max-device-width: 680px) {
      padding: 0 20px;
    }

    form {
      margin-bottom: 40px;

      & > fieldset {
        margin-right: 0;
        padding: 20px;

        & > input[type='password'] {
          margin-bottom: 20px;
        }

        & > input[type='submit'] {
          position: relative;
          right: 0;
          margin-bottom: 0 !important;
        }
      }
    }

    &__actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      * {
        margin-bottom: 0 !important;
      }
    }
  }
}

// REGISTER
.sign-opt {
  padding: 20px;
}

.usr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 20px 0 40px;
  padding: 20px;
  background: rgba($ma-cyan, 0.3);
  @media only screen and (max-device-width: 680px) {
    flex-flow: column wrap;
    width: 100% !important;
  }

  .usr-head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    order: 1;

    h1 {
      margin-bottom: 40px;
      @media only screen and (max-device-width: 680px) {
        margin-bottom: 10px;
      }
    }
  }

  .usr-det {
    order: 2;
    width: 50%;
    padding: 0 0 0 20px;
    @media only screen and (max-device-width: 680px) {
      width: 100% !important;
      padding: 0;
    }
  }
}

.box-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  @media only screen and (max-device-width: 680px) {
    flex-flow: column wrap;
    width: 100% !important;
  }
  @media only screen and (min-device-width: 680px) {
    flex-flow: row nowrap;
    width: 100% !important;
  }

  .box-a {
    order: 1;
    width: 50%;
    margin: 10px;
    @media only screen and (max-device-width: 680px) {
      width: 100% !important;
      margin: 0 !important;

      &.contact-a {
        order: 2;
      }
    }

    .field-short {
      width: 100% !important;
    }
  }

  .box-b {
    order: 2;
    width: 50%;
    margin: 10px;
    @media only screen and (max-device-width: 680px) {
      width: 100% !important;
      margin: 0 !important;

      &.contact-b {
        order: 1;
        height: 100%;
        margin-bottom: 20px !important;
      }
    }

    .field-short {
      width: 100% !important;
    }
  }
}

legend .mandatory {
  color: white;
  font-weight: 300;
}

.ph-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.acblock {
  display: inline-block;
  flex-grow: 0;
  order: 2;

  ul {
    width: 4rem !important;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
      padding: 3px 10px;
      color: $gl-indigo;
      font-weight: 700;
      font-family: Arial, sans-serif;
      text-align: right;
      list-style-type: none;
      background: white;
      border-radius: 5px;

      &.disabled {
        color: $text;
        font-weight: 400;
        background: rgba($sl-gray, 0.1);
      }
    }
  }
}

.ph-set-1 {
  display: inline-block;
  flex-grow: 0;
  order: 1;
  width: 220px !important;
  height: 30px;
  margin-right: 10px !important;
  @media only screen and (max-device-width: 680px) {
    width: 80% !important;
  }
}

.ph-set-2 {
  display: inline-block;
  flex-shrink: 1;
  order: 3;
}

// PRICING
.plans-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 640px;
  margin: 20px auto;
  @media only screen and (max-device-width: 800px) {
    width: 100%;
  }

  .pln-wrap {
    order: 1;
    width: 300px;
    margin: 20px 10px;
    background: white;
    border: solid 2px rgba($sl-gray, 1);
    @media only screen and (max-device-width: 800px) {
      flex-flow: column nowrap;
      width: 100% !important;
    }

    div {
      width: 100%;
      padding: 10px;
      color: rgba($text, 0.8);
      text-align: center;
    }

    .pln-typ {
      color: rgba($gl-indigo, 1);
      font-weight: 700;
      font-size: 28px;
    }

    .pln-pkg {
      color: $text;
      font-weight: 700;
    }

    .pln-price {
      .pp-tot {
        color: $text;
        font-weight: 700;
        font-size: 28px;
      }
    }

    .pln-fea {
      padding: 0;
      font-weight: 700;

      &:before {
        display: block;
        width: 100%;
        margin: 0.25rem auto 0;
        border-bottom: solid 1px $sl-gray;
        content: '';
      }
    }

    .ppf-inc {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      border-bottom: solid 1px $sl-gray;

      &:first-child {
        border-top: solid 1px $sl-gray;
      }

      &:nth-child(odd) {
        background: rgba($text, 0.06);
      }

      &:nth-child(even) {
        background: rgba($text, 0);
      }

      span {
        &.check {
          margin-right: 10px;

          .svg-icon {
            fill: limegreen;
          }
        }
        order: 2;
      }

      &:before {
        content: '';
      }
    }
  }
}

.pln-calc-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  order: 2;
  margin-top: 20px;
  background: $gl-purple;
  background: rgba($gl-purple, 0.25);
  background: rgba($sl-gray, 1) !important;
  border: none !important;
  @extend .pln-wrap;

  div {
    color: white !important;
    color: $text !important;
    font-size: 20px;
  }
  @media only screen and (max-device-width: 800px) {
    margin-top: 20px !important;
  }

  #calc-icon {
    width: 80px;
    height: auto;
  }

  .svg-icon {
    fill: $gl-indigo;
  }

  .btn-secondary {
    background: $gl-purple !important;
  }
}

.calc-title {
  padding-top: 40px;
  font-size: 2rem;
}

.plans-title {
  @extend .calc-title;
  margin-top: 0 !important;
  color: white;
  font-weight: 700;
}

.calc-wrap {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    width: 20%;
    margin-left: 3.6% !important;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid $sl-gray;

    &:hover {
      border: 2px solid $gl-indigo;
    }
  }
}

.calc-sum {
  h4 {
    margin-bottom: 0;

    &.cs-head {
      padding: 10px;
      color: $sl-gray;
      font-weight: 700;
      background-color: $text;

      &.csh-para {
        display: inline-block;
        width: 50%;
      }

      &.csh-sum {
        display: inline-block;
        width: 50%;
      }
    }
  }

  .cspi-head {
    height: 40px;

    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 1rem;
    }
  }

  .csp-info {
    display: inline-block;
    width: 51%;
    padding: 20px;
    list-style-type: none;
    border: solid 1px $in-yellow;

    span {
      width: 50%;
    }

    .cspi {
      display: block;
      width: 100%;
      margin: 0;
      padding: 10px 0;
      border-bottom: solid 1px $sl-gray;

      &:first-child {
        padding: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .cssi-head {
    @extend .cspi-head;

    h5 {
      span {
        &.total-txt {
          font-size: 2.25rem;
        }

        &.subtotal-txt {
          font-weight: 400;
        }
      }
    }
  }

  .css-info {
    @extend .csp-info;
    width: 49%;
    margin: 0 !important;
    border: 0 !important;

    &:before {
      display: block;
      border-top: solid 4px $in-yellow;
      content: '';
    }

    .cssi {
      @extend .cspi;
    }
  }

  .cc-head {
    @extend .cspi-head;
  }

  .cc-info {
    @extend .csp-info;

    .cci {
      @extend .cspi;
    }
  }

  .csl-head {
    @extend .cspi-head;
  }

  .csl-info {
    @extend .csp-info;

    .csli {
      @extend .cspi;
      margin-bottom: 10px;
      padding: 20px;
      border: solid 1px $sl-gray;

      &:first-child {
        margin-top: 10px;
        padding: 20px;
      }

      &:last-child {
        border: solid 1px $sl-gray;
      }

      &:hover {
        border: solid 1px $gl-indigo;
      }
    }
  }

  .cd-head {
    @extend .csl-head;
  }

  .cd-info {
    @extend .csl-info;

    .cdi {
      @extend .csli;
    }
  }

  .cm-head {
    @extend .csl-head;
  }

  .cm-info {
    @extend .csl-info;

    .cmi {
      @extend .csli;
    }
  }
}

// PRICING V2
.med {
  &.stretch-bkg {
    padding: 20px 25%;
    @media only screen and (min-device-width: 621px) and (max-device-width: 1024px) {
      padding: 20px 20%;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 620px) {
      padding: 20px 10%;
    }

    h2 {
      padding-top: 20px;
    }

    .calc-lbl {
      color: $text;
      font-weight: 700;
      font-size: 20px;
      text-transform: uppercase;
    }

    // &:nth-child(even) {
    //   background: rgba($text, 0.04);
    // }

    // &:nth-child(odd) {
    //   background: rgba($gl-indigo, 0.25);
    // }

    // &:last-child {
    //   background: rgba($gl-purple, 0.25);
    // }

    // &:nth-child(even) {
    //   background: rgba($gl-indigo, 0.04);
    // }

    &:nth-child(odd) {
      background: rgba($gl-indigo, 0.25);
    }

    // &:last-child {
    //   background: rgba($gl-purple, 0.25);
    // }

    // &:nth-child(odd) {
    //   background: rgba($sl-gray, 1);
    // }

    &:nth-child(even) {
      padding-top: 20px;
      background: rgba(white, 1);
    }

    &:last-child {
      background: rgba($gl-purple, 1);
    }
  }

  .small {
    @media only screen and (min-device-width: 320px) and (max-device-width: 620px) {
      width: 100% !important;
      padding: 0 10px;
    }
    margin: 0 auto;

    & + .small {
      margin-top: 40px;
    }
  }
}

.steps {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  * {
    color: $text;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__number {
    width: 40px;
    height: 40px;
    padding: 10px;
    border: solid 3px;
    border-radius: 20px;

    & p {
      margin: 0;
      line-height: 1;
      text-align: center;
    }
  }

  &__range {
    &--number,
    &--label {
      align-items: center;
      height: 15px;
      margin: 12.5px -3px;
      border: solid 8px transparent;
    }

    &--number {
      min-width: 110px;
      max-width: 144px;
    }

    &--label {
      min-width: 50px;
      max-width: 144px;
    }
  }

  &__number,
  &__range {
    &--done {
      background-color: $gl-purple;
      border-color: $gl-purple;

      p {
        color: white !important;
      }
    }

    &--active {
      z-index: 99;
      border-color: $gl-purple;

      p {
        color: $gl-purple !important;
      }
    }

    &--not-done {
      z-index: 2;
      background-color: #e0e0e1;
      border-color: #f2f2f3;

      p {
        color: white !important;
      }
    }
  }

  &__number--not-done {
    z-index: 99 !important;
  }

  &__range--not-done {
    z-index: 4 !important;
  }

  &__labeling &__range {
    width: (((144px - 6px) * 2) - 100px * 3) !important;
    width: 83px !important;
    background: transparent;
  }

  &__label {
    @extend .steps__number;
    width: 100px;
    padding: 10px 0 !important;
    border: none;

    p {
      font-size: math.div(12px, 16px) + rem;
      font-family: Nunito, sans-serif;
    }

    @media only screen and (max-device-width: 680px) {
      &:first-of-type {
        p {
          text-align: left;
        }
      }

      &:last-of-type {
        p {
          text-align: right;
        }
      }
    }
  }
}

.upgrade {
  padding: 10px;
  background: rgba($text, 0.25);
  border: solid 2px rgba($text, 0.5);
  border-radius: 3px;

  p {
    margin: 0 !important;
    padding: 0 !important;
    color: $text;
  }

  &__standard {
    background: rgba($in-yellow, 0.25);
    border-color: rgba($in-yellow, 0.5);
  }
}

.subscribe {
  &-register {
    @extend .sign-ins;

    &__opt-out {
      margin: 0 0 20px;
      padding: 0 !important;
    }

    form {
      & fieldset {
        margin: 0 0 20px !important;
        padding: 20px !important;
      }
    }
  }

  &-checkout {
    @extend .sign-ins;
    padding-top: 20px;
  }

  /*=============================================
  =             Subscription Plans             =
  =============================================*/

  /*----------  Plans  ----------*/
  &-plans {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    justify-content: center;
    max-width: 1200px;
    padding: 32px 16px 16px 16px;

    @media only screen and (max-width: 1200px) {
      max-width: 100%;
    }

    & > h1 {
      width: 100%;
      margin: 0;
      font-size: calc(32rem / 16);
      line-height: unset;
      text-align: center;
    }

    & > h2 {
      gap: 0;
      width: 100%;
      margin: 0;
      color: $gl-indigo;
      font-weight: 600 !important;
      font-size: calc(20rem / 16);
      text-align: center;
    }

    &__packs {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding: 32px 0 0 0;

      @media only screen and (max-width: 1000px) {
        gap: 16px 0;
        justify-content: start;
        padding: 0;
      }

      @media only screen and (max-width: 480px) {
        flex-flow: column;
        gap: 32px;
        width: 100%;
      }
    }

    &__pack {
      $borderColor1: color.mix($gl-indigo, white, 20%);
      display: flex;
      flex: 2;
      flex-flow: column;
      width: calc((100% / 4));
      margin: 0;
      padding: 0;

      @media only screen and (max-width: 768px) {
        flex: unset;
        width: calc((100% / 2));
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      & > h2 {
        height: 46px;
        margin: 0 ;
        padding: 0;

        @media only screen and (max-width: 640px)  {
          height: unset;
        }
      }

      &.recommended {
        background: white;
        background: $gl-indigo;
        border: solid $gl-indigo;
        border-width: 2px 2px 0 2px;
        border-radius: 16px 16px 0 0;

        & > form {
          border-width: 0 !important;
        }
      }

      &--recommended {
        margin: 0;
        padding: 8px 0 !important;
        overflow: hidden;
        color: white;
        font-weight: 400 !important;
        text-align: center;
        text-transform: uppercase;

        @media only screen and (max-width: 640px)  {
          height: 46px;
        }

        & ~ .subscribe-plans__pack--cost,
        & ~ .subscribe-plans__pack--included {
          border-color: $gl-indigo !important;
          border-width: 0;
        }

        & ~ .subscribe-plans__pack--included {
          border-width: 0;
          border-bottom-width: 2px;
        }
      }

      &--cost {
        z-index: 2;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        gap: 16px;
        align-items: center;
        order: 1;
        margin: 0;
        padding: 16px;
        list-style-type: none;
        background: color.mix($text, white, 0%) !important;
        border: solid $borderColor1;
        border-width: 1px 0 0 1px;
        border-radius: 0;
        // box-shadow: 0 4px 3px color.mix($text, white, 20%);

        @media only screen and (max-width: 768px) {
          border-width: 1px 1px 0 1px;
        }

        & > li {
          width: 100%;
          color: $gl-purple;
          text-align: center;

          &:first-of-type {
            color: $gl-purple;
            font-weight: 600;
            font-size: calc(32rem / 16);
          }

          &:last-child {
            & > i {
              padding-right: 4px;
              color: color.mix($gl-indigo, white, 40%);
            }
          }

          & > ul {
            margin: 0 !important;
            padding: 0 10% 0 10%;
            color: $ma-cyan;
            text-align: center;
            list-style-type: none;

            & > li {
              padding: 16px 0;
              font-weight: 700;
              font-size: calc(42rem / 16);
              text-align: center;
              border: solid 1px $borderColor1;
              border-radius: 4px;
            }
          }

          & + li {
            font-weight: 300;
            font-size: calc(24rem / 16);

            &:last-child {
              font-weight: 600;
              font-size: calc(14rem / 16);
            }
          }
        }
      }

      & > form {
        z-index: 3;
        display: flex;
        justify-content: center;
        order: 2;
        padding: 16px;
        background: white;
        border: solid $borderColor1;
        border-width: 0 0 0 1px;
        border-radius: 0;
        // box-shadow: 0 4px 3px color.mix($text, white, 20%);

        @media only screen and (max-width: 768px) {
          border-width: 0 1px 0 1px;
        }
      }

      &--included {
        z-index: 4;
        display: flex;
        flex: 3;
        flex-flow: column;
        align-items: stretch;
        order: 3;
        margin: 0;
        padding: 0 0 16px 0;
        list-style-type: none;
        background: white;
        border: solid $borderColor1;
        border-width: 0 0 1px 1px;
        border-radius: 0;
        // box-shadow: 0 4px 3px color.mix($text, white, 20%);

        @media only screen and (max-width: 768px) {
          border-width: 0 1px 1px 1px;
        }

        &.last {
          border-width: 0 1px 1px 0;
        }

        & > li {
          display: flex;
          flex: auto 0 1;
          width: 100%;
          min-height: fit-content;
          margin: 0;
          // border-top: dashed 1px $gl-purple;
          padding: 16px 16px 0 16px ;
          font-size: calc(14rem / 16);

          &:empty {
            height: 0;
            margin: 0;
            padding: 0;
            border-top: 0;
            visibility: hidden;
          }

          &:not(:empty):before {
            display: inline-block;
            margin-right: 0.5rem;
            color: $gl-purple;
            font-weight: 500;
            font-family: $fa-style-family, sans-serif;
            content: fa-content($fa-var-check);
          }
        }
      }

      &.last {
        & > .subscribe-plans__pack--cost,
        & > form,
        & > .subscribe-plans__pack--included {
          border-right: 1px solid $borderColor1;
        }
      }
    }

    &__info {
      & > span > .fa-circle-info {
        color: color.mix($text, white, 40%);
      }
      // i {
      //   position: relative;
      //   top: -2px;
      //   left: 0;
      //   width: 14px;
      //   height: 14px;
      //   padding: 2px;
      //   color: color.mix($text, white, 40%);
      //   font-size: calc(8rem / 16);
      //   line-height: 1;
      //   text-align: center;
      //   background: white;
      //   border: solid 1px color.mix($text, white, 60%);
      //   border-radius: 50px;
      // }

      &--help {
        position: relative;
        top: 10px;
        left: 0;
        display: inline;
        float: left;
        width: 100%;
        padding: 8px;
        font-size: calc(12rem / 16);
        background: color.mix($text, white, 5%);

        &:before {
          position: absolute;
          top: -20px;
          left: 8px;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid color.mix($text, white, 5%);
          border-left: 10px solid transparent;
          transition: all 0.3s ease-in;
          content: '';
        }
      }
    }

    &__btn {
      &--buy {
        @extend .home-v3__start--btn;
      }
    }

    &__interest {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 32px 0;

      & > h2 {
        width: 100%;
        color: $gl-indigo;
        text-align: center;
      }

      & > p {
        // display: flex;
        // flex-flow: row wrap;
        margin: 0;
        padding: 0;

        & > span {
          @media only screen and (max-width: 640px) {
            display: block;
            padding-top: 16px;
          }

          & > a {
            @extend .subscribe-plans__btn--buy;
            padding: 8px 18px;
            text-align: center;
          }
        }
      }
    }

    &__help {
      @extend .subscribe-plans__interest;
      display: flex;
      gap: 16px;
      align-items: flex-start;

      @media only screen and (max-width: 480px) {
        flex-flow: column;
        align-items: center;
        justify-content: center;
      }

      & > div,
      & > div > span {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 220px;
        height: 220px;
        border-radius: 500px;

        @media only screen and (max-width:1000px) {
          width: 160px;
          height: 160px;
        }

        &:not(span) {
          background: linear-gradient(color.mix($ma-blue, white, 10%), color.mix(dodgerblue, white, 40%));
          border: 5px solid color.mix($ma-blue, white, 10%);
        }

        & > span {
          --fa-primary-color: #{$ma-blue};
          --fa-secondary-color: white;
          --fa-primary-opacity: 0.8;
        }
        // & > span {
        //   &:before {
        //     --fa-primary-opacity: 0.8;
        //     color: $ma-blue;
        //     opacity: var(--fa-primary-opacity, inherit);
        //     content: fa-content($fa-var-messages-question);
        //   }

        //   &:after {
        //     color: white;
        //     content: fa-content($fa-var-messages-question$fa-var-messages-question);
        //   }
        // }
      }

      section {
        width: calc((100% / 2) - 8px);
        border-radius: 4px;

        @media only screen and (max-width: 768px) {
          width: 80%;
        }
        @media only screen and (max-width: 480px) {
          width: 100%;
        }

        &:last-of-type {
          width: 25%;
          padding: 16px;
          background: white;
          border: solid 1px color.mix($ma-blue, white, 20%);

          & > span {
            display: block;
            color: $ma-blue;
            text-align: center;
          }

          @media only screen and (max-width: 768px) {
            width: 80%;
          }

          @media only screen and (max-width: 480px) {
            width: 100%;
          }
        }

        & > p {
          margin: 0;
          padding: 0;
          padding: 8px 0 0 0;
          color: color.mix($ma-blue, white, 80%);

          & > strong {
            color: $ma-cyan;
          }
        }

        & > ol {
          padding: 16px 0 0 24px;
          color: color.mix($ma-blue, white, 60%);
          border-top: dashed 1px color.mix($ma-blue, white, 20%);

          & > li {
            color: $ma-cyan;
            // font-weight: 600;
            line-height: 1.5;

            &:not(:first-child) {
              padding: 8px 0 0 0;
            }
          }
        }
      }
    }
  }

  /*----------  Plan  ----------*/
  &-plan {
    width: 100%;
  }

  /*=====  End of Subscription Plans  ======*/
}

.subscribed {
  padding: 20px;
  background: $sl-gray;

  h2,
  p,
  ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none;
  }

  &__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
  }

  &--label {
    display: inline-block;
    width: 120px;
    font-weight: 700;
    font-size: math.div(16px, 16px) + rem;
  }

  &--data {
    width: calc(100% - 120px);
  }
}

/*=============================================
=     Subscription plans V1 comment block     =
=============================================*/

.pricings {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;

  * {
    font-family: Arial, Helvetica, sans-serif !important;
  }

  &__full {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    order: 1;
    width: calc((100% / 4) - 15px);
    border-radius: 10px 10px 15px 15px;

    @media only screen and (max-width: 1000px) {
      width: calc((100% / 2) - 15px);

      &:nth-child(even) {
        margin-right: 0 !important;
      }
    }

    @media only screen and (max-width: 480px) {
      width: calc((100% / 1));
    }
  }

  &__recommend {
    outline: $in-yellow solid 5px;

    div:first-child {
      position: relative;
      top: 10px;
      z-index: -1;
      height: 0;
      margin-top: -10px !important;
      padding: 10px 20px 40px;
      color: white;
      font-weight: 700;
      font-size: math.div(18px, 16px) + rem;
      text-transform: uppercase;
      background: $in-yellow;
    }
  }

  &__normal {
    @extend .pricings__recommend;
    outline-width: 0;

    div:first-child {
      background: transparent !important;
    }
  }

  &__plan {
    $color1: $gl-indigo;
    $color2: $gl-purple;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: flex-start;
    order: 1;
    height: fit-content;
    padding: 10px;
    background: $color1;
    background: linear-gradient(to bottom, $color1 0%, $color2 100%);
    border-radius: 10px 10px 0 0;
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#92278f', endColorstr='#e6197f', GradientType=0);

    * {
      color: white;
    }

    h2,
    p {
      margin: 0 !important;
      line-height: 1;
    }

    &--has-discount {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: fit-content;
      margin: 0 auto;
      padding: 10px;
      border: solid 1px white;
      border-radius: 5px;

      .pricings__plan--price {
        s {
          position: relative;
          margin-top: 25px !important;
          color: rgba(black, 0.5);
          font-weight: 700;
          font-size: math.div(32px, 16px) + rem;
          letter-spacing: -1px;
          text-decoration: none;

          span {
            color: rgba(black, 0.5);
          }

          &:before {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            border-color: red;
            border-top: 4px solid;
            transform: rotate(-15deg);
            content: '';
          }
        }

        span {
          &:before {
            margin-left: 0;
          }
        }
      }
    }

    &--name {
      padding: 10px 0 0;
      font-weight: 400 !important;
      font-size: math.div(39px, 16px) + rem;
      letter-spacing: -0.05em;

      &:after {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px;
        content: '';
      }
    }

    &--price {
      padding: 0;
      color: rgba(white, 0.5);
      font-size: math.div(64px, 16px) + rem;

      span {
        color: white;
        font-weight: 700;

        &:before {
          display: inline-block;
          margin-left: 1rem;
          content: '';
        }
      }
    }

    &--period {
      padding: 10px 0 !important;
      font-size: math.div(24px, 16px) + rem;
      font-style: italic;
    }

    &--annual {
      padding: 10px 0 !important;
      font-style: italic;

      s {
        position: relative;
        color: rgba(black, 0.75);
        text-decoration: none;

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          border-color: red;
          border-top: 2px solid;
          transform: rotate(-15deg);
          content: '';
        }
      }

      span {
        font-weight: 700;
      }
    }
  }

  &__included {
    flex-grow: 3;
    align-items: stretch;
    order: 2;
    padding: 10px 10px 0;
    background: $sl-gray;

    &--upsell {
      margin: 0 !important;
      padding: 10px 0 !important;
      font-size: math.div(12px, 16px) + rem;
      border-bottom: solid 1px rgba($text, 0.25);

      &:before {
        display: inline-block;
        margin-right: 0.5rem;
        color: $in-yellow;
        font-weight: 700;
        font-family: $fa-style-family, sans-serif;
        content: fa-content($fa-var-check);
      }

      &:after {
        display: block;
        width: 100%;
        margin: 0 auto;
        content: '';
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    span {
      svg {
        position: relative;
        top: 5px;
        fill: $in-yellow;
      }
    }
  }

  &__buy {
    flex-grow: 0;
    order: 3;
    padding: 20px 10px !important;
    background: $sl-gray;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    * {
      font-weight: 400 !important;
      font-family: Nunito, sans-serif !important;
      border-radius: 30px !important;
    }
  }

  &__additional {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    width: calc((100% / 2) - 16px);
    margin: 0;
    padding: 16px;
    list-style-type: none;
    background: color.mix($text, white, 0%);
    border: solid 1px color.mix($gl-indigo, white, 20%);
    border-radius: 4px;
    // box-shadow: 2px 4px 8px color.mix($text, white, 60%);

    @media only screen and (max-device-width: 680px) {
      width: calc(100% - 40px);
      margin: 0 0 16px;
    }

    &s {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;

      h2 {
        width: 100%;
        margin: 0 !important;
        padding: 16px 0 0 0;
        color: $gl-indigo;
      }
    }

    & > h3 {
      margin: 0;
      color: $gl-purple;
      font-weight: 600;
      font-size: math.div(18rem, 16);
      line-height: 1;

      @media only screen and (max-width: 768px) {
        font-size: calc(20rem / 16);
        border-width: 0 1px 1px 1px;
      }

      &:after {
        display: none;
        margin: 16px -16px 0;
        // padding-bottom: 20px;
        border-bottom: solid 1px color.mix($gl-indigo, white, 10%);
        content: '';
      }
    }

    & > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      & > li {
        display: flex;
        padding: 16px 0 0;
        color: color.mix($text, white, 80%);
        font-size: calc(16rem / 16);

        @media only screen and (max-width: 768px) {
          font-size: calc(12rem / 16);
        }

        a {
          color: white;
          text-decoration: none;
        }

        &:before {
          display: inline-block;
          margin-right: 0.5rem;
          color: $gl-purple;
          font-weight: 500;
          font-family: $fa-style-family, sans-serif;
          content: fa-content($fa-var-check);
        }

        &.pricings__additional--plain {
          padding: 16px 0 0;

          & > a {
            @extend .home-v3__learn--btn;
            display: inline-block;
            margin: 0;
          }

          &:before {
            content: unset !important;
          }
        }

        // &:not(:last-of-type) {
        //   &:after {
        //     // display: block;
        //     padding: 16px 0 0;
        //     border-bottom: dashed 1px color.mix($text, white, 15%);
        //     content: '';
        //   }
        // }
      }
    }
  }
}

/*=====  End of Subscription plans V1 comment block  ======*/

.accept {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  justify-content: center;
  width: 100%;
  color: color.mix($text, white, 40%);
  // max-width: 480px;

  & > p {
    @extend .text--tiny;
    width: 100%;
    margin: 0;
    padding: 16px 0 0 0;
    text-align: center;
  }

  &ed{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @media only screen and (max-device-width: 768px) {
      width: 100%;
    }

    &__container {
      width: calc(100% / 7);
      margin: 5px;
      border-radius: 3px;

      @media only screen and (max-device-width: 480px) {
        width: 25%;
      }
    }

    &--card {
      width: fit-content;
      height: fit-content;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.payments {
  & label {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    margin: 0;
    background: rgba($sl-gray, 0.5);
    border: solid 2px;

    &[data='basic'] {
      background: rgba($gl-indigo, 0.15);
      border-color: $gl-indigo;
    }

    &[data='standard'] {
      background: rgba($in-yellow, 0.15);
      border-color: $in-yellow;
    }

    &[data='premium'] {
      background: rgba($ma-cyan, 0.15);
      border-color: $ma-cyan;
    }

    &:hover input ~ .radio {
      background: rgba($text, 0.25);

      &:after {
        position: relative;
        display: block;
        margin: 5px 7px;
        color: $text;
        font-weight: 700;
        font-size: math.div(16px, 16px) + rem;
        content: '\002713';
        content: '';
      }
    }

    & input:checked ~ .radio {
      display: block;
      background: $gl-indigo;
      border: none;

      &:after {
        position: relative;
        display: block;
        margin: 7px 9px;
        color: white;
        font-weight: 700;
        font-size: math.div(16px, 16px) + rem;
        content: '\002713';
      }
    }
  }

  &__subscription {
    position: absolute;
    align-items: center;
    cursor: pointer;
    opacity: 0;

    &:focus {
      border-radius: 20px;
      box-shadow: none;
    }
  }

  & .radio {
    width: 30px;
    height: 30px;
    margin: 10px;
    border: solid 2px;
    border-radius: 50%;
  }

  &__name {
    margin: 0;
    font-size: math.div(18px, 16px) + rem;

    &--category {
      display: block;
      height: auto;
      padding: 2px 5px;
      font-weight: 400;
      font-size: math.div(12px, 16px) + rem;
      line-height: 1;
      text-transform: lowercase;
      border: solid 1px $text;
      border-radius: 3px;
    }
  }

  &__points {
    flex-grow: 2;

    ul {
      padding: 0 !important;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;

      &:before,
      &:after {
        margin: 0 !important;
      }
    }

    &--item {
      margin-bottom: 3px;
    }

    &--tiny {
      margin: 0;
      font-weight: 700;
      font-size: math.div(12px, 16px) + rem;
      line-height: 1;
    }
  }

  &__price {
    align-items: flex-end;
    order: 3;

    &--item {
      margin: 0;
      font-weight: 700;
      font-size: math.div(20px, 16px) + rem;
      line-height: 1;
    }
  }
}

.paymethod {
  padding: 20px;
  background: $sl-gray;

  &__row {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;

    h2,
    p {
      margin: 0;
      padding: 0;
      font-size: math.div(16px, 16px) + rem !important;
      line-height: 1.5;
    }
  }

  &--label {
    width: 86px !important;
    font-weight: 700 !important;
  }

  &__card {
    display: inline-block;
    margin: 0 10px;
    overflow: hidden;
    filter: grayscale(100%) invert(25%);

    &--tiny {
      width: 40px;
      height: 25px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.billing {
  display: flex;
  flex-flow: row wrap;

  &__legend {
    width: 100%;

    &--subtle {
      top: 0;
      left: 0;
      padding: 0;
      color: $gl-indigo;
      background: none;
    }
  }

  &__cardholder,
  &__discount {
    margin: 0 10px;
  }

  &__address,
  &__city,
  &__state,
  &__code,
  &__country {
    width: calc(50% - 20px);
    margin: 0 10px;
  }
}

.payhistory {
  @extend .paymethod;
  padding: 0;
  background: none;

  table {
    border-collapse: collapse;
  }

  &__row {
    background: $sl-gray;
    border: solid 1px rgba($text, 0.5);

    &:nth-child(odd) {
      background: rgba($sl-gray, 0.5);
    }

    thead,
    tbody {
      width: 100%;
    }

    th,
    td {
      padding: 0 10px;
      padding: 10px;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: math.div(16px, 16px) + rem !important;
      line-height: 1.5;
    }
  }
}

.pay-totals {
  padding: 10px;

  p {
    text-transform: uppercase;

    span {
      display: block;
      margin-left: 20px;
      color: black;
      font-size: math.div(18px, 16px) + rem;
    }
  }
}

.StripeElement {
  @extend input;
  height: 40px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background: $sl-gray;

  &--focus {
    @extend input, :focus;
  }

  &--invalid {
    border: solid 1px #fa755a !important;
  }
}

.card--number {
  display: inline-block;
  width: calc(60% - 10px) !important;
  margin-right: 10px;
}

.card--expiry {
  display: inline-block;
  width: calc(25% - 10px) !important;
  margin-right: 10px;
}

.card--cvc {
  display: inline-block;
  width: 15% !important;
}

.credit {
  padding: 10px;
  background: white;
  border: solid 2px $gl-indigo;
  border-radius: 5px;

  &__card {
    display: flex;
    flex-flow: row nowrap;
  }

  & input {
    height: 40px;
    padding: 10px;
    background: $sl-gray;

    &::placeholder {
      color: #aab7c4;
    }
  }

  & label {
    padding: 0 !important;
    background: none !important;
  }
}

.confirmation {
  h2,
  h3 {
    margin-bottom: 0 !important;
    color: $gl-purple;
  }

  em {
    color: $gl-indigo;
  }

  &__totals {
    margin: 20px 0 !important;
  }

  table {
    border-collapse: collapse;

    tfoot {
      tr:nth-child(even) {
        background: $sl-gray;
      }

      td span {
        display: inline-block;
        width: 80px;
      }
    }
  }
}

.checkout {
  h2,
  h3,
  h4 {
    margin-bottom: 0 !important;
    color: $gl-purple;
  }

  p {
    margin: 0;

    & + p {
      &:before {
        display: block;
        margin-top: 24px;
        content: '';
      }
    }
  }

  &__heading {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    div {
      width: 50%;

      h2 {
        text-transform: capitalize !important;
      }
    }
  }

  &__address {
    @extend .checkout__heading;
    justify-content: flex-start;

    p {
      margin: 0;
    }
  }

  &__breakdown {
    table,
    th,
    tr,
    td {
      border-spacing: 0;
    }

    p {
      margin: 0;
    }

    table {
      thead {
        background: black;
      }

      tbody {
        background: $sl-gray;
      }

      tfoot {
        background: white;
      }

      tr {
        p {
          padding: 0;
        }
      }

      th {
        padding: 5px;
        color: white;
      }

      td {
        padding: 5px;

        h4 {
          margin: 0;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        li p {
          font-style: italic;

          &:before {
            display: inline;
            content: '- ';
          }
        }
      }
    }
  }
}

.notifications {
  & fieldset {
    margin-bottom: 20px !important;
  }

  & h2 {
    margin-bottom: 10px;
    color: $gl-purple;

    & + p {
      margin-top: 0;
      padding: 0;
    }
  }
}

.max-form {
  padding-bottom: 40px;
  background: rgba($gl-purple, 1);
}

.calc-box {
  width: 100%;
  padding: 20px;
  font-size: 24px;

  & + ul {
    padding-left: 0;

    & li {
      padding: 5px;
      color: rgba($text, 1);
      font-style: italic;
      list-style-type: none;
    }
  }
}

#note-market {
  padding: 5px;
  color: rgba($text, 1);
  font-style: italic;
  list-style-type: none;
}

.calc-pro-info {
  padding: 20px !important;
  background: white;
  border: solid 1px $in-yellow;

  li {
    color: $text;
    line-height: 2;
    list-style-type: none;
    border-top: 1px solid $sl-gray;

    &:first-child {
      border: none;
    }
  }

  h5 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
  }
}

.calc-cont-info {
  @extend .calc-pro-info;
  background: rgba(white, 1);
  border-color: $sl-gray;

  li {
    border: 0;
  }
}

.calc-pri-info {
  @extend .calc-pro-info;
}

.container .small {
  h3 {
    margin: 0;
    padding: 10px;
    color: $in-yellow;
    background: $text;

    & + ul {
      margin-top: 0 !important;
    }
  }

  & + .selectdiv {
    margin-top: 0 !important;
  }
}

// SEARCH
.srch-h1 {
  margin: 0;
  margin-top: 20px;
}

.srch-cap {
  margin-bottom: 10px;
}

.srch-page {
  b {
    position: relative;
    top: -7px;
  }
}

span.chev-prev {
  #chevron {
    transform: rotate(90deg);

    &.svg-icon {
      fill: $gl-indigo;
    }
  }
}

span.chev-next {
  #chevron {
    transform: rotate(-90deg);

    &.svg-icon {
      fill: $gl-indigo;
    }
  }
}

.srch-filt {
  order: 1;
  margin-right: 20px;

  h1 {
    line-height: 1.2;
  }

  form {
    margin-bottom: 20px;
  }

  fieldset,
  .fieldset {
    margin: 0;
    padding: 0;
    background: $sl-gray !important;
  }

  & select {
    margin-bottom: 0;
    transition: all 0.5s ease !important;
  }

  label {
    display: block;
    height: auto;
    margin: 0;
    padding: 20px;
    border-bottom: solid 1px rgba($text, 0.25);
    border-radius: 0;
  }

  @media only screen and (max-device-width: 680px) {
    width: 50% !important;
  }
  @media only screen and (max-device-width: 480px) {
    width: 100% !important;
  }

  .filter {
    &--visible {
      padding: 20px;
      background: rgba(black, 0.1);
    }

    &--hidden {
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
    }

    &--folded {
      &:before {
        position: relative;
        right: 0;
        display: block;
        float: right;
        color: $gl-indigo;
        font-weight: 700 !important;
        font-family: Nunito, sans-serif;
        content: '+';
      }
    }

    &--unfolded {
      &:before {
        position: relative;
        right: 0;
        display: block;
        float: right;
        color: $gl-indigo;
        font-weight: 700 !important;
        font-family: Nunito, sans-serif;
        content: '-';
      }
    }
  }

  h1,
  p,
  form {
    width: 300px !important;
    @media only screen and (max-device-width: 680px) {
      width: 100% !important;
    }
  }

  .tokens-container {
    margin: 0;
    padding: 0 0 0 5px;
    transition: all 0.5s ease;

    .token {
      display: block;
    }

    .placeholder {
      margin: 0 !important;
    }

    .token-search {
      margin: 0;

      input {
        margin-left: 5px;

        &:focus {
          border-radius: 5px;
          box-shadow: none;
        }
      }
    }
  }

  .srch-curr {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: top;

    & + .srch-curr {
      margin-left: -5px;
    }

    &.filter--hyphen {
      display: inline-block;
      height: 34px;
      background: white;
    }

    & select {
      margin: 0 !important;
      border: 0 !important;

      &#sCurrency {
        z-index: 1;
        width: 15%;
        border-radius: 0.25rem 0 0 0.25rem;
      }

      &#sMinValue {
        z-index: 1;
        width: 42.5%;
        border-radius: 0;
      }

      &#sMaxValue {
        z-index: 1;
        width: 42.5%;
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}

.srch-rslt,
.opp-rslt {
  order: 2;
  width: calc(100% - 320px);

  @media only screen and (max-device-width: 1000px) {
    display: block;
    margin-left: 0;
  }
  @media only screen and (max-device-width: 680px) {
    width: calc(50% - 20px) !important;
  }
  @media only screen and (max-device-width: 480px) {
    width: 100% !important;
  }

  &--meta {
    width: 100%;
  }

  h1,
  p {
    display: block;
    width: 100%;
  }

  .filter--results {
    color: $gl-indigo;
    font-weight: 700;
    font-size: math.div(18px, 16px) + rem;
    line-height: 1.2;
  }

  .opp-tiles {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .tile {
      width: calc((100% - 60px) / 4) !important;

      // &:nth-child(4n) {
      //   margin-right: 0 !important;
      // }

      @media only screen and (max-width: 1920px) {
        width: calc((100% - 40px) / 3) !important;

        &:nth-child(3n) {
          margin-right: 0 !important;
        }
      }

      @media only screen and (max-width: 1000px) {
        width: calc((100% - 20px) / 2) !important;

        &:nth-child(3n) {
          margin-right: 20px !important;
        }

        &:nth-child(2n) {
          margin-right: 0 !important;
        }
      }

      @media only screen and (max-width: 680px) {
        width: calc((100%) / 1) !important;
        margin-right: 0 !important;
      }

      &.tile-opp {
        margin: 0 20px 20px 0;

        @media only screen and (max-device-width: 480px) {
          margin: 0 0 20px;
        }
      }
    }
  }
}

.opp-rslt {
  width: 100%;
}

// ABOUT
.profiles {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .headshot {
    align-items: flex-start;
    order: 1;
    width: 40%;
    margin: 20px;

    &.even {
      order: 2;

      &.tanya {
        width: 338px;
        height: 338px;
      }
    }
  }

  .profiles-info {
    order: 2;
    width: 50%;
    margin: 20px;

    p {
      padding: 0;
    }

    &.even {
      order: 1;
    }
  }
}

.about-social {
  a {
    color: $gl-indigo;
    text-decoration: none;

    & + a {
      margin-left: 10px;
    }
  }

  #twtr-icon,
  #li-icon {
    width: 22px;
    height: auto;
    margin: -4px 0 -4px -4px;
  }

  .svg-icon {
    fill: $gl-indigo;
  }
}

// PITCH BREADCRUMBS
.breadcrumb {
  width: 100% !important;
  height: 40px;
  padding-left: 0;
  overflow: hidden;
  font-size: 18px;
  list-style: none;
  background-color: white;

  // display: flex;
  // flex-flow: row nowrap;
  // justify-content: space-around;

  @media only print {
    display: none;
  }

  li {
    display: flex;
    float: left;

    a {
      position: relative;
      display: block;
      float: left;
      padding: 10px 0 10px 45px;
      color: $text;
      text-decoration: none;
      background: white;
      @media only screen and (max-device-width: 768px) {
        padding-left: 25px;
      }

      span.pit-stp {
        display: none;
        margin-right: 5px;
        padding: 5px 10px;
        color: white;
        font-family: Arial, sans-serif !important;
        background-color: $text;
        border-radius: 25px;
        @media only screen and (max-device-width: 768px) {
          display: inline;
          margin-right: 0;
        }
      }

      span.pit-name {
        @media only screen and (max-device-width: 768px) {
          display: none;
        }
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 100%;
        z-index: 2;
        display: block;
        width: 0;
        height: 0;
        margin-top: -50px;
        border-top: 50px solid transparent;

        /* Go big on the size, and let overflow hide */
        border-bottom: 50px solid transparent;
        border-left: 30px solid white;
        content: ' ';
        @media only screen and (max-device-width: 768px) {
          margin-top: -25px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 20px solid white;
        }
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 100%;
        z-index: 1;
        display: block;
        width: 0;
        height: 0;
        margin-top: -50px;
        margin-left: 1px;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 30px solid $text;
        content: ' ';
        @media only screen and (max-device-width: 768px) {
          margin-top: -25px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 20px solid $text;
        }
      }
    }

    &:first-child a {
      padding-left: 10px;
      @media only screen and (max-device-width: 768px) {
        padding-left: 0;
      }
    }

    &:last-child a {
      padding-right: 10px;
    }

    &:last-child a:after {
      border: 0;
    }

    &:last-child a:before {
      border: 0;
    }

    &.active {
      font-weight: 700;

      a {
        background: white !important;
        cursor: default;
        pointer-events: none;

        span.pit-stp {
          background-color: $ma-cyan;
        }
      }
    }
  }
}

.breadcrumb li a:hover {
  background: rgba($sl-gray, 1) !important;
}

.breadcrumb li a:hover:after {
  border-left-color: rgba($sl-gray, 1) !important;
}

// PITCH
.pit-container {
  .pit-bnr {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: url('../images/consortium/bigstock-LONDON-UK--JANUARY------90004613b_1024px.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    .container {
      &.small {
        max-width: 100%;
        padding: 10px;
        background-color: rgba($co-orange, 0.6);
        border: 0 !important;

        * {
          color: white;
        }

        h1 {
          margin: 0;
          color: $co-red;
        }

        h3 {
          margin: 0;
        }

        p {
          margin: 0 !important;

          & + p {
            margin: 0 !important;
          }
        }
      }
    }

    h2 {
      color: $co-orange;
    }

    h3 {
      color: $co-red !important;
      text-transform: uppercase !important;
    }
  }

  h3 {
    color: $co-red !important;
    text-transform: uppercase !important;
  }

  .pit-pubbed {
    padding: 10px;
    color: color.mix($text, white, 75%);
    border: solid 1px color.mix($text, white, 25%);
    border-radius: 5px;
  }

  li {
    color: $text;
  }

  p {
    &.pit-spec {
      padding-right: 10px;
      font-weight: 700;

      a {
        text-decoration: none;
      }

      span {
        svg {
          position: relative;
          top: 7px;
          margin-left: 5px;
          fill: rgba($gl-indigo, 1);
        }

        &.pits-copro {
          color: rgba($gl-indigo, 1);
          text-transform: uppercase;
        }
      }
    }

    .pits-tar {
      margin: 0 !important;
      text-transform: uppercase;
    }

    &.pit-est {
      margin: 0;
      color: $co-orange;
      font-weight: 700;
      font-size: 1.5rem;
    }

    &.pit-dat {
      color: $co-orange;
      font-size: 1.175rem;
    }

    &.pit-tar {
      margin: 0;
      color: $co-orange;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    // &.pit-par {
    //   color: $co-orange;
    // }
  }

  span {
    &.pit-spec {
      padding-right: 10px;
      font-weight: 700;
    }

    &.pit-spin {
      padding-left: 10px;
      border-left: solid 2px $co-orange;
    }
  }

  .container {
    width: 100%;

    &.small {
      padding: 10px 20px;
      border: solid 1px $co-orange !important;

      &.high {
        background-color: $sl-gray;
        border: 0 !important;
      }

      .pit-high-one {
        display: table-cell;
        width: 47.5%;
        padding-right: 20px;
        vertical-align: top;
        border-right: solid 2px $co-orange;

        p {
          padding: 0;
        }

        span.pit-spec {
          color: $co-red;
        }
      }

      .pit-high-two {
        @extend .pit-high-one;
        padding-right: 0;
        padding-left: 20px;
        border-right: 0;

        ul {
          padding-left: 10px;

          p {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            hyphens: auto;
          }
        }
      }
    }
  }
}

.opportunity {
  &-view {
    padding-bottom: 32px;

    &.consortiums {
      $color1: $co-red;
      $color2: $co-orange;

      h1 {
        margin-bottom: 20px !important;
        color: $color1 !important;
        font-weight: 600;
        font-size: math.div(32px, 16px) + rem;
        line-height: 1;
        letter-spacing: -1px;
      }

      h2 {
        margin: 0 0 20px !important;
        color: $color1 !important;
        font-weight: 700 !important;
        font-size: math.div(18px, 16px) + rem;
        line-height: 1.5;
        letter-spacing: -1px;
      }

      h3 {
        margin: 0 0 20px !important;
        color: $color1 !important;
        line-height: 2;
      }

      p + h1,
      p + h2,
      p + h3 {
        margin-bottom: 40px;
      }

      a {
        color: $color2;
      }
    }

    &.marketplaces {
      $color1: $ma-blue;
      $color2: $ma-cyan;

      h1 {
        color: $color1 !important;
      }

      h2 {
        color: $color1 !important;
      }

      h3 {
        color: $color1 !important;
      }

      a {
        color: $color2;
      }
    }

    &__upsell {
      p {
        margin: 0 !important;

        // padding: 0;
        padding: 20px;
      }

      a,
      .btn,
      .btn-default {
        all: unset;
        text-decoration: underline;

        &:hover {
          all: unset;
          background: transparent !important;
          cursor: pointer;
        }
      }

      input {
        margin-left: 5px !important;
        color: $in-green !important;
      }

      &.full-width {
        background: $in-green;

        p,
        input,
        a {
          color: white !important;
        }
      }
    }
  }

  &-next {
    @extend .full-width;
    padding: 16px;
    background: $in-green;

    &__step {
      display: flex;
      flex-flow: row nowrap;
      gap: 8px;
      align-items: center;
      color: color.mix($in-green, white, 20%) !important;

      &s {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        align-items: center;
        padding: 0 16px;
        list-style-type: none;
      }

      &:first-child {
        margin: 0 !important;
        color: color.mix($in-green, white, 40%);
        font-size: math.div(14rem, 16);
      }

      & > form {
        color: color.mix($in-green, white, 20%) !important;
      }

      & > form > input[type='submit'] {
        all: unset;
      }

      & > a,
      & form > input[type='submit'] {
        display: inline-block;
        padding: 4px 8px;
        overflow: auto;
        color: color.mix($in-green, white, 20%) !important;
        font-weight: 600;
        text-decoration: none;
        background: $in-green;
        border: solid 1px currentColor;
        border-radius: 4px;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--heading {
        padding: 0 !important;
        overflow: unset !important;
        text-decoration: underline !important;
        border: 0 !important;
      }
    }

    &-answers {
      @extend .full-width;
      padding: 16px 16px 32px;
      background: linear-gradient(color.mix(dodgerblue, white, 20%), color.mix(dodgerblue, white, 5%));

      & > h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: calc(100% - 18px);
        padding: 24px 16px 16px;
        color: color.mix($text, white, 90%);
        border-bottom: solid 2px currentColor;

        span {
          font-weight: 400;
          font-size: math.div(16rem, 16);

          & > a {
            color: color.mix($text, white, 40%);
          }
        }
      }

      &__faq {
        padding: 0 16px;

        &s {
          display: flex;
          flex-flow: column nowrap;
          gap: 0;
          padding: 0 16px 0 0;
          list-style-type: none;
        }

        & > h3 {
          margin: 0;
          padding: 8px 0 0;
          color: $gl-indigo !important;
          color: color.mix($text, white, 60%);
          font-weight: 600;

          &:before {
            margin-right: 8px;
            color: color.mix($text, white, 20%);
            font-weight: 500;
            font-family: $fa-style-family, serif;
            content: fa-content($fa-var-question-circle);
          }
        }

        & > p {
          margin: 0;
          padding: 0 0 0 18px;
        }
      }
    }
  }
}

.consortiums {
  $color1: $co-red;
  $color2: $co-orange;

  .pit {
    &-bnr {
      // margin-bottom: 20px !important;
      padding-bottom: 0 !important;
    }

    &-co-profile {
      display: block;
      margin-bottom: 20px !important;
      color: $color2;
    }

    &__card {
      &--one {
        display: flex;
        flex-flow: column;
        padding: 20px !important;
        background: white !important;

        &-deadline,
        &-to-go {
          padding: 0 !important;
        }

        &-to-go {
          order: 2;
          color: $text !important;
          font-weight: 600 !important;
          font-size: math.div(18px, 16px) + rem;
          letter-spacing: -1px;
        }

        &-deadline {
          order: 3;
          color: $text !important;
          letter-spacing: -1px;
        }

        &-interest {
          order: 3;
          color: $text !important;
          letter-spacing: -1px;

          a {
            color: $color2 !important;
          }
        }
      }

      &--two {
        @extend .pit__card--one;
        padding: 20px !important;
        background: transparent !important;

        p {
          margin: 0 0 10px;
          padding: 0;
          letter-spacing: -1px;

          &.pit-est {
            margin: 0 0 10px;
            color: $color1;
            font-weight: 600;
            font-size: math.div(28px, 16px) + rem;
            text-transform: unset;
          }

          &.pit-tar {
            color: $text;
            font-weight: 400;
            font-size: math.div(16px, 16px) + rem;
            text-transform: unset;
          }

          &.pit-dat {
            color: $text !important;
            font-weight: 400;
            font-size: math.div(16px, 16px) + rem;
          }

          & svg#help-circle-outline-icon {
            position: relative;
            top: -5px;
            fill: rgba($text, 0.6);
          }

          &:last-of-type {
            margin: 0;
          }
        }

        ul {
          &.pit-markets-industries {
            display: flex !important;
            flex-flow: row wrap;
            align-items: flex-start;
            margin: 0 0 20px;
            padding: 0;
            list-style-type: none !important;

            li {
              margin: 0 3px 3px 0;
              padding: 5px 10px;
              color: color.mix($text, white, 75%);
              font-size: math.div(16px, 16px) + rem !important;
              letter-spacing: -0.5px;
              background: white;
              border: solid 1px color.mix($text, white, 25%);
            }
          }

          &.pit-skill,
          &.pit-lot {
            // list-style-type: ;
            margin-top: 0 !important;
          }
        }
      }
    }

    &__over,
    &__reqs,
    &__attachments {
      padding: 20px !important;

      h3 {
        margin-top: 0;
        line-height: 1;
      }

      p {
        margin: 0;
        padding: 0;
        letter-spacing: -0.1px;

        &:not(:empty),
        &:not(:empty):last-of-type {
          margin-bottom: 20px;
        }

        &.pit-spec {
          margin-bottom: 5px;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &__document {
      display: inline-block;

      &s {
        margin: 0;
        padding-left: 0;
        list-style-type: none;
      }

      a {
        display: flex;
        align-items: center;
        width: auto;
        padding: 3px 10px 3px 0;
        color: $co-red;
        text-decoration: none;
        background: white;
        border: 1px solid rgba($co-red, 0.5);
        border-radius: 3px;

        &:hover {
          color: white;
          background: color.adjust($co-red, $lightness: -10%);
          border-color: color.adjust($co-red, $lightness: -10%);

          svg {
            path {
              fill: white;
            }
          }
        }
      }

      svg {
        path {
          fill: $co-red;
        }
      }
    }

    &-upsell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px !important;
      border-top: solid 1px $co-orange !important;
      border-bottom: solid 1px $co-orange !important;

      p {
        margin: 0;
        padding: 0;
        font-size: math.div(18px, 16px) + rem !important;
        letter-spacing: -1px;

        span {
          position: unset !important;
          top: unset !important;
          margin: 0 !important;
        }

        input,
        input:hover,
        input:focus,
        input:active,
        a,
        a:hover,
        a:focus,
        a:active {
          margin: 0 !important;
          padding: 0 !important;
          color: $co-orange !important;
          font-weight: 700 !important;
          font-family: Nunito, sans-serif;
          text-decoration: underline !important;
          background: transparent !important;
          cursor: pointer;
        }
      }
    }
  }
}

.marketplaces {
  @extend .consortiums;

  $color1: $ma-blue;
  $color2: $ma-cyan;

  .pit {
    &-bnr {
      background: url('../images/marketplace/bigstock-Beautiful-Dubai-cityscape-bir-85111427-2560px.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    }

    &__card {
      &--one {
        h1,
        h2 {
          color: $color1 !important;
        }

        &-to-go {
          // color: $color2 !important;
          color: $text;
        }

        &-interest {
          order: 3;
          color: $text !important;
          letter-spacing: -1px;

          a {
            color: $color2 !important;
          }
        }
      }

      &--two {
        h3 {
          color: $color1 !important;
        }

        p {
          &.pit-est {
            color: $color1;
          }

          &.pit-dat {
            color: $text !important;
          }
        }
      }
    }

    &__over,
    &__reqs,
    &__attachments {
      h3 {
        color: $color1 !important;
      }

      p {
        margin: 0;
        padding: 0;
        letter-spacing: -0.1px;

        &:not(:empty),
        &:not(:empty):last-of-type {
          margin-bottom: 20px;
        }

        &.pit-spec {
          margin-bottom: 5px;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &__document {
      a {
        color: $color1;
        background: white;
        border: 1px solid rgba($color1, 0.5);

        &:hover {
          color: white;
          background: color.adjust($color1, $lightness: -10%);
          border-color: color.adjust($color1, $lightness: -10%);

          svg {
            path {
              fill: white;
            }
          }
        }
      }

      svg {
        path {
          fill: $color1;
        }
      }
    }

    &-upsell {
      padding: 0 !important;

      p {
        padding: 0 20px;
        color: color.mix($text, white, 75%);

        input,
        input:hover,
        input:focus,
        input:active,
        a,
        a:hover,
        a:focus,
        a:active {
          color: $color2 !important;
        }
      }

      &:first-of-type {
        background: white !important;
        border-top: $color1 !important;
        border-bottom: $color1 !important;
      }
    }
  }
}

.share {
  &__section {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    & > span {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      align-items: center;
    }

    & > form {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      align-items: center;

      button {
        $color1: color.mix($text, white, 40);
        width: 28px;
        height: 28px;
        color: white;
        background: $color1;
        border: none;
        border-radius: 3px;

        & + button {
          color: red;
          background: white;
          border: 1px solid $color1;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 28px;
    height: 32px;
    margin: 0 !important;
    padding: 2px 3px;
    color: color.mix($text, white, 20) !important;
    font-size: math.div(18rem, 16);
  }
}

.soc-share {
  display: inline-block;
  color: white;

  &.facebook,
  &.twitter,
  &.linkedin,
  &.instagram {
    & a > b {
      font-weight: 400;
      @media only screen and (max-device-width: 768px) {
        display: none;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white !important;
    text-decoration: none;
  }

  // & a > b {

  // }

  &.facebook i {
    color: #4267b2;
  }

  &.twitter i {
    color: #4ab3f4;
  }

  &.linkedin i {
    color: #0077b5;
  }

  &.instagram i {
    color: #d6249f;
  }
}

#pReasons {
  overflow-y: scroll;
}

.deadline-instructions {
  margin: 0;
  color: rgba($gl-indigo, 16);
  font-size: 0.825rem;
  line-height: 1.2;
}

// PREVIEW
.pit-views {
  position: relative;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  height: 34px;

  * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

#eye-icon {
  position: relative;
  top: 6px;
  width: 20px;
  fill: $text;
}

#ui-datepicker-div {
  z-index: 200 !important;
}

.ui-datepicker-trigger {
  position: relative;
  top: 8px;
  margin-left: 3px;
}

.pit-upsell {
  padding: 0 20px;
  border-top: solid 5px $gl-indigo;

  p {
    display: inline-block;
    width: 100%;
    font-size: 20px;
    vertical-align: middle;

    .pups-cta {
      position: relative;
      top: 5px;
    }
  }
}

// INVOICE
.in-datnum {
  position: relative;
  top: 0;
  display: inline-block !important;
  width: 49.65%;
  margin-left: 0 !important;
  color: $gl-purple;
  @media only screen and (max-device-width: 620px) {
    width: 100%;
    margin: 0;
    padding: 0 10px;

    &.right-txt {
      text-align: left !important;
    }
  }
}

.incuco {
  & h2 {
    margin: 0;
  }

  & p {
    margin: 0;
  }

  .in-to {
    display: inline-block;
    width: 49.65%;
    vertical-align: top;
    @media only screen and (max-device-width: 620px) {
      width: 100%;
      padding: 0 10px;

      &.right-txt {
        text-align: left !important;
      }
    }
  }

  .in-from {
    @extend .in-to;
  }
}

.in-prodesc {
  display: block !important;
  padding: 5px 0;

  h2 {
    color: $gl-purple;

    span {
      display: block;
    }
  }
}

.inhdr {
  min-height: 24px;
  color: $gl-purple;
  border-bottom: solid 4px $gl-purple;

  & + .inrows {
    span.in-cell {
      border: 0 !important;
    }
  }
}

span.in-hdr {
  float: left;
  width: (100% * 0.25);
  min-height: 23px;
  max-height: 43px;
  margin-left: 0;
  padding: 5px;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;

  &.inh-cde {
    width: 22rem;
  }

  &.inh-qty {
    width: 2rem;
  }

  &.inh-unit {
    width: 5rem;
  }
  @media only screen and (max-device-width: 620px) {
    &.inh-cde {
      width: (100% * 0.25);
    }

    &.inh-qty {
      width: (100% * 0.25);
    }

    &.inh-unit {
      width: (100% * 0.25);
    }

    &.inh-amt {
      width: (100% * 0.25);
    }
  }

  &.inh-nam {
    display: block;
    clear: both;
    width: 100%;
    color: $text;
  }
}

.in-ktot {
  color: $gl-purple;
  font-size: 1.5rem;
}

.sheet {
  display: block;
  clear: both;
  width: 620px;
  margin-bottom: 0 !important;
}

.inrows {
  color: $text;

  .in-row {
    height: 30px + 23px;

    &.odd {
      background: rgba($sl-gray, 0.4);
    }

    &.even {
      background: white;
    }
  }
}

span.in-cell {
  @extend .in-hdr;
  padding-top: 10px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  border-top: solid 1px rgba($text, 0.6);

  &:last-child {
    padding-top: 0 !important;
    border: 0;
  }
}

.in-btns {
  @media only print {
    display: none;
  }
}

.g-recaptcha {
  width: 100%;

  & + div {
    width: 100% !important;
  }

  div {
    display: table;
    width: 100%;
    margin: 0 auto;

    iframe {
      width: 100%;
    }
  }
}

.headline {
  &__additional {
    margin-bottom: 20px;
    padding: 20px;
    background: $sl-gray;
    border: solid 1px rgba($text, 0.25);

    & > h2 {
      margin: 0 0 10px;
      font-size: math.div(20px, 16px) + rem;

      &:after {
        display: block;
        margin: 0 -20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($text, 0.25);
        content: '';
      }
    }

    & > h3 {
      margin: 0 0 10px;
      font-size: math.div(18px, 16px) + rem;
      line-height: 1.2;

      &:after {
        display: block;
        margin: 0 -20px;
        padding-bottom: 10px;
        border-bottom: 1px dashed rgba($text, 0.25);
        content: '';
      }
    }

    & > p {
      margin: 0;
      padding: 0;
    }
  }
}

// PARTICIPATE
.pita {
  padding: 0 !important;
  background: rgba($sl-gray, 0);
  border: solid 1px $sl-gray;

  & + & {
    margin-top: 20px;
  }

  &:nth-child(odd) {
    background: rgba($sl-gray, 0.3);
  }

  &:nth-child(even) {
    background: rgba($sl-gray, 0.7);
  }

  &-head,
  &-det_dates {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 !important;
  }

  &-head {
    a {
      color: $gl-purple;
    }

    h2 {
      margin-right: 10px;
      margin-left: 10px;
      color: text;
      font-weight: 400 !important;
      font-size: 1.12rem;
    }

    h2,
    p {
      margin: 0;
    }
  }

  &-det {
    padding: 0 10px 10px;

    &_prod {
      text-transform: uppercase;
    }

    &_pitch {
      margin-bottom: 10px;
      padding: 10px 20px;
      background-color: white;
      border-bottom: solid 2px rgba($text, 0.3);
    }

    &_dates {
      margin-bottom: 10px;
      padding: 0 !important;
      background-color: transparent !important;
    }

    h3 {
      color: $gl-purple;
      font-weight: 400;
      font-size: 1rem;
    }

    p {
      margin: 0;
    }

    em {
      color: $gl-indigo;
      font-weight: 400;
      font-style: normal;
    }
  }
}

.pitp {
  padding: 20px 40px;
  background: white;
  border: solid 1px rgba($text, 0.3);
  border-bottom: 0;

  p {
    font-weight: 200;
  }
}

.vpit {
  @extend .pita;
  padding: 10px !important;

  h2,
  p {
    margin: 0;
  }

  h2 {
    color: $text;
    font-weight: 400 !important;
    font-size: 1.12rem;
  }

  &:nth-child(odd) {
    background: rgba(white, 1);
  }

  &-head,
  &-det_dates {
    @extend .pita-head;
  }

  &-head {
    a {
      color: $gl-purple;
    }

    h2 {
      margin-right: 10px;
      margin-left: 10px;
      color: text;
      font-weight: 400 !important;
      font-size: 1.12rem;
    }

    h2,
    p {
      margin: 0;
    }
  }

  &-det {
    padding: 0 10px 10px;
  }

  &-det_dates {
    em {
      color: $gl-indigo;
      font-weight: 400;
      font-style: normal;
    }
  }

  .buttons {
    height: 30px;
  }
}

.appfa {
  @extend .pita;
  padding: 10px !important;

  h2,
  p {
    margin: 0;
  }

  h2 {
    color: text;
    font-weight: 400 !important;
    font-size: 1.12rem;
  }

  &-head,
  &-det_dates {
    @extend .pita-head;
  }

  &-head {
    padding: 10px;

    a {
      color: $gl-purple;
    }

    h2 {
      margin-right: 10px;
      margin-left: 10px;
      color: text;
      font-weight: 400 !important;
      font-size: 1.12rem;
    }

    h2,
    p {
      margin: 0;
    }
  }

  &-det {
    &_prod {
      text-transform: uppercase;
    }

    &_pitch {
      @extend .pita-det_pitch;
    }

    &_dates {
      @extend .pita-det_dates;
    }

    h3 {
      color: $gl-purple;
      font-weight: 400;
      font-size: 1rem;
    }

    h3,
    p {
      margin: 0;
    }

    em {
      color: $gl-indigo;
      font-weight: 400;
      font-style: normal;
    }
  }
}

// CRITERIA
.appbop {
  @extend .pita;
  padding: 10px !important;

  h2,
  p {
    margin: 0;
  }

  h2 {
    color: text;
    font-weight: 400 !important;
    font-size: 1.12rem;
  }

  &-head,
  &-det_dates {
    @extend .pita-head;
  }

  &-head {
    padding: 10px !important;

    a {
      color: $gl-purple;
    }

    h2 {
      margin-right: 10px;
      margin-left: 10px;
      color: text;
      font-weight: 400 !important;
      font-size: 1.12rem;
    }

    h2,
    p {
      margin: 0;
    }
  }

  &-det {
    padding: 0 10px 10px;

    &_prod {
      text-transform: uppercase;
    }

    &_paid {
      a {
        color: $text;
      }
    }

    &_pitch {
      @extend .pita-det_pitch;
    }

    &_dates {
      @extend .pita-det_dates;
    }

    h3 {
      color: $gl-purple;
      font-weight: 400;
      font-size: 1rem;
    }

    h3,
    p {
      margin: 0;
    }

    em {
      color: $gl-indigo;
      font-weight: 400;
      font-style: normal;
    }
  }
}

.pec {
  @extend .pita;
  margin-bottom: 30px !important;
  padding: 10px !important;
  background: white !important;
  border-color: $gl-indigo;

  h2,
  p {
    margin: 0;
  }

  h2 {
    color: text;
    font-weight: 400 !important;
    font-size: 1.12rem;
  }

  &-head,
  &-det_dates {
    @extend .pita-head;
  }

  &-head {
    padding: 10px;

    a {
      color: $gl-purple;
    }

    h2 {
      margin-right: 10px;
      margin-left: 10px;
      color: text;
      font-weight: 400 !important;
      font-size: 1.12rem;
    }

    h2,
    p {
      margin: 0;
    }
  }

  &-det {
    padding: 0 10px 10px;

    &_prod {
      text-transform: uppercase;
    }

    &_paid {
      a {
        color: $text;
      }
    }

    &_pitch {
      @extend .pita-det_pitch;
    }

    &_dates {
      @extend .pita-det_dates;
    }

    h3 {
      color: $gl-purple;
      font-weight: 400;
      font-size: 1rem;
    }

    h3,
    p {
      margin: 0;
    }

    em {
      color: $gl-indigo;
      font-weight: 400;
      font-style: normal;
    }
  }
}

.pec-act {
  border-top: solid 1px rgba($text, 0.1);
}

.pitcrit {
  padding: 10px;

  &-head {
    padding: 10px;
    border: solid 1px $gl-indigo;

    a {
      color: $gl-indigo;
    }

    h2,
    p {
      margin: 0;
    }

    &_result {
      text-transform: uppercase;
    }
  }

  &-det {
    display: flex;
    flex-flow: column nowrap;
    padding: 5px;

    &__container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      order: 1;

      &_title {
        display: inline-block;
        flex-grow: 3;
        order: 1;
        margin-top: 3px;
        margin-right: 5px;
        margin-bottom: 3px;
        padding: 3px 5px;
        background: rgba($sl-gray, 0.3);
      }

      &_weight {
        @extend .pitcrit-det__container_title;
        flex-grow: 0;
        align-content: flex-end;
        order: 3;
        margin-right: 0;
        color: $gl-indigo;
        background: white;
        border: solid 1px $gl-indigo;
      }

      &_criteria {
        @extend .pitcrit-det__container_title;
        flex-grow: 0;
        align-content: flex-end;
        order: 2;
        color: $text;
        text-align: right;
        text-transform: uppercase;
        background: rgba($sl-gray, 0.7);
      }
    }
  }
}

.my-account {
  &__panel {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &--list {
      display: inline-block;
      width: calc((100% - 40px) / 3);
      margin: 0 20px 20px 0;
      padding: 20px;
      list-style-type: none;
      background: color.mix($sl-gray, white, 65%);
      border: solid 1px color.mix($text, white, 10%);
      border-radius: 5px;

      @media only screen and (max-device-width: 680px) {
        width: calc(100% - 40px);
        margin: 0 20px 20px;
      }

      lh {
        color: $gl-indigo;
        font-weight: 600;
        font-size: math.div(18px, 16px) + rem;

        &:after {
          display: block;
          margin: 0 -20px 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px color.mix($text, white, 25%);
          content: '';
        }
      }

      li {
        a {
          color: $text;
          text-decoration: none;
        }

        &:not(:last-of-type) {
          &:after {
            display: block;
            margin: 0 0 20px;
            padding-bottom: 20px;
            border-bottom: dashed 1px color.mix($text, white, 25%);
            content: '';
          }
          margin-bottom: 10px;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;

        @media only screen and (max-device-width: 680px) {
          margin-right: 20px;
        }
      }
    }
  }

  &__alert {
    margin-bottom: 20px;
    color: color.mix($gl-purple, white, 75%);
    background: color.mix($gl-purple, white, 15%);
    border-color: color.mix($gl-purple, white, 50%);

    @media only screen and (max-device-width: 1000px) {
      margin: 0 20px 20px;
    }

    &--limited {
      display: inline-block !important;
      margin: 0 !important;
      color: $gl-purple;

      @media only screen and (max-device-width: 1000px) {
        margin: 0 0 20px !important;
      }

      input {
        display: inline-block;
        width: auto;
        padding: 5px 20px;
        color: white !important;
        background: $gl-purple !important;
        border-radius: 15px;

        &:not(:last-of-type) {
          margin-right: 10px;
          @media only screen and (max-device-width: 1000px) {
            margin: 0 0 20px !important;
          }
        }

        &:hover {
          color: white !important;
          background: color.adjust($gl-purple, $lightness: -10%) !important;
        }
      }
    }
  }

  &-trainings {
    @extend .resource-trainings;
    padding-bottom: 0 !important;

    &__popular {
      @extend .resource-trainings__popular;

      &--photo {
        @extend .resource-trainings__popular--photo;
      }

      &--title {
        @extend .resource-trainings__popular--title;
        color: $text !important;
      }

      &--info {
        @extend .resource-trainings__popular--info;
      }

      &--price {
        @extend .resource-trainings__popular--price;
      }

      &--level {
        @extend .resource-trainings__popular--level;
        color: color.mix($text, white, 40) !important;
        font-size: math.div(10rem, 16);
        line-height: 1;
      }

      &--duration {
        @extend .resource-trainings__popular--duration;
        color: color.mix($text, white, 40) !important;
        font-size: math.div(10rem, 16);
        line-height: 1;
      }

      &--button {
        z-index: 99;
        padding: 0 16px 16px;

        .btn {
          background: white !important;

          &:hover {
            background: color.mix(white, $text, 80) !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.interest {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 20px;
  padding: 0 !important;
  background: white !important;
  border: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  // all: initial;

  @media screen and (max-device-width: 680px) {
    width: calc(100% - 40px);
    margin: 0 20px 20px !important;
  }

  &s {
    &__buttons {
      @extend .full-width;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 10px 0;

      // border-top: 1px double $gl-purple;
      background: color.adjust(white, $lightness: -0%) !important;
      box-shadow: 0 -4px 8px rgba(black, 0.15), 0 -8px 16px rgba(black, 0.15);

      // z-index: 199;

      @media screen and (max-device-width: 680px) {
        padding: 10px 20px;
      }

      @media print {
        display: none;
      }

      button {
        &:first-of-type {
          background-color: $gl-purple;

          &:hover {
            background-color: color.adjust($gl-purple, $lightness: -10%) !important;
          }

          svg {
            path {
              fill: white !important;
            }
          }
        }

        &:not(:first-of-type) {
          margin-right: 10px;
        }
      }
    }
  }

  h2 {
    margin: initial;
    padding: 10px 20px;
    font-weight: 600 !important;
    font-size: math.div(20px, 16px) + rem;
    border-bottom: 1px solid;
  }

  &__status {
    padding: 10px 20px;
    border-bottom: 1px solid rgba($text, 0.25);

    // all: initial;

    p {
      margin: initial;
      padding: initial;
    }

    &--prod,
    &--current {
      display: inline-block;
    }

    &--prod {
      text-transform: uppercase;
    }

    &--current a {
      margin-left: 10px;
      padding: 0.05rem 0.5rem !important;
      font-size: math.div(13px, 16px) + rem;
      text-transform: capitalize;
      border: solid 1px;
      border-radius: 3px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__dates {
    @extend .interest__status;

    p {
      display: flex;
      flex-flow: column nowrap;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &--submit,
    &--clarify {
      color: $text !important;

      em {
        display: none;
      }
    }

    &--submit {
      .interest__dates--remain {
        font-weight: 600;
        font-size: math.div(18px, 16px) + rem;
      }
    }

    &--long,
    &--remain {
      &:empty {
        display: none;
      }
    }

    &--proposal,
    &--proposal a {
      color: rgba($text, 0.6) !important;
      letter-spacing: -1px;
    }

    &--long {
      display: none !important;
    }
  }

  &__proposal {
    float: left;
    padding: 0;
    padding-top: 20px;
    color: rgba($text, 0.6) !important;
    font-weight: 400;
    font-size: 16px;
    font-family: Nunito, sans-serif;
    line-height: 1.5;
    letter-spacing: -1px;
    text-decoration: underline;
    background: none !important;
    border: none;
    cursor: pointer;
  }

  &__buttons {
    padding: 20px;
    background: color.mix($text, white, 5%);

    // all: initial;

    form {
      button {
        &:not(:first-of-type) {
          margin-right: 10px !important;
        }
      }
    }
  }

  &.consortium {
    $color1: $co-red;
    $color2: $co-orange;
    border-color: $color1;

    h2 {
      border-bottom-color: $color1;

      a {
        color: $color1;
      }
    }

    .interest {
      &__status {
        &--prod {
          color: $color1 !important;
          background: transparent !important;
        }

        &--current a {
          color: color.mix($color1, white, 75%);
          border-color: color.mix($color1, white, 25%);

          &:hover {
            border-color: color.adjust(color.mix($color1, white, 25%), $lightness: -25%);
          }
        }
      }

      &__dates {
        &--submit {
          .interest__dates--remain {
            color: $color2;
          }
        }
      }
    }
  }

  &.marketplace {
    $color1: $ma-blue;
    $color2: $ma-cyan;
    border-color: $color1;

    h2 {
      border-bottom-color: $color1;

      a {
        color: $color1;
      }
    }

    .interest {
      &__status {
        &--prod {
          color: $color1 !important;
          background: transparent !important;
        }

        &--current a {
          color: color.mix($color1, white, 75%);
          border-color: color.mix($color1, white, 25%);

          &:hover {
            border-color: color.adjust(color.mix($color1, white, 25%), $lightness: -25%);
          }
        }
      }

      &__dates {
        &--submit {
          .interest__dates--remain {
            color: $color2;
          }
        }
      }
    }
  }
}

// Pitches
.pitch {
  @extend .interest;

  &es {
    &__lot {
      margin-bottom: 20px;

      &--heading {
        margin: 0;
        color: rgba($gl-indigo, 0.75);
        font-size: math.div(16px, 16px) + rem;

        &:after {
          display: block;
          margin-bottom: 5px;
          border-bottom: dashed 1px rgba($gl-indigo, 0.5);
          content: '';
        }
      }
    }

    &__buttons {
      @extend .interests__buttons;

      button {
        &:first-of-type {
          background-color: $gl-purple;

          &:hover {
            background-color: color.adjust($gl-purple, $lightness: -10%) !important;
          }

          svg {
            path {
              fill: white !important;
            }
          }
        }

        &:not([style*='display:none']:first-of-type) {
          margin-right: 10px;
        }
      }
    }
  }

  // Pitch Alerts
  &alert {
    @extend .interest;

    h2 {
      color: $gl-purple;
    }

    &__info {
      @extend .interest__dates;

      p,
      ol {
        display: flex;
        flex-flow: column nowrap;
        font-size: math.div(15px, 16px) + rem;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    &__dates {
      @extend .interest__dates;

      &--created {
        font-size: math.div(12px, 16px) + rem;
      }
    }

    &__buttons {
      @extend .interest__buttons;
    }
  }

  &__status {
    @extend .interest__status;

    &--prod {
      @extend .interest__status--prod;
    }

    &--current {
      @extend .interest__status--current;
    }
  }

  &__dates {
    @extend .interest__dates;

    &--submit {
      @extend .interest__dates--submit;
    }

    &--long {
      @extend .interest__dates--long;
    }

    &--remain {
      @extend .interest__dates--remain;
    }

    &--created {
      font-size: math.div(12px, 16px) + rem;
    }
  }

  &__buttons {
    @extend .interest__buttons;
  }

  &-proposal {
    margin-bottom: 20px;
    padding: 20px;
    background: $sl-gray;

    h2 {
      margin: 0;
      font-size: math.div(16px, 16px) + rem;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &__headline {
      &:after {
        display: block;
        margin: 0 -20px 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($text, 0.5);
        content: '';
      }
    }

    &__dates {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px !important;
      border: 1px solid rgba($text, 0.25);
      border-radius: 5px;

      &--submit {
        @extend .pitch__dates--created;
        padding: 0 !important;
      }

      button {
        &:not(:first-of-type) {
          margin-right: 10px;
        }
      }
    }

    &__buttons {
      display: inline-block;
    }

    &s {
      &__headline {
        @extend .headline__additional;

        h2 {
          a {
            color: $gl-purple;
          }
        }

        h3 {
          margin-bottom: unset;

          &:after {
            all: unset;
          }
        }
      }

      &__buttons {
        @extend .interests__buttons;
      }

      &-select {
        &s {
          &__headline {
            @extend .headline__additional;
          }

          &__buttons {
            @extend .interests__buttons;
          }
        }
      }

      &-compare {
        &__headline {
          @extend .headline__additional;
        }

        &__buttons {
          @extend .interests__buttons;
        }
      }
    }

    &-score {
      &s {
        &__headline {
          @extend .headline__additional;

          h2 {
            color: $gl-purple;
          }

          h3 {
            margin: 0;
            color: $gl-indigo;
          }

          h4 {
            margin: 0 0 10px;

            &:after {
              display: block;
              margin: 0 -20px;
              border-bottom: 1px dotted rgba($text, 0.25);
              content: '';
            }
          }
        }

        &__buttons {
          @extend .interests__buttons;
        }
      }

      &__weights {
        margin: 0 0 8px;
        padding: 10px 20px !important;
        border: solid 1px $gl-indigo;

        span {
          &:first-of-type {
            margin-right: 20px;
          }
        }

        strong {
          color: $gl-purple;
          font-weight: 400;
        }

        em {
          color: $gl-indigo;
          font-weight: 700;
          font-style: normal;
        }
      }

      fieldset {
        padding: 20px;

        & > legend {
          margin-bottom: 10px !important;
        }
      }

      &__legend {
        &--line {
          display: block;
          margin: 0 -20px 10px;
          padding: 10px 0 0;
          border-bottom: 1px solid $gl-purple;
          content: '';
        }
      }

      &__buttons {
        position: relative;
        right: 35px;
        bottom: 115px;

        button {
          box-shadow: 0 2px 4px rgba(black, 0.75), 0 6px 16px rgba(black, 0.05);

          &:hover {
            box-shadow: 0 0 0;
          }
        }
      }
    }

    &-select {
      padding: 5px !important;

      legend {
        top: 36px !important;
        display: block;

        // color: $gl-indigo !important;
        width: 100%;
        margin-bottom: 20px !important;
        padding: 0;

        &:after {
          display: block;
          margin: 0 -10px 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($text, 0.25);
          content: '';
        }
      }

      &__option {
        padding: 5px;

        // background: white;
        border: solid 1px color.mix($text, white, 25%);

        // color: $text;
        border-radius: 3px;

        &:checked,
        &:active,
        &:focus {
          color: white !important;
        }
      }
    }

    &-compare {
      &__radios {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        & > ul {
          width: calc(50% - 10px);
        }
      }

      &__chart {
        width: 100%;
        border: 1px solid color.mix($text, white, 5%) !important;

        &s {
          margin-bottom: 20px;

          form {
            & > fieldset {
              padding: 20px !important;
            }
          }
        }
      }
    }
  }
}

.extend {
  &__buttons {
    @extend .interests__buttons;
  }
}

/* Proposal */
.proposal {
  &s {
    &-print {
      h2,
      h3 {
        margin: 0 !important;
        line-height: 1.5;
      }

      h2 {
        color: $gl-purple;
      }

      h3 {
        color: $gl-indigo;
      }
    }

    &-view {
      &__heading {
        @extend .headline__additional;
      }

      &__buttons {
        @extend .interests__buttons;
      }
    }
  }

  &-view {
    @extend .interest;

    @media screen and (max-device-width: 680px) {
      width: calc(100% - 40px);
      margin: 0 20px 20px !important;
    }

    &__buttons {
      @extend .interest__buttons;
    }

    &__attachment {
      display: flex;
      flex-flow: row wrap;

      &s {
        margin-bottom: 20px;

        & > h2 {
          margin-top: 0 !important;
        }
      }

      &--delete {
        @extend .btn__rectangle--min;
        position: relative;
        right: 0;
        color: color.adjust($sl-gray, $lightness: -10%) !important;
        background: transparent;
        border-color: color.adjust($sl-gray, $lightness: -1%) !important;

        &:hover {
          color: text !important;
          background: white !important;
          border-color: white !important;
        }
      }

      &--details {
        display: inline-flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        order: 2;
        width: calc(50% - 10px);
        padding: 10px 10px 10px 5px;
        background: white;
        border: solid 1px $sl-gray;
        border-radius: 5px;

        svg {
          path {
            fill: $sl-gray;
          }
        }

        &:not(:nth-child(2n + 1)) {
          margin-right: 15px;
        }

        &:hover {
          color: white;
          background: $gl-purple;
        }
      }

      &--info {
        display: inline-block;
        width: calc(100% - 50px);
        margin: 0;
        padding-left: 0;
        color: $text;
        list-style-type: none;

        a {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
          text-decoration: none;

          &:hover span {
            color: white;
          }
        }
      }

      &--filetitle,
      &--filetype,
      &--filedate {
        display: block;
        color: $text;
      }

      &--filetitle {
        margin-bottom: 5px;
        color: blue;
        text-decoration: underline;
      }

      &--filetype {
        margin-bottom: 0;
      }
    }

    &__status {
      @extend .interest__status;

      &--prod {
        @extend .interest__status--prod;
      }

      &--current {
        @extend .interest__status--current;
      }
    }

    &__dates {
      @extend .interest__dates;

      &--submit {
        @extend .interest__dates--submit;
        margin: 0;
      }

      &--remain {
        @extend .interest__dates--remain;
      }

      &--long {
        @extend .interest__dates--long;
      }

      &--created {
        flex-flow: row nowrap !important;
        font-size: math.div(12px, 16px) + rem;
      }
    }
  }

  &-print {
    &__heading {
      @extend .clarifications__heading;
    }

    &__overview,
    &__reference {
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid $sl-gray;

      h2 {
        position: relative;
        right: -20px;
        padding: 5px 20px;
        border: solid $gl-purple;
        border-width: 1px 0 1px 1px;
      }

      p {
        margin: 0 0 20px;
        padding: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__reference {
      &--contract-detail {
        &:not(:nth-child(6)) {
          margin-bottom: 5px;
        }
      }
    }

    &__buttons {
      @extend .clarifications__buttons;
    }
  }
}

// Pitch Clarification
.clarifications {
  &:not(body) {
    padding: 10px;
    padding-top: 30px;
  }

  h2,
  h3 {
    margin: 20px 0 20px !important;
    line-height: 1.5;
  }

  h2 {
    color: $gl-purple;
    font-weight: 600 !important;
    font-size: math.div(18px, 16px) + rem;
  }

  h3 {
    font-weight: 700 !important;
    font-size: math.div(16px, 16px) + rem;

    // &:last-of-type {
    //   margin: 0 !important;
    // }
  }

  &__heading {
    margin-bottom: 20px;
    padding: 20px;

    // text-align: center;
    background: $sl-gray;

    p {
      margin: 0;
      padding: 0;

      &:not(:last-of-type) {
        margin: 0 0 10px;
      }
    }
  }

  &__sign {
    &--open,
    &--closed {
      position: relative;
      top: -0.15rem;
      margin-right: 10px;
      padding: 0.15rem 0.5rem;
      color: white;
      font-weight: 500;
      font-size: math.div(10px, 16px) + rem !important;
      border-radius: 3px;
    }

    &--open {
      background: $in-green;
    }

    &--closed {
      background: $co-red;
    }
  }

  &__message {
    font-weight: 700;
  }

  &__buttons {
    @extend .full-width;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    padding: 10px 0;
    background: color.adjust(white, $lightness: -0%);
    box-shadow: 0 -4px 8px rgba(black, 0.15), 0 -8px 16px rgba(black, 0.15);

    @media screen and (max-device-width: 680px) {
      padding: 10px 20px;
    }

    @media print {
      display: none;
    }

    button {
      &:first-of-type {
        background-color: $gl-purple;

        &:hover {
          background-color: color.adjust($gl-purple, $lightness: -10%) !important;
        }

        svg {
          path {
            fill: white !important;
          }
        }

        & > .fa,
        & > .far,
        & > .fas,
        & > .fab {
          color: white !important;
        }
      }

      &:not(:first-of-type) {
        margin-right: 10px;
      }
    }
  }

  &__answered,
  &__unanswered {
    margin-top: 20px !important;
    padding: 3px 10px;
    color: rgba($gl-purple, 0.75);
    border-bottom: 1px solid rgba($gl-purple, 0.5);
  }

  &__separator {
    padding-bottom: 10px !important;
    border-bottom: 4px solid rgba($gl-indigo, 0.5);
  }

  &__separator2 {
    padding-bottom: 10px !important;
    border-bottom: 4px solid $gl-purple;
  }

  &__select {
    width: calc(100% - 50px);
    height: 34px;
    color: white;
    font-size: 16px;
    background-color: $gl-purple;
  }

  // Requests
  &__request {
    &--data {
      p {
        display: inline-block;
        width: calc(100% - 20px);
        margin: 0 0 5px 20px;
        padding: 0;
        font-size: math.div(12px, 16px) + rem;

        span {
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }

    &--buttons {
      & > form {
        display: inline-block;
        width: 40px !important;
      }
    }

    &--message {
      position: relative;
      right: 0;
      left: unset;
      display: inline-block;
      width: calc(100% - 50px - 20px);
      margin: 0 10px 20px 20px;
      padding: 10px;
      background: $gl-indigo;
      border-radius: 10px 10px 0;

      p {
        margin: 0;

        &:not(:empty) {
          margin-top: 0;
          padding: 0;
          color: white;
          font-weight: 500;
        }

        &:empty {
          display: none;
        }
      }

      ul,
      ol {
        margin: 0;
        padding-left: 20px;
        color: white;
        list-style-type: unset;

        li {
          font-size: unset;
        }
      }

      ol {
        list-style-type: decimal;
      }
    }

    &--draft {
      background: transparent;
      border: 1px solid color.mix($text, white, 20%);

      p,
      ul,
      ol {
        color: color.mix($text, white, 60%) !important;
      }
    }

    &--lots {
      margin: 0;
      padding: 0 !important;
      color: white;
      font-size: math.div(12px, 16px) + rem;
      list-style-type: none !important;

      &:after {
        display: block;
        width: calc(100% + 20px);
        margin: 10px -10px;
        content: '';
      }
    }

    &--lot-item {
      text-transform: lowercase;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }

    &--sent {
      width: calc(100% - 20px);
      margin-right: 0;
    }

    &--documents {
      margin: 0;
      padding: 0 !important;
      list-style-type: none !important;
    }

    &--document-id {
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;

        span {
          margin-right: 5px;

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    &--attach-title {
      margin: 0 0 5px;
      color: white;
      font-weight: 700;
      font-size: math.div(12px, 16px) + rem;
      line-height: 1;
      text-transform: uppercase;

      &:before {
        display: block;
        margin-bottom: 10px;
        content: '';
      }

      &:after {
        display: block;
        width: calc(100% + 20px);
        margin: 5px -10px 0;
        border-bottom: solid 1px rgba(white, 0.5);
        content: '';
      }
    }
  }

  // Responses
  &__response {
    @extend .clarifications__request;

    &--data {
      @extend .clarifications__request--data;

      p {
        width: 100%;
        margin-left: unset;
        text-align: right;
      }
      margin-right: 20px;
      margin-left: unset;
    }

    &--buttons {
      @extend .clarifications__request--buttons;
    }

    &--message {
      @extend .clarifications__request--message;
      right: unset;
      left: 0;
      width: calc(100% - 20px);
      margin-right: 20px;
      margin-left: unset;
      background: $sl-gray;
      border-radius: 10px 10px 10px 0;

      p {
        &:not(:empty) {
          color: $text;
        }
      }

      ul,
      ol {
        color: $text;
      }
    }

    &--draft {
      @extend .clarifications__request--draft;
    }

    &--lots {
      @extend .clarifications__request--lots;

      &:after {
        border-color: rgba($text, 0.5);
      }
    }

    &--lot-item {
      @extend .clarifications__request--lot-item;
    }

    &--wait {
      width: calc(100% - 50px) !important;
      margin-right: 10px;
    }

    &--documents {
      @extend .clarifications__request--documents;
    }

    &--document-id {
      @extend .clarifications__request--document-id;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $text;

        span {
          margin-right: 5px;

          svg {
            path {
              fill: $text;
            }
          }
        }
      }
    }

    &--attach-title {
      @extend .clarifications__request--attach-title;
      color: $text !important;

      &:before {
        display: block;
        margin-bottom: 10px;
        content: '';
      }

      &:after {
        border-bottom-color: rgba($text, 0.25);
      }
    }
  }
}

.crit {
  &eria {
    &-view {
      &__heading {
        @extend .proposals-view__heading;
      }

      &__lot {
        margin-bottom: 20px;
        padding-bottom: 0 !important;

        & > legend {
          top: 36px !important;
          display: block;
          width: 100%;
          margin-bottom: 0 !important;
          padding: 0;
          color: $gl-indigo !important;

          &:after {
            display: block;
            margin: 0 -10px 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid $gl-indigo;
            content: '';
          }
        }

        &--buttons {
          @extend .interest__buttons;
          display: flex;
          justify-content: flex-end;
          margin: 0 -10px;
          padding: 10px;
          background: rgba($sl-gray, 1);
          border-top: 1px solid rgba($text, 0.25);

          button {
            &:not(:last-of-type) {
              margin-right: 10px;
            }
          }
        }
      }

      &__buttons {
        @extend .clarifications__buttons;
      }
    }
  }

  &erion {
    margin-bottom: 20px;

    &:not(:last-of-type) {
      margin: 0;

      &:after {
        display: block;
        margin: 0 -10px;
        padding-bottom: 20px;
        border-bottom: dashed 1px rgba($text, 0.25);
        content: '';
      }
    }

    & > legend {
      display: block;
      width: 100%;
      padding: 10px;

      &:after {
        display: block;
        margin: 0 -30px 0 -10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($text, 0.25);
        content: '';
      }
    }

    &__input {
      &--mandatory {
        display: inline-block;
        width: calc(25% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
      }

      &--name {
        display: inline-block;
        width: calc(60% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
      }

      &--weight {
        display: inline-block;
        width: calc(13.5% - 0px);
        margin-bottom: 10px;
      }
    }

    &__dates {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 10px;
      font-size: math.div(12px, 16px) + rem;
      border: 1px solid rgba($text, 0.25);
      border-radius: 5px;
    }

    &__buttons {
      @extend .interest__buttons;
    }
  }
}

.company {
  &-profile {
    &__info {
      margin-bottom: 20px;

      &--list {
        margin: 0 0 20px;
        padding: 0 20px;
        list-style-type: none;

        li {
          i {
            width: 18px;
            margin-right: 10px;
            color: color.mix($text, white, 20%);
          }

          &:not(:last-child) {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: solid 1px $sl-gray;
          }
        }
      }

      p {
        margin: 0 0 40px;
        padding: 0;
      }
    }
  }
}

.business-contacts,
.manage-suppliers,
.manage-alerts,
.company-profile,
.consortiums {
  &__buttons {
    @extend .clarifications__buttons;
  }
}

.advertise__buttons {
  @extend .interests__buttons;
}

/**
 *
 * Industries
 *
 */

.indust {
  &ries {
    padding: 16px;
  }

  &ry {
    &-types {
      grid-column-gap: 16px;
      columns: 4;
      column-gap: 16px;

      a {
        text-decoration: none;
      }

      h2 {
        display: flex;
        flex-flow: column;
        gap: 8px;
        align-items: center;
        margin: 0 !important;
        padding-top: 8px;
        font-size: calc(16rem / 16);

        & > span {
          padding-top: 8px;
        }
      }

      & > div {
        display: inline-block;
        width: 100%;
        margin-bottom: 16px;
        overflow: hidden;
        border: 1px solid color.mix($text, white, 15%);
        border-radius: 8px;
        box-shadow: 0 4px 16px color.mix($text, white, 20%);
      }
    }

    &-type {
      padding: 16px;
      background: color.mix(dodgerblue, white, 15%);
      // border-radius: 8px 8px 0 0;
      // border: 1px solid;
    }

    &__sub-type {
      &s {
        margin: 0;
        padding: 16px;
        list-style-type: none;
        background: color.mix($text, white, 0%);

        & > div > a{
          display: flex;
          // gap: 8px;
        }
      }
    }
  }
}

// BLOG
.contribute {
  position: relative;
  margin: 0;
  padding: 0;

  .contrib-cta {
    font-weight: 500;
    font-size: 20px;
    list-style-type: none;

    a {
      color: rgba($gl-indigo, 1);
      text-decoration: none;
    }
  }
}

.author {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background: rgba($sl-gray, 0.6);

  h2,
  h3,
  p {
    margin: 0;
    color: $text;
  }

  p {
    font-family: Georgia, serif;
  }

  h2 {
    color: $gl-indigo;
    font-size: 20px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 16px;
    text-transform: uppercase;
  }

  .svg-icon {
    fill: $gl-indigo;
  }

  .auth-profile {
    order: 1;
  }

  .auth-profile p,
  li {
    padding-bottom: 10px;
  }

  .auth-accts {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    order: 2;

    a {
      align-items: center;
      margin: 5px;
    }
  }
}

.art-h1 {
  order: 2;
  color: $text;
  font-weight: 700;
  font-family: Arial, sans-serif;
  line-height: 1.2;
  text-transform: capitalize;
}

h1 {
  #rss-icon {
    width: 24px;
    height: auto;
    margin: 0 0 -2px 5px;
  }

  .svg-icon {
    fill: $co-orange;

    &:hover {
      fill: rgba(black, 0.6);
    }
  }
}

.story_summary {
  padding: 20px 0;

  ul {
    margin: 0;
    padding: 0;
  }
}

.sections {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 10px;

  &-icon {
    display: inline-block;
    order: 1;
    width: 24px;
    height: 24px;
    background: black;
  }

  &-set {
    display: flex;
    flex-flow: column nowrap;
    width: calc((100% - 60px) / 2);
    margin: 10px;

    &-article-thumb {
      width: 60px;
      margin: 10px;
    }

    &-recent {
      h2 {
        margin: 5px 10px;
        font-size: 0.825rem;
        line-height: 1rem;

        span {
          display: block;
          margin-bottom: 5px;
          padding: 1px 2px;
          color: $gl-indigo;
          font-size: 0.625rem;
          text-transform: uppercase;
          background: white;
          border: solid 1px $gl-indigo;
        }

        a {
          margin: 0;
          padding: 0;
          color: $gl-indigo;
          text-transform: none;
          text-decoration: none !important;
          background: transparent;
        }
      }
    }

    &-link {
      padding: 3px 5px;
      color: white;
      font-size: 0.725rem;
      font-family: Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      background: $gl-purple;
    }
  }
}

.article_info ul li {
  display: inline;
}

.article_body {
  margin-top: 10px / 2;
  overflow: auto;
}

.article-photo-container {
  width: 100%;

  & img {
    width: 100%;
  }
}

@media only screen and (max-device-width: 480px) {
  .article-photo-container {
    width: 100%;
  }
}

.art-upsell-pnl {
  margin: 0;
}

.art {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;

  .pop-pitch {
    width: calc((100% - 60px) / 3);
    padding: 0 10px 10px;
    background: rgba(white, 0.6);

    @media only screen and (max-device-width: 480px) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  .story_summary {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: math.div(880px, 3);
    margin: 20px;
    padding: 0;

    &.art-upsell {
      width: 290px;
      @media only screen and (max-device-width: 480px) {
        width: 100%;
      }
    }

    h2 {
      margin: 0;
    }
    @media only screen and (max-device-width: 768px) {
      width: 50%;
    }
    @media only screen and (max-device-width: 480px) {
      width: 100%;
    }

    .article_thumbnail {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      justify-content: flex-start;
      order: 1;
      width: 100%;

      a {
        height: 180px;
        overflow: hidden;

        img {
          min-width: 100% !important;
        }
      }
    }

    .headline {
      flex-grow: 1;
      align-items: baseline;
      order: 2;
      width: 100%;
      padding: 10px;

      .art-h2 {
        margin: 0;
        font-size: 18px;
        font-family: Nunito, sans-serif;
        line-height: 1.2;

        a {
          color: rgba($gl-indigo, 1);
          font-weight: 700 !important;
          text-decoration: none;
        }
      }
    }

    .art-det {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 0;
      justify-content: space-between;
      order: 3;
      width: 100%;
      padding: 0 10px 10px;

      .article_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;
        margin: 5 px;

        .article_link {
          display: block;
          align-content: center;
          order: 1;
          margin: 10px;
          font-size: 13px;
          font-family: Arial, sans-serif;
          text-align: center;
          widows: 100%;

          a {
            display: block;
            padding: 10px;
            color: white;
            letter-spacing: 0.05rem;
            background: $gl-purple;
            border-radius: 5px;
          }
        }
      }

      .article_info {
        order: 1;
        color: $text;

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .byline {
            order: 1;
            margin-right: 10px;
            color: $text;

            a {
              color: $text;
            }
          }

          .published {
            order: 2;
            margin-top: 4px;
            font-size: 13px;
            letter-spacing: -0.025rem;
            opacity: 0.6;
          }
        }
      }
    }

    p {
      color: rgba($text, 0.9);
      font-weight: 500 !important;
      font-size: 16px;
      font-family: Georgia, serif;
      line-height: 1.8;
    }
  }
}

.art-box {
  margin-top: 0 !important;
}

.art-full {
  iframe {
    width: 100% !important;
    margin: 20px 0 !important;
  }

  .full_article {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    .art-sect {
      padding: 0;

      .art-sect_info {
        display: inline-block;
        order: 1;
        margin-top: 20px;
        font-size: 13px;

        a {
          margin-right: 5px;
          padding: 5px 0.25rem;
          color: $gl-indigo;
          border: solid 1px $gl-indigo;
        }
      }
    }

    h2 {
      color: black;
    }

    .art-h1 {
      order: 2;
      color: black;
      font-weight: 700;
      font-family: Arial, sans-serif;
      text-transform: capitalize;

      .svg-icon {
        fill: rgba($co-orange, 1);
      }
    }

    .article_info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      order: 3;

      .art-sect {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        order: 1;
        padding: 0;
      }

      .svg-icon {
        fill: rgba($gl-indigo, 1);
      }

      .art-credit {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        order: 2;
        margin-bottom: 20px;
        padding: 0;
        font-size: 14px !important;

        li {
          align-items: center;
          order: 1;
          margin-right: 1rem;
          padding: 0;

          &.art-end_credit {
            &.rss a {
              color: black;
              text-decoration: none;

              &:hover {
                color: rgba(black, 0.6);

                .svg-icon {
                  fill: rgba(black, 0.6) !important;
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }

            &:after {
              margin: 0;
              content: '';
            }
          }

          &:after {
            margin-left: 1rem;
            color: $gl-indigo;
            content: '|';
          }
        }

        #eye-icon {
          width: 22px;
          height: auto;
          margin: -10px 4px 0 -1px;

          .svg-icon {
            fill: $gl-indigo;
          }
        }

        #rss-icon {
          width: 18px;
          height: auto;
          margin: -3px 2px -3px -3px;

          .svg-icon {
            fill: $co-orange;
          }
        }

        #published-icon {
          width: 13px;
          height: 13px;
        }
      }
    }

    .article-media-controls {
      display: block;
      order: 3;
      height: 30px;
      margin-bottom: 10px !important;

      #blg-listen--frm {
        display: inline-block;
      }
    }

    .art-share {
      &-one {
        order: 4;
        border: solid rgba($text, 0.3);
        border-width: 1px 0;
      }

      &-two {
        border: solid rgba($text, 0.3);
        border-width: 1px 0;
      }
    }

    .article-photo-container {
      order: 4;
      margin: 0;
    }

    .art-content {
      order: 5;
      margin: 10px;

      p:first-child {
        margin-top: 0;
      }

      ul,
      ol {
        li {
          color: black;
          font-family: Georgia, serif !important;
          line-height: 1.5rem;
          list-style-type: disc;
        }
      }

      ol li {
        list-style-type: decimal;
      }

      blockquote {
        &:before {
          content: '"';
        }

        &:after {
          content: '"';
        }
        margin: 20px 0;
        color: $gl-purple;
        font-weight: 500;
        font-size: 2rem;
        font-family: Georgia, serif;
        font-style: italic;

        cite {
          font-weight: 300;
          font-size: 1rem;

          &:before {
            display: inline;
            content: '~ ';
          }
        }
      }
    }

    .art-social {
      order: 6;
      margin: 10px;
    }

    .art-disclaimer {
      order: 6;
      margin: 10px;

      h2,
      h3 {
        margin: 0;
        font-size: math.div(14px, 16px) + rem !important;
        text-decoration: underline;
      }

      p {
        margin: 0;
        font-size: math.div(12px, 16px) + rem !important;
        font-style: italic;
      }
    }
  }

  p {
    color: black;
    font-family: Georgia, sans-serif;
  }

  figure {
    width: 100%;
    margin: 20px 0;

    img {
      width: 100% !important;
    }

    figcaption {
      position: relative;
      bottom: 4px;
      padding: 10px 1.2rem;
      font-size: 13px;
      font-family: Arial, sans-serif;
      background: rgba($text, 0.25);

      span.art-fig-credit {
        margin-left: 5px;
        color: rgba($gl-purple, 1);
        font-style: italic;

        &:before {
          margin-right: 5px;
          border-left: 1px solid;
          content: '';
        }
      }
    }
  }
}

// PODCAST
.podcasts {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 10px;

  @media only screen and (max-device-width: 480px) {
    flex-flow: column nowrap;
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__card {
    display: block;
    width: calc((100% / 3) - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: $sl-gray;
    transition: all 0.5s ease;

    @media only screen and (max-device-width: 480px) {
      width: calc(100%);

      &:nth-child(even) {
        background: white;
      }
    }

    &:hover {
      background: black;

      h2 {
        color: white;

        span {
          color: white;
        }
      }

      ul {
        background: none;

        li {
          color: white;
        }
      }
    }

    h2 {
      margin-top: 0;
      font-weight: 700 !important;
      font-size: math.div(24px, 16px) + rem;

      span {
        display: block;
        color: $gl-indigo;
        font-weight: 900;
        font-size: math.div(32px, 16px) + rem;
      }
    }

    ul {
      width: 100%;
      margin: 0;
      padding: 8px;
      list-style-type: none;

      li {
        display: inline;
        color: $text;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }
  }

  &__list {
    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;
      padding: 10px;

      &:not(:nth-child(odd)) {
        background: $sl-gray;
      }
    }

    &--form {
      display: flex;
      flex-flow: row nowrap;
    }

    &--item {
      text-align: left;
      list-style-type: none;

      .btn {
        justify-content: center;
        width: 40px;
        height: 40px;
      }
    }
  }

  &__item {
    &--ep {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50px;
      padding: 5px;
    }

    &--title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 165px);
      padding: 5px;
    }

    &--action {
      width: 40px;
      margin-left: 10px;
      text-align: center;
    }
  }
}

.scale {
  &__cross {
    display: inline-block;
    font-weight: 700;
    transform: scale(1.5, 1.75);
  }

  &__prev {
    @extend .scale__cross;
    display: flex;
    justify-content: center;
    transform: scale(1.25);

    &.secondary {
      svg {
        fill: white !important;
      }
    }

    svg {
      fill: $text;
    }
  }

  &__next {
    @extend .scale__cross;
    display: flex;
    justify-content: center;
    transform: scale(1.25);

    &.primary {
      svg {
        fill: white !important;
      }
    }

    svg {
      fill: $text;
    }
  }

  &__add {
    @extend .scale__cross;
    transform: scale(1.75);
  }

  &__view {
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg {
      &#eye-icon,
      &#eye-outline-icon {
        all: unset;

        .svg-icon {
          fill: $text !important;
        }
      }
    }

    &.secondary {
      svg {
        fill: white !important;
      }
    }
  }

  &__pencil {
    display: inline-block;
    transform: scale(-1.1, 1.1) rotate(10deg);
  }

  &__check {
    display: inline-block;
    font-weight: 700;
  }

  &__bookmark {
    svg {
      margin-left: -2px;

      .svg-icon {
        fill: $text;
      }
    }
  }

  &__check-circle {
    svg {
      margin: 0 0 -4px -2px;

      &#check-circle-outline-icon {
        .svg-icon {
          fill: $text;
        }
      }

      .svg-icon {
        fill: $gl-purple;
      }
    }
  }

  &__minus-circle {
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg {
      .svg-icon {
        fill: $text;
      }
    }
  }

  &__clarify {
    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: scale(1);

    svg {
      path {
        fill: $text;
      }
    }

    &.primary,
    &.secondary {
      path {
        fill: white;
      }
    }

    // &:first-of-type {
    //   &.fa, &.far, &.fas, &.fab {
    //     color: white;
    //   }
    // }
  }

  &__respond,
  &__restore,
  &__propose,
  &__show-all,
  &__plus,
  &__prev,
  &__edit,
  &__delete,
  &__default,
  &__criteria,
  &__print,
  &__submit,
  &__ {
    @extend .scale__clarify;
  }
}

.icon {
  &__box {
    display: inline-block;
    width: 34px;
    height: 34px;
    padding: 5px;

    span {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &--big {
      @extend .icon__box;
      width: unset;
      height: unset;
      padding: 1px;

      span {
        svg {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

.divide {
  display: block;
  margin-bottom: 40px;
}

body.podcast,
body.podcasts {
  display: block;
}

a.logo.podcast,
a.logo.podcasts {
  width: auto;
}

.podcast {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  @media only screen and (max-device-width: 480px) {
    flex-flow: column nowrap;
  }

  &__core {
    width: 644px;
    margin: 0 20px 0 0;
    padding: 0 20px;

    @media only screen and (max-device-width: 768px) {
      width: (math.div(644px, 1000px) * 100%);
      padding: 0 10px;
    }
    @media only screen and (max-device-width: 480px) {
      width: 100%;
      margin: 0;
    }
  }

  &__indices {
    display: block;
    padding: 0;
  }

  &__add-chapter,
  &__add-reference {
    fieldset {
      margin-bottom: 5px;

      fieldset {
        border: none;
      }
    }
  }

  &__modify-chapter,
  &__modify-reference {
    fieldset {
      fieldset {
        margin: 0;
      }
      margin-bottom: 5px;
    }
  }

  &__chapter {
    margin-bottom: 10px;
    overflow: hidden;
    color: $text;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    list-style-position: inside;
    list-style-type: none;

    &:before {
      position: relative;
      top: 0;
      padding-right: 0.25rem;
      color: $gl-indigo;
      font-size: 1em;
      content: '\2022';
    }

    &--index {
      margin-right: 10px;

      a {
        color: $gl-indigo;
        font-weight: 900;
      }
    }
  }

  &__sidebar {
    width: 312px;
    margin: 0;
    padding: 10px 20px 20px;
    background: $sl-gray;

    @media only screen and (max-device-width: 768px) {
      width: (math.div(312px, 1000px) * 100%);
      padding: 10px;
    }
    @media only screen and (max-device-width: 480px) {
      width: 100%;
    }

    h3 {
      margin: 0;
    }

    p {
      margin-top: 0;
      padding: 0;
    }
  }

  &__topics {
    & ul {
      padding: 0 0 0 30px;
      color: $text;
      list-style: none;

      & > li {
        &:before {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 8px;
          margin-left: -30px;
          padding: 5px;
          font-weight: 700;
          font-size: math.div(12px, 16px) + rem;
          border: solid 1px $text;
          border-radius: 50%;
          content: '\2713';
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 5px;
    list-style-type: none;

    a {
      color: $gl-indigo;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &s {
      margin-top: 0;
      padding: 0;
    }
  }

  &__keyword {
    display: inline-block;
    color: $text;

    &:not(:last-child) {
      &:after {
        content: ', ';
      }
    }

    &s {
      margin-top: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  &__divider {
    display: block;
    width: 100%;
    margin: 0 0 20px !important;
    border-bottom: 1px solid black;
    content: '';

    &--med {
      color: black;
      font-size: math.div(16px, 16px) + rem;
    }
  }

  &__subscribe {
    margin: 0;
    padding: 0;
  }

  &__google,
  &__apple {
    display: block;
    text-align: center;
    list-style-type: none;

    &:not(.podcast__apple) {
      margin-bottom: 10px;
    }

    img {
      width: 70%;
    }
  }
}

.supplier {
  width: 100%;
  padding: 0 !important;

  &__list {
    display: flex;
    justify-content: flex-start;
    height: 50px;
    margin: 0 !important;
    list-style-type: none;

    &:nth-child(odd) {
      background: $sl-gray;
    }

    &--data {
      display: flex;
      align-items: center;
      width: calc((100% - 112px) / 2);
      padding: 5px 10px;

      &:last-child {
        width: 112px !important;
        border-left: solid 1px $text;
      }
    }
  }
}

.zero {
  &__given {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 30vh;
    padding: 16px !important;

    &--min {
      height: auto;
    }

    h2,
    p,
    form {
      margin-top: 0;
      padding: 0;
      text-align: center;
    }
  }
}

// DISCOUNTS
.discounts {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;

  .dis-view {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    color: $text;

    form {
      display: flex;
      width: 100%;
    }

    .dis-code,
    .dis-title,
    .dis-use,
    .dis-status,
    .dis-button {
      box-sizing: border-box;
      margin: 0 1px 1px 0;
      padding: 5px;

      .btn {
        font-size: 13px;
      }
    }

    .dis-code {
      order: 1;
      width: 250px;
      font-weight: 700;
      background: rgba($text, 0.1);
    }

    .dis-title {
      order: 2;
      width: 175px !important;
      background: rgba($text, 0.1);
    }

    .dis-use {
      order: 3;
      width: 75px;
      background: rgba($text, 0.1);
    }

    .dis-status {
      order: 4;
      width: 75px;
      background: rgba($text, 0.1);
    }

    .dis-button {
      order: 9;
    }

    &.dis-head {
      color: $gl-indigo;

      .dis-code,
      .dis-title,
      .dis-use,
      .dis-status {
        font-weight: 400;
        background: none;
      }
    }
  }
}

// SERVICES
.service {
  &__btn {
    @extend .home-v2__btn;

    &--big {
      @extend .home-v2__btn--big;
    }

    &--optional {
      @extend .home-v2__btn--optional;
    }

    &--area {
      position: relative;
      top: -30px;
      left: 0;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 768px) {
        top: 0;
      }
    }

    &--round {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 60px;
      height: 60px;
      padding: 0;
      border-radius: 500px;
    }
  }

  &__benefit {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0;
    list-style-type: none;

    @media only screen and (max-device-width: 680px) {
      flex-flow: column nowrap;
    }

    a {
      color: white;
    }

    h2 {
      color: white;
      font-weight: 200 !important;
      font-size: math.div(28px, 16px) + rem;

      @media only screen and (max-device-width: 680px) {
        font-size: math.div(22px, 16px) + rem;
      }
    }

    h3 {
      font-weight: 700 !important;
      font-size: math.div(22px, 16px) + rem;
      text-transform: unset !important;

      @media only screen and (max-device-width: 680px) {
        font-size: math.div(20px, 16px) + rem;
      }

      &:before {
        display: inline-block;
        margin-right: 0.25rem;
        font-weight: 300;
        font-family: $fa-style-family, sans-serif;
      }
    }

    .fold {
      height: 0;
      overflow-y: hidden;

      &ed {
        h3 {
          &:before {
            transform: rotate(0deg);
            content: fa-content($fa-var-chevron-right);
          }
        }
      }
    }

    .expand {
      &ed {
        h3 {
          &:before {
            font-weight: 300;
            font-family: $fa-style-family, serif;
            transform: rotate(90deg);
            content: fa-content($fa-var-chevron-right);
          }
        }
      }
    }

    h2,
    h3 {
      margin: 0;
      line-height: 1.15;
    }

    & > li > ul {
      padding: 0;
      list-style-type: none;
    }

    & > lh,
    & > li {
      width: calc((100% / 3) - 20px);

      @media only screen and (max-device-width: 680px) {
        width: calc((100% / 1) - 0px);
      }
    }

    &s {
      width: 100%;

      @media only screen and (max-device-width: 680px) {
        min-width: 100%;
        max-width: 100%;
      }

      &--separator {
        width: 100%;

        &:before {
          display: block;
          width: 50%;
          margin: 0 auto;
          border-bottom: solid 1px rgba(white, 0.25);
          content: '';
        }
      }

      &--suppliers {
        display: flex;
        justify-content: flex-end;
        text-align: right;

        // align-items: flex-end;

        b {
          // background-color: rgba($text, .25);
          padding: 0.25rem 1rem;
          color: white;

          // font-style: oblique;
          font-size: math.div(24px, 16px) + rem;
          letter-spacing: initial;
          border: solid 2px color.adjust($in-yellow, $hue: 15);
          border-radius: 5px;
        }
      }

      &--soon {
        padding-bottom: 40px !important;
        text-align: center;
      }

      &--opp {
        order: 3;

        & > ul {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: space-around;
          padding-left: 0;
          list-style-type: none;
        }
      }
    }
  }

  &__page {
    @extend .home-v2__page;
    padding: 20px;

    & > ul {
      // width: 100%;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: space-around;
      padding-left: 0;
      list-style-type: none;
    }

    ul,
    ol {
      .expand {
        transition: height 0.15s ease-in-out;
        animation-name: fold-expand;
        animation-duration: 0.25s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: backwards;

        p {
          // color: $text !important;
          letter-spacing: -0.01rem;
        }

        &able {
          cursor: pointer !important;

          & > h3 {
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;

            &:before {
              color: color.mix($text, white, 80%);
              transition: transform .15s ease-in-out;
            }
          }
        }

        &ed {
          h3 {
            &:before {
              content: fa-content($fa-var-chevron-right);
            }
          }
        }
      }

      .fold {
        animation-name: fold-expand;
        animation-duration: 0.25s;
        animation-iteration-count: 1;
        animation-direction: reverse;
        animation-fill-mode: forwards;
      }

      @keyframes fold-expand-icon-spin {
        0% {
          content: 'f054';
        }

        100% {
          content: 'f078';
        }
      }

      @keyframes fold-expand {
        0% {
          height: 1px;
          overflow: hidden;
        }

        100% {
          height: 3rem;
          overflow: visible;
        }
      }
    }

    h1 {
      width: 100%;
      margin: 0 0 40px;

      @media only screen and (max-device-width: 680px) {
        margin: 0 0 20px;
      }
    }

    h2 {
      @media only screen and (max-device-width: 680px) {
        margin: 0 0 20px;
      }
    }

    p {
      margin: 0;
      padding-top: 16px;
      color: color.mix($text, white, 10%) !important;
      font-size: math.div(20px, 16px) + rem;
      line-height: 1.5 !important;

      @media only screen and (max-device-width: 680px) {
        font-size: math.div(18px, 16px) + rem;
      }
    }

    h1,
    p {
      line-height: 1.15;
    }

    &--why {
      gap: 16px;
      width: 75%;
      padding-top: 32px;

      @media only screen and (max-device-width: 680px) {
        width: unset;
      }

      &-column {
        &:not(:first-of-type) {
          border-bottom: solid 1px color.mix($gl-indigo, white, 60%);

          & > p {
            padding-top: 16px;
            padding-left: 24px;
            border-top: solid 1px color.mix($gl-indigo, white, 60%);

            &:before {
              margin-left: calc(-1rem - 10px);
              padding-right: 8px;
              // color: color.mix($gl-purple, white, 30%);
              font-family: $fa-style-family, serif;
              content: fa-content($fa-var-people-group);
            }
          }
        }

        &-bullet {
          display: flex;
          flex-flow: column nowrap;
          gap: 16px;
          padding-top: 8px;
          padding-left: 40px;
          list-style-type: none;

          & > li {
            $color2: $gl-indigo;
            padding-left: 8px;
            color: color.adjust($color2, $lightness: 25%) !important;

            &:before {
              margin-left: calc(-1rem - 10px);
              padding-right: 8px;
              // color: color.mix($gl-purple, white, 40%);
              font-weight: 300;
              font-family: $fa-style-family, serif;
              content: fa-content($fa-var-check);
            }
          }
        }

        &-choice {
          @extend .service__page--why-column-bullet;
          flex-flow: row nowrap;
          gap: 32px;
          margin-bottom: 0;
          padding-top: 16px;
          padding-left: 16px;
          border-top: solid 1px color.mix($gl-indigo, white, 60%);

          & > li {
            width: calc(50% - 16px);

            &:before {
              display: inline-block;
              // color: color.mix($gl-purple, white, 30%);
              font-weight: 400;
            }

            &:first-child  {
              &:before {
                content: fa-content($fa-var-person);
              }
            }

            &:last-child {
              &:before {
                content: fa-content($fa-var-person-dolly);
              }
            }

            & > h2 {
              display: inline-block;
              margin: 0;
              font-weight: 700 !important;
            }
          }
        }

        p {
          padding: 0;
          text-align: left !important;
        }
      }

      lh {
        width: 100%;
      }
    }

    &--one {
      $color1: $gl-purple;
      $color2: $gl-indigo;
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-blend-mode: multiply;

      h1 {
        color: color.adjust($color2, $hue: 180deg) !important;
      }

      h2,
      h3,
      p {
        color: color.adjust($color2, $lightness: 25%) !important;
      }
    }

    &--marketplace {
      $color1: $ma-blue;
      $color2: $ma-cyan;

      h1 {
        color: color.adjust($color2, $hue: 240deg) !important;
      }

      h2,
      h3,
      ul,
      ol {
        color: color.adjust($color2, $lightness: 45%);
      }

      p:not(.opp-ld) {
        color: color.adjust($color2, $lightness: 35%);
      }

      a {
        color: white;

        &:visited {
          color: white;
        }
      }
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-blend-mode: multiply;
    }

    &--consortium {
      $color1: $co-red;
      $color2: $co-orange;

      h1 {
        color: color.adjust($color2, $hue: 120deg) !important;
      }

      h2,
      h3,
      ul,
      ol {
        color: color.adjust($color2, $lightness: 35%);
      }

      p:not(.opp-ld) {
        color: color.adjust($color2, $lightness: 25%);
      }

      a {
        color: white;

        &:visited {
          color: white;
        }
      }
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-blend-mode: multiply;
    }

    &--investment {
      $color1: $in-green;
      $color2: $in-yellow;

      h1 {
        color: color.adjust($color2, $hue: 120deg) !important;
      }

      h2,
      h3 {
        color: color.adjust($color2, $lightness: 35%);
      }

      p {
        color: color.adjust($color2, $lightness: 25%);
      }

      a {
        color: white;

        &:visited {
          color: white;
        }
      }
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-blend-mode: multiply;
    }

    &--additional {
      $color1: color.mix($text, white, 70%);
      $color2: color.mix($text, white, 20%);
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      min-height: 100vh !important;
      background: linear-gradient(0deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
      background-blend-mode: multiply;

      h1 {
        color: $text !important;
      }

      h2,
      h3 {
        color: color.adjust($color2, $lightness: 35%);
      }

      p {
        color: color.adjust($color2, $lightness: 25%);
      }
    }

    &--exclusive {
      min-height: auto !important;
    }

    &--about {
      ul {
        & > li {
          p {
            text-align: justify;
          }
        }
      }
    }
  }

  &s {
    @extend .discounts;

    .serv-view {
      @extend .dis-view;

      form {
        display: flex;
        width: 100%;
      }

      .serv-name,
      .serv-use,
      .serv-desc,
      .serv-button {
        margin: 0 1px 1px 0;
        padding: 10px;

        .btn {
          font-size: 13px;
        }
      }

      .serv-name {
        order: 1;
        width: 200px;
        font-weight: 700;
        background: rgba($text, 0.1);
      }

      .serv-use {
        order: 3;
        width: 200px;
        background: rgba($text, 0.1);
      }

      .serv-desc {
        order: 6;
        width: 400px;
        background: rgba($text, 0.1);
      }

      .serv-button {
        order: 5;
      }

      &.serv-head {
        color: $gl-indigo;

        .serv-name,
        .serv-title,
        .serv-use,
        .serv-desc,
        .serv-status {
          font-weight: 400;
          background: none;
        }
      }
    }
  }

  &__item {
    padding: 16px !important;
    background: color.mix($text, white, 60%);
    border-radius: 4px;
    transition: height 0.25s ease-in-out;
  }
}

// COMPANIES
.companies {
  @extend .services;

  .com-view {
    @extend .serv-view;
    width: 100%;
    border-bottom: 1px solid rgba($text, 0.3);

    &:first-child {
      border-bottom-width: 3px;
    }

    &:last-child {
      border: none;
    }

    &:nth-child(even) {
      background: rgba($sl-gray, 0.9);
    }

    form {
      display: flex;
      width: 100%;
    }

    .com-name,
    .com-country,
    .com-web,
    .com-use,
    .com-desc,
    .com-button {
      margin: 0 1px 1px 0;
      padding: 10px;

      .btn {
        font-size: 13px;
      }
    }

    .com-name {
      order: 1;
      width: 150px;
      font-weight: 700;
    }

    .com-country {
      order: 2;
      width: 100px;
    }

    .com-use {
      order: 3;
      width: 200px;
    }

    .com-web {
      order: 4;
      width: 200px;
    }

    .com-button {
      order: 5;
    }

    &.com-head {
      color: $gl-indigo;

      .com-name,
      .com-country,
      .com-web,
      .com-use,
      .com-desc,
      .com-status,
      .com-button {
        font-weight: 400;
        background: none;
      }
    }
  }
}

.pre-regions {
  color: $gl-purple;
}

// REGIONS
.regions {
  display: flex;
  flex-flow: column nowrap;

  li {
    width: 100%;
    border-bottom: 1px solid rgba($text, 0.3);

    &:first-child {
      border-bottom-width: 3px;
    }

    &:last-child {
      border: none;
    }

    &:nth-child(even) {
      background: rgba($sl-gray, 0.9);
    }
  }

  .reg-view {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    color: $text;

    form {
      display: flex;
      width: 100%;
    }

    .reg-code,
    .reg-name,
    .reg-territory,
    .reg-status,
    .reg-button {
      width: math.div(100%, 6);
      padding: 10px;

      .btn {
        font-size: 13px;
      }
    }

    .reg-code {
      order: 3;
    }

    .reg-name {
      order: 2;
    }

    .reg-territory {
      order: 1;
    }

    .reg-status {
      order: 4;
    }

    .reg-button {
      order: 5;
    }

    &.reg-head {
      color: $gl-indigo;

      .reg-code,
      .reg-name,
      .reg-territory,
      .reg-status {
        font-weight: 400;
        background: none;
      }
    }
  }
}

.legalese {
  ul,
  ol {
    li {
      color: $text;
      line-height: 1.5rem;
      list-style-type: disc;
    }
  }

  ol li {
    list-style-type: decimal;
  }

  blockquote {
    margin: 20px 0;
    padding-left: 20px;
    font-weight: 600;
    font-size: 2rem;
    font-style: italic;
    border-left: solid 5px $gl-purple;

    cite {
      font-weight: 300;
      font-size: 1rem;

      &:before {
        display: inline;
        content: '~ ';
      }
    }
  }
}

/* =============================================
=            TEXT            =
============================================= */

.center-txt {
  text-align: center !important;
}

.left-txt {
  text-align: left !important;
}

.right-txt {
  text-align: right !important;
}

/* =============================================
=            SELECT            =
============================================= */

.selectdiv {
  position: relative; /* Don't really need this just for demo styling */
  &:-ms-expand {
    display: none;
  }

  /* IE11 hide native button (thanks Matt!) */
  &:after {

    /* left line */
    position: absolute;

    /* Adjust for position however you want */
    top: 11px;
    right: 11px;
    padding: 0 0 2px;
    color: $text;
    font: 24px Consolas, monospace;
    border-bottom: 0 solid #999;
    transform: rotate(90deg);
    content: '>';
    pointer-events: none;
    @media only screen and (min-device-width: 320px) and (max-device-width: 620px) {
      right: 22px;
    }
  }

  & select {

    /* Add some styling */
    display: block;
    width: 100%;
    height: 50px;
    margin: 5px 0;
    padding: 0 24px;
    color: $text;
    font-size: 16px;
    line-height: 1.75;
    word-break: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    appearance: none;

    option {
      padding: 7px 0;
      vertical-align: middle;
    }
  }
}

.selectmultidiv {
  @extend .selectdiv;

  .tokens-container {
    display: block;
    width: 100%;
    min-height: 50px;
    margin: 5px 0;
    padding: 0 10px 5px;
    color: $text;
    font-size: 16px;
    line-height: 1.75;
    word-break: normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;

    .focus {
      &:hover,
      &:focus {
        color: white !important;
        background: $text !important;
      }
    }
  }

  &:after {
    content: '' !important;
  }

  select {
    height: auto;
    overflow-y: scroll;
  }
}

/* =============================================
=            BUTTONS            =
============================================= */

.button {
  &.in-tile {
    display: inline-block !important;
    margin: 0 auto;
    padding: 5px 10px !important;
  }
}

.btn-default {
  width: auto;
  padding: 5px 10px;
  color: $text;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  background: color.mix(rgb(188, 190, 192), #fff, 40%);
  border: 0;
  border-radius: 20px;

  &:hover {
    background-color: color.adjust(rgba(188, 190, 192, 40%), $lightness: -10%) !important;
  }
}

.btn-ghost {
  @extend .btn-default;
  color: $text !important;
  background: transparent !important;
  border: solid 1px $text !important;

  &:hover {
    color: black !important;
    background: transparent !important;
    border: solid 1px black !important;
  }
}

.btn {
  &__circle {
    width: 40px;
    height: 40px;
    padding: 0;

    &--mini {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30px;
      height: 30px;
      padding: 5px;
    }
  }

  &__rectangle {
    z-index: 88;
    float: right;
    height: 40px;
    padding: 0 20px;
    color: $gl-purple;
    background: white;
    border: solid 1px $gl-purple;
    border-radius: 3px;

    &--min {
      @extend .btn__rectangle;
      height: auto;
      padding: 0;

      span {
        transform: scale(0.75);
      }
    }

    @media only screen and (max-device-width: 480px) {
      padding: 0 8px;
    }

    &:hover {
      color: white !important;
      background: $gl-purple !important;
      border-color: color.adjust($gl-purple, $lightness: -15%);
    }
  }
}

.btn-mega {
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 15px;
}

.btn-tiny {
  height: 15px;
  margin: 0;
  padding: 0 10px !important;
  font-size: math.div(10px, 16px) + rem !important;
  text-transform: uppercase;
}

.btn-mini {
  transform: scale(0.6, 0.6);
}

#blg-listen--frm {
  height: 15px;
}

.btn-primary {
  @extend .btn-default;
  color: #fff !important;
  background-color: $gl-purple !important;

  &:hover {
    background-color: color.adjust($gl-purple, $lightness: -10%) !important;
  }

  &.consortium {
    background-color: $co-red !important;

    &:hover {
      background-color: color.adjust($co-red, $lightness: -10%) !important;
    }
  }

  &.investment {
    background-color: $in-green !important;

    &:hover {
      background-color: color.adjust($in-green, $lightness: -10%) !important;
    }
  }

  &.marketplace {
    background-color: $ma-blue !important;

    &:hover {
      background-color: color.adjust($ma-blue, $lightness: -10%) !important;
    }
  }
}

.btn-secondary {
  @extend .btn-primary;
  background-color: $in-yellow !important;

  &:hover {
    background-color: color.adjust($in-yellow, $lightness: -10%) !important;
  }
}

.btn-refine {
  @extend .btn-primary;
  margin: 0 !important;
  font-weight: 700 !important;
  background-color: rgba($text, 0.3) !important;

  &:hover {
    background-color: color.adjust(rgba($text, 0.3), $lightness: -10%) !important;
  }
}

.btn-tertiary {
  @extend .btn-primary;
  background-color: red !important;

  &:hover {
    background-color: color.adjust(red, $lightness: -10%) !important;
  }
}

footer {
  z-index: 2;

  // float: left;
  width: 100%;
  padding: 20px 0;
  color: #fff;
  background-color: $text;
  @media only print {
    display: none;
  }

  .footer-wrap {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    justify-content: space-between;
    padding: 16px;

    a {
      color: white;
      text-decoration: none;
    }
    @media only screen and (max-width: 640px) {
      .footer-products,
      .footer-additionals,
      .footer-share,
      .footer-contact {
        gap: 32px;
        width: calc((100% / 2) - 16px) !important;
        margin: 0 !important;
      }
    }

    ul {
      margin: 0;
      padding-left: 0;
      line-height: 2;
      list-style-type: none;

      // border-top: solid 1px rgba(white, 0.25);
    }

    .footer-products {
      order: 1;
      width: calc(25% - 16px);
    }

    .footer-additionals {
      order: 2;
      width: calc(25% - 16px);
    }

    .footer-share {
      order: 3;
      width: calc(25% - 16px);

      li {
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        i {
          transform: scale(0.75);
        }

        .svg-icon {
          fill: white;
        }
      }
    }

    .footer-contact {
      order: 4;
      width: calc(25% - 16px);

      &-address,
      &-telephone {
        a {
          color: rgba(white, 1);
          text-decoration: none;
        }
        color: rgba(white, 0.6);
      }
    }

    .container {
      &.centered {
        padding: 20px 5% !important;
      }
    }
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    a {
      order: 1;
      margin-top: -5px;
    }

    & a:hover {
      .cls-1,
      .cls-2,
      .cls-3,
      .cls-4 {
        fill: rgba(white, 0.3);
      }
    }

    #global-logo {
      width: 200px;
      height: 70px;

      * {
        stroke: none;
      }
    }
  }

  p.copyright {
    display: block;
    width: 100%;
    color: rgba(white, 0.6);

    a {
      color: white;
    }

    & + p {
      display: block;
      margin-top: 10px;
    }
  }

  .social {
    a {
      color: white;
      text-decoration: none;

      #twtr,
      #fcbk {
        position: relative;
        top: 5px;
      }

      .svg-icon {
        fill: #eee;
      }

      & + a {
        margin-left: 20px;
      }

      .soc-txt {
        margin-left: 8px;
      }
    }
  }
}

.finetype {
  font-size: 12px;
}

.fineprint {
  @extend .finetype;
}

.rights {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 20px !important;
  padding-bottom: 0 !important;

  p {
    order: 2;
    font-size: 0.8rem;

    a {
      text-decoration: none;
    }

    &.ownedby,
    &.developer {
      width: 100%;
      margin-bottom: 20px;
      color: rgba(white, 0.6);

      a {
        color: white;
      }
    }
  }
}

.no-decorate {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.inp {
  position: relative;
  top: -50px;
  left: 0;
  z-index: 2000;
  float: left;
  width: 100%;
  margin-bottom: -40px;

  p {
    @extend .alert;
    @extend .alert-info;
    margin-top: 0;
    padding: 5px 0;
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    box-shadow: 1px 1px 3px rgba(black, 0.1);

    &:before {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      padding: 0 8px;
      color: white;
      font-weight: 900;
      font-style: normal;
      background: rgba($ma-cyan, 0.7);
      border-radius: 20px;
      content: 'i';
    }
  }
}

@keyframes system-message-action {
  0% {
    position: relative;
    top: -40px;
    opacity: 1;
  }

  25% {
    position: relative;
    top: 0;
    opacity: 1;
  }

  80% {
    position: relative;
    top: 0;
    opacity: 1;
  }

  90% {
    position: relative;
    top: 10px;
    opacity: 1;
  }

  100% {
    position: relative;
    top: -80px;
    opacity: 1;
  }
}

.sys-msg {
  $color1: color.mix($text, white, 50%);
  z-index: 91;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 8px 16px 0;

  &:empty {
    display: none;
  }

  h1 {
    margin: 0;
    padding-bottom: 8px;
    color: $text;
    font-weight: 700;
    font-size: math.div(18rem, 16) !important;
    line-height: 1;
  }

  div .alert {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    order: 1;
    min-width: 50%;
  }

  .close-msg {
    order: 2;
  }
}

.close-msg {
  &:before {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 5px;
    color: currentColor;
    font-weight: 700;
    font-size: math.div(16rem, 16);
    font-family: $fa-style-family, sans-serif;
    line-height: 1;
    cursor: pointer;
    content: fa-content($fa-var-times);
  }

  &:hover {
    color: white;
  }
}

.hide {
  display: none;
  width: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  transition: all 0.75s ease-in ease-out;
}

.visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap; /* added line */
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.always {
  &__visible {
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 10;

    &--disabled {
      display: none;
    }

    & > .btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 54px;
      height: 54px;
      color: white;
      background: $gl-purple !important;

      // border: solid 2px $gl-purple;
      border-radius: 500px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 25%);

      &:hover,
      &:focus {
        // background: white !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 100%);
      }
    }

    &--msg {
      position: fixed;
      right: 50px;
      bottom: 70px;
      z-index: 11;
      display: none;
      min-width: 200px;
      max-width: 300px;
      background-color: mintcream;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 100%);
      opacity: 0;
      transition: opacity 0.25s ease-in-out;

      &:hover + .btn {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 100%);
      }

      h4 {
        margin: 0;
        padding: 20px 20px 0;
        font-weight: 700;
        font-size: 14px;
      }

      p {
        margin: 0;
        padding: 0 20px 10px;
        font-size: 14px;
      }

      a {
        margin: 0 20px 20px;
        text-align: center;
      }
    }

    a {
      display: block;
      margin-top: 10px;
      color: royalblue;
      font-weight: 600;
      font-size: 16px;

      // text-decoration: none;
    }

    & > button:hover,
    & > button:focus {
      & ~ .always__visible--msg {
        display: unset;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
      }
    }

    &:hover {
      .always__visible--msg {
        display: unset;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
      }
    }

    svg {
      width: 24px;
    }
  }
}

.animate {
  transition: 5s ease-in ease-out !important;
}

.big-four-oh-four {
  font-size: 6rem;
  line-height: 1.5;
  text-align: center;

  span {
    display: block;
    font-size: 12rem;
    line-height: 1;
  }
}

/* =============================================
=            Resource            =
============================================= */

.resource {
  &-centre {
    margin-bottom: 20px;

    &:not(body) {
      padding: 0 16px;
    }

    p {
      padding: 0;
    }

    &__area {
      display: flex;
      flex-flow: row wrap;
      gap: 0;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      list-style-type: none;

      h2,
      p {
        margin: 0;
        padding: 0;
      }

      & > ul {
        list-style-type: none;
      }

      &--item {
        z-index: 6;
        display: flex;
        flex-flow: column nowrap;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: calc((100% / 4) - 1px);

        // height: 250px;
        margin: 0 0 1px;
        padding: 22px;
        background: white;
        box-shadow: 0 0 3px color.mix(slategray, white, 15);
        transform: scale(1);
        transition: transform ease-in 0.25s;

        @media only screen and (max-device-width: 768px) {
          width: calc((100% / 2) - 1px);
        }
        @media only screen and (max-device-width: 480px) {
          width: 100%;
        }

        &:hover,
        a:focus .resource-centre__area--item {
          z-index: 8;
          box-shadow: 0 1px 8px color.mix(slategray, white, 15), 0 -1px 8px color.mix(slategray, white, 15), 1px 0 8px color.mix(slategray, white, 15), -1px 0 8px color.mix(slategray, white, 15);
          transform: scale(1.02);
          transition: all ease-out 0.25s;

          @media only screen and (max-device-width: 768px) {
            transition: all ease-out 0.25s;
          }
        }

        a:focus .resouce-centre__area--item {
          color: red !important;
        }

        & > .fas {
          flex: 0, 0, auto;
          order: 1;
          color: color.mix($gl-indigo, white, 100);
        }

        h2 {
          flex: 0 0 auto;
          order: 2;
          font-weight: 700 !important;
          letter-spacing: -0.1px;
        }

        h2,
        h2 > a {
          color: $text;
        }

        p {
          flex: 1 0 auto;
          order: 2;
          line-height: 1.2;
          text-align: center;
        }

        &:not(:nth-child(3n)) {
          margin: 0 1px 1px 0;

          @media only screen and (max-device-width: 768px) {
            margin: 0 0 1px;
          }
        }
      }
    }
  }

  /* ----------  Blog  ---------- */

  &-blog {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
    margin: 0;
    padding: 0 16px;
    list-style-type: none;

    &__card {
      display: flex;
      flex-flow: column wrap;
      width: calc((100% - (24px * 3)) / 4);
      overflow: hidden;

      @media only screen and(max-width: 1200px) {
        width: calc((100% - (24px * 2)) / 3);
      }
      @media only screen and(max-width: 640px) {
        width: calc((100% - (24px * 1)) / 2);
      }
      @media only screen and(max-width: 480px) {
        width: 100%;
      }

      &--photo {
        display: block;
        width: 100%;
        background: white;
        background-size: cover;
        border: 1px solid color.mix($text, white, 10);
        border-radius: 8px;

        // min-height: 173px;
        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 16 / 9) {
          position: relative;
          height: 0;
          padding-top: 56.25%;
          overflow: hidden;
        }
      }

      &--title {
        flex: 2 0 auto;
        margin: 0;
        padding: 8px 0 0;
        font-weight: 700 !important;
        font-size: math.div(16rem, 16);
        line-height: 1.2;
        letter-spacing: -0.1px;

        a {
          color: $text;
          text-decoration: none;
        }
      }

      &--info {
        display: flex;
        flex-flow: row wrap;
        padding: 8px 0 16px;
        font-size: math.div(12rem, 16);
        list-style-type: none;

        li {
          display: inline-block;
          color: color.mix($text, white, 40);

          a {
            color: color.mix($text, white, 40);
          }

          &:first-child {
            padding-right: 8px !important;
          }
        }
      }
    }

    &__section,
    &__tag {
      @extend .resource-training__info--skill;

      &s {
        @extend .resource-training__info--skills;
        padding: 16px 0 0 !important;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    &__text-to-speech {
      display: flex;
      flex-flow: row nowrap;
      gap: 8px;
      height: unset !important;
      padding: 16px 0;

      button {
        $color1: color.mix($text, white, 40);

        // outline: 1px solid white;
        color: $color1 !important;
        border-color: $color1 !important;
      }
    }

    &__article {
      &--header {
        @extend .applet, .max;
        @media only screen and (max-width: 768px) {
          padding: 0 16px;
        }
      }

      &--info {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;
        align-items: center;
        margin: 0;
        padding: 16px 0 0;
        color: color.mix($text, white, 40);
        font-size: math.div(14rem, 16);
        list-style-type: none;

        li {
          &:last-child {
            font-size: math.div(24rem, 16);

            a {
              color: orange;
            }
          }
        }

        a {
          color: color.mix($text, white, 70);
        }
      }
    }

    &__feed {
      padding: 16px !important;
      border-top: solid 1px color.mix($text, white, 20);

      .resource-blog__feed--heading {
        @extend .applet, .max;
        padding: 0;
      }

      .search__results,
      .resource-blog,
      .resource-trainings__related,
      .resource-templates__related {
        @extend .applet, .max;
      }

      &:nth-child(even) {
        background: color.mix($text, white, 5);
      }

      h2 {
        margin: 0;
        color: color.mix($text, white, 60);
        font-weight: 700 !important;
        font-size: math.div(16rem, 16) !important;
        line-height: 1.2;
        text-transform: uppercase;
      }

      & > ul {
        margin: 0;
        padding: 8px 0 0 !important;
      }
    }

    &s {
      & > figure {
        flex-flow: column nowrap;
        margin: 0;
        background: color.mix($text, white, 100);

        img {
          height: fit-content;

          @media only screen and (max-width: 640px) {
            width: 100%;
            height: fit-content;
          }
        }

        & > span {
          @extend .applet, .max;
          display: flex;
          align-items: center;
          justify-content: space-around;
          background: color.mix($text, white, 90);

          // background-position: center;
          // background-size: cover;
        }

        figcaption {
          padding: 8px 16px;
          color: color.mix($text, white, 40);
          font-size: math.div(14rem, 16);
          letter-spacing: -0.1px;
          text-align: right;
          background: color.mix($text, white, 2.5);
        }
      }

      h1 {
        margin-bottom: 0;
        padding-top: 32px;
        font-weight: 600;
        line-height: 1.2;
      }

      & > h1 {
        margin-bottom: 0;
        padding: 16px;
        line-height: 1.5;

        a {
          padding-left: 16px;
          color: orange;
        }
      }

      p {
        padding: 0 !important;
      }

      .art-content {
        @extend .applet, .max;

        // width: 640px;
        margin: 0 auto !important;

        @media only screen and (max-width: 768px) {
          padding: 0 16px;
        }

        aside {
          h3,
          p {
            color: color.mix($text, white, 40);
          }
        }
      }
    }
  }

  /* ----------  Template  ---------- */

  &-template {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0 20px;

    @media only screen and (max-width: 768px) {
      flex-flow: column nowrap;
      width: 100%;
    }

    h1 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      padding: 0;
      letter-spacing: -0.1px;
    }

    &__one {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-around;
      margin-right: 20px;

      &--container {
        width: 75%;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      img {
        width: 100%;
        padding: 2px;
        border: solid 1px color.mix($text, white, 20%);
      }
    }

    &__one,
    &__two {
      width: calc((100% / 2) - 10px);

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    &__information {
      margin-bottom: 20px !important;
      padding-bottom: 20px !important;
      border-bottom: solid 1px color.mix($text, white, 20%);
    }

    &__category a {
      color: dodgerblue;
    }

    &__category,
    &__guidance {
      margin-bottom: 10px;
    }

    &__counter {
      margin-right: 20px;
    }

    &__counter,
    &__date {
      display: inline-block;
    }

    &__description {
      margin-bottom: 20px !important;

      p + p {
        padding-top: 20px;
      }

      ul,
      ol {
        padding: 0 0 0 40px;
        list-style-position: outside;
        list-style-type: unset;
      }

      ol {
        list-style-type: decimal;
      }
    }

    &__tags {
      margin: 0 0 20px !important;
      padding-bottom: 20px !important;
      border-bottom: solid 1px color.mix($text, white, 20%);

      li {
        display: inline-block;
        color: color.mix($text, white, 80%);

        &:not(:last-child) {
          margin-right: 10px;
          margin-bottom: 10px;
        }

        i {
          margin-right: 5px;
        }

        &:not(:first-child) {
          padding: 3px;
          border: solid 1px color.mix($text, white, 80%);
        }
      }
    }

    &__upsell {
      @extend .opportunity-view__upsell;

      p,
      p * {
        color: white !important;
      }
      background: $in-green;
    }

    &__document {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      padding: 8px;
      color: dodgerblue;
      text-decoration: none;
      border: solid 1px dodgerblue;
      border-radius: 5px;
    }

    /* ----------  Templates  ---------- */

    &s {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding: 0 20px 20px;

      h1,
      h3 {
        margin-bottom: 0;
      }

      h2,
      h3 {
        margin-top: 0;
      }

      h3 {
        color: $gl-indigo;
      }

      p {
        margin-top: 0;
      }

      ul {
        display: flex;
        flex-flow: row wrap;
        margin-top: 0;
        padding: 0;
        list-style-type: none;

        article {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;

          img {
            display: block;
            width: 100%;
            background-size: cover;
          }
        }
      }

      &-category {
        @extend .resource-templates;

        &__tngs {
          @extend .resource-templates__tngs;

          &--title {
            @extend .resource-templates__tngs--title;
          }

          &--availability {
            @extend .resource-templates__tngs--availability;
          }

          &--counter {
            @extend .resource-templates__tngs--counter;
          }
        }
      }

      &__categories {
        gap: 16px;
        margin: 0;
        padding: 16px 0 32px !important;

        @media only screen and (max-device-width: 480px) {
          flex-flow: column nowrap;
        }

        li {
          position: relative;
          padding: 8px 16px;
          overflow: hidden;
          background: color.mix($text, white, 20);
          background-size: cover;
          background-blend-mode: overlay;
          border: solid 1px color.mix($text, white, 50);
          border-radius: $round-button;

          @media only screen and (max-device-width: 480px) {
            width: 100%;

            img {
              display: none !important;
            }
          }
        }

        a {
          display: block;
          text-decoration: none;

          span {
            color: $text;
            isolation: isolate;

            // text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white ;
          }

          &:hover {
            border-color: dodgerblue;
          }

          img {
            height: 75px !important;
          }
        }

        &--name {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 8px 16px;
          background: color.mix($text, white, 80%) !important;
          border-radius: $round-button;

          & > h2,
          & > h1 {
            margin: 0;
            color: white;
            font-weight: 400 !important;
            font-size: math.div(16rem, 16);
            line-height: 1;
            text-align: center;

            span {
              // display: block;

              @media only screen and (max-device-width: 480px) {
                display: unset;
                line-height: 1.5;
              }
            }
          }
        }

        &--title {
          display: block;
          padding: 4px 8px;
          white-space: pre !important;
        }

        &--new {
          padding: 16px;

          &est {
            a {
              height: 150px;
              padding: 0;
              color: $text;
              background: color.mix($text, white, 5%);
              border-radius: 3px;

              &:hover {
                background: color.mix($text, white, 5%);
              }

              span {
                text-shadow: unset;
              }
            }
          }
        }
      }

      &__tngs {
        $color1: $gl-purple;
        gap: 20px;

        li {
          display: flex;
          flex-flow: column nowrap;
          width: calc((100% - (20px * 3)) / 4);
          overflow: hidden;
          background: white;
          border: solid 1px color.mix($text, white, 10%);
          border-radius: 8px;

          & > span:first-of-type {
            width: 100%;
            height: 120px;
            background: color.mix($text, white, 70);
            background-size: cover;
            opacity: 0.8;

            & > a {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          a {
            color: unset;
            text-decoration: none;
            background: transparent !important;
          }

          &:hover,
          &:focus,
          &:active {
            background: color.mix($color1, white, 70);
            border: solid 1px color.mix($color1, white, 70%);

            & > span:first-of-type {
              opacity: 1;
            }

            *,
            .free ~ span,
            .basic ~ span,
            .standard ~ span,
            .premium ~ span {
              color: white;
            }
          }

          @media only screen and (max-width: 768px) {
            width: calc((100% - (20px * 1)) / 2);

            &:nth-child(even) {
              margin-right: 0;
            }
          }
          @media only screen and (max-width: 480px) {
            width: 100%;
            margin-right: 0;
          }

          img {
            opacity: 0.7;
            transition: all 0.25s ease-in-out;
          }
        }

        &--info {
          padding: 8px 16px 16px;
          border-top: solid 1px color.mix($text, white, 10);
        }

        &--title {
          display: block;
          flex: 1 0 auto;
          width: 100%;
          padding: 8px 16px;
          color: $color1;
          font-weight: 600;
          letter-spacing: -0.1px;
          text-transform: capitalize;
        }

        &--guidance {
          @extend .resource-templates__tngs--title;
          flex: 1 0 auto;
          padding-top: 4px;
          font-weight: 600;
          text-transform: unset;
          border-width: 0;
        }

        &--availability {
          @extend .resource-templates__tngs--title;
          display: inline-block;
          flex: 0 0 auto;
          width: unset;
          padding: 0;
          color: $gl-indigo;
          font-weight: 600;
          font-size: math.div(12rem, 16);
          text-transform: uppercase;
          border-width: 0;

          .free,
          .basic,
          .standard,
          .premium {
            padding-right: 4px;
          }

          .free {
            $color2: orange;
            color: color.mix($color2, white, 50);

            & ~ span {
              color: color.mix($color2, white, 100);
            }
          }

          .basic {
            $color2: $text;
            color: color.mix($color2, white, 30);

            & ~ span {
              color: color.mix($color2, white, 80);
            }
          }

          .standard {
            $color2: red;
            color: color.mix($color2, white, 40);

            & ~ span {
              color: color.mix($color2, white, 90);
            }
          }

          .premium {
            $color2: blue;
            color: color.mix($color2, white, 40);

            & ~ span {
              color: color.mix($color2, white, 90);
            }
          }
        }

        &--counter {
          @extend .resource-templates__tngs--title;
          display: inline-block;
          flex: 0 0 auto;
          width: auto;
          padding: 3px 16px 16px 0;
          color: $text;
          color: color.mix($text, white, 50%);
          background: unset;
          border-width: 0;
        }
      }

      &__related {
        @extend .resource-templates__tngs;
        width: 100% !important;

        li {
          a {
            @extend .resource-templates__tngs, a;
            background: color.mix($text, white, 2.5%);
            background-size: cover;

            &:hover {
              background: color.mix($text, white, 10%);
              border-color: dodgerblue;
            }
          }
        }
      }

      &__contact {
        display: flex;
        gap: 24px;
        align-items: center;
        margin: 0 -16px -20px;
        padding: 16px;
        background: white;

        span:first-of-type {
          color: color.mix($text, white, 20%);
        }

        a {
          color: $gl-indigo;
          font-weight: 700;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  /* ----------  Training  ---------- */

  &-training {
    $color1: $gl-purple;
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    padding: 0 16px;

    @media only screen and (max-width: 1000px) {
      flex-flow: column nowrap;
    }

    /* ----------  Trainings  ---------- */

    &s {
      @extend .resource-templates;

      &__categories {
        @extend .resource-templates__categories;

        &--name {
          @extend .resource-templates__categories--name;
        }
      }

      &__popular {
        gap: 20px;

        $borderRadius: 8px;

        li {
          display: flex;
          flex-flow: column nowrap;
          width: calc((100% - (20px * 2)) / 3);
          background: color.mix($text, white, 0%);
          border: solid 1px color.mix($text, white, 10);
          border-radius: $borderRadius;
          transition: width 0.25s ease-in-out;

          @media only screen and (max-width: 768px) {
            width: calc((100% - (20px * 1)) / 2);
          }

          @media only screen and (max-width: 480px) {
            width: calc((100% - (20px * 0)) / 1);
          }

          &:hover {
            background: color.mix($text, white, 90%);
            border-color: color.mix($text, white, 90%);

            span {
              color: white !important;

              &.resource-trainings__popular--photo {
                opacity: 1;
              }
            }
          }

          a {
            display: flex;
            flex-flow: row wrap;
            gap: 10px;
            width: 100%;
            height: 100%;
            color: unset;
            text-decoration: none;

            span {
              // display: block;
              // white-space: pre;

              i {
                margin-right: 4px;
                color: color.mix($text, white, 40);
                font-size: math.div(13rem, 16);
              }
            }
          }
        }

        &--photo {
          display: block;
          width: 100%;
          height: 150px;
          background-size: cover;
          border-top-left-radius: $borderRadius;
          border-top-right-radius: $borderRadius;
          opacity: 0.8;
        }

        &--title {
          flex: 1 0 auto;
          width: 100%;
          padding: 8px 16px;
          color: $gl-purple;
          font-weight: 600;
          font-size: math.div(16rem, 16);
          letter-spacing: -0.1px;
          text-align: left;
          text-transform: capitalize;
        }

        &--guidance {
          @extend .resource-templates__tngs--guidance;
          padding: 8px 16px 16px !important;
          font-weight: 600;
          letter-spacing: -0.5px;

          // border-bottom: solid 1px $gl-indigo;
        }

        &--info {
          display: block;
          width: 100%;
          padding: 8px 16px 16px;
          border-top: 1px solid color.mix($text, white, 10);
          border-bottom-right-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }

        &--level {
          padding: 0 !important;
          color: $gl-indigo;
          font-weight: 400;
          font-size: math.div(14rem, 16);
          letter-spacing: -0.1px;
          text-transform: uppercase;

          &:before {
            padding-right: 4px;
            color: color.mix($text, white, 30);
            font-weight: 700;
            font-family: $fa-style-family, serif;
            content: fa-content($fa-var-graduation-cap);
          }
        }

        &--price {
          padding: 0 !important;
          color: $gl-indigo;
          font-weight: 600;
          font-size: math.div(16rem, 16);
          letter-spacing: -0.5px;
        }

        &--level,
        &--price,
        &--duration,
        &--date {
          display: inline-block;
          padding: 0;
        }

        &--duration,
        &--date,
        &--counter {
          color: color.mix($text, white, 100%);
          font-weight: 700;
        }

        &--duration {
          display: unset !important;
          color: color.mix($text, white, 90);
          font-weight: 400;
        }

        // span {
        //   $color1: mix($text, white, 70%);
        //   text-shadow: -1px -1px 0 $color1, 1px -1px 0 $color1, -1px 1px 0 $color1, 1px 1px 0 $color1 !important;
        // }
      }

      &__novice,
      &__intermediate,
      &__expert {
        @extend .resource-trainings__popular;
      }

      &__related {
        @extend .resource-trainings__popular;

        &--photo {
          @extend .resource-trainings__popular--photo;
        }

        &--info {
          @extend .resource-trainings__popular--info;
          padding: 0;
          border: unset;
        }

        &--title {
          @extend .resource-trainings__popular--title;
          padding: 16px;
        }

        &--counter {
          @extend .resource-templates__tngs--counter;
        }

        // li {
        //   width: calc((100% - (20px * 2)) / 3);

        //   @media only screen and (max-width: 768px) {
        //     width: calc((100% - (20px * 1)) / 2);
        //   }
        //   @media only screen and (max-width: 480px) {
        //     width: 100%;
        //   }
        // }

        // &--title {
        //   @extend .resource-templates__tngs--title;
        //   font-size: (18px / 16px) + rem;
        //   font-weight: 700;
        //   letter-spacing: -.5px;
        //   color: white;
        // }

        // span {
        //   $color1: mix($text, white, 70%);
        //   text-shadow: -1px -1px 0 $color1, 1px -1px 0 $color1, -1px 1px 0 $color1, 1px 1px 0 $color1 !important;
        // }
      }

      &__contact {
        @extend .resource-templates__contact;
      }
    }

    &__one,
    &__two {
      min-width: 100%;
      max-width: 1200px;
      margin-bottom: 16px;

      span i {
        margin-right: 4px;
        color: color.mix($text, white, 30%);
      }
    }

    h1 {
      width: 100%;
      margin: 0;
      padding: 0;
      color: $gl-indigo;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -0.1px;
      text-transform: capitalize;
    }

    h2 {
      margin: 0;
      color: color.mix($text, white, 70%);
      font-weight: 600;
      font-size: math.div(16px, 16px) + rem;
      letter-spacing: -0.5px;
      text-transform: uppercase;
    }

    h3 {
      width: 100%;
      margin: 20px;
      color: $gl-indigo;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.1px;
      text-align: center;
      text-transform: uppercase;
    }

    &__cover {
      width: 100%;
      height: 250px;
      margin-bottom: 16px;
      background: color.mix($text, white, 20%);
      background-size: cover;
      border-radius: 8px;
    }

    &__frame {
      position: relative;
      width: 100%;
      margin-bottom: 16px;
      padding-bottom: 55%;
      background: color.mix($text, white, 90);

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__one {
      display: flex;
      gap: 20px;
      order: 2;
      margin-bottom: 0;

      @media only screen and (max-width: 1000px) {
        width: 100%;
      }
      @media only screen and (max-width: 640px) {
        flex-flow: column nowrap;
      }
    }

    &__two {
      order: 2;

      .resource-trainings,
      .resource-templates {
        padding: 32px 0 0;
      }

      .resource-trainings__related,
      .resource-templates__related {
        padding-top: 8px;
      }
    }

    &__author {
      display: flex;
      flex-flow: column nowrap;
      gap: 2px;
      margin-bottom: 16px;
      padding: 16px 0 0;

      h2 + h3 {
        padding-top: 8px;
      }

      h3 {
        color: $gl-indigo !important;
        font-weight: 400;
        font-size: math.div(16rem, 16);
        text-align: unset;

        & + p {
          padding-top: 4px;
        }
      }

      h3,
      p {
        margin: 0 !important;
        color: color.mix($text, white, 60);
        line-height: 1.2;
      }

      p {
        font-size: math.div(14rem, 16);

        & + p {
          padding-top: 0 !important;
        }

        &:empty {
          display: none;
        }
      }

      &--profile {
        width: 100%;
        padding: 16px;
        background: white;
        border: 1px solid color.mix($text, white, 10);
        border-radius: 4px;

        a {
          padding-top: 8px !important;
          color: dodgerblue !important;
          font-style: normal;
        }
      }

      &--accounts {
        display: flex;
        gap: 16px;

        a {
          color: dodgerblue !important;
          font-size: math.div(26px, 16px) + rem;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    &__info {
      order: 2;
      width: 70%;
      padding: 0 !important;

      @media only screen and (max-width: 1000px) {
        width: 70%;
      }
      @media only screen and (max-width: 640px) {
        width: 100%;
      }

      &--desc {
        padding: 16px;
        background: color.mix($text, white, 0%);
        border: 1px solid color.mix($text, white, 10);
        border-radius: 4px;
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: math.div(18px, 16px) + rem;
        line-height: 1.5;
        letter-spacing: -0.1px;

        & + p {
          padding-top: 16px;
        }
      }

      ol,
      ul {
        font-weight: 400;
        font-size: math.div(18px, 16px) + rem;
        line-height: 1.5;
        letter-spacing: -0.1px;
      }

      // span {
      //   width: unset;
      //   display: inline-block;
      // }

      &--skill,
      &--tag {
        $color1: color.mix($text, white, 40%);
        padding: 4px 12px;
        color: $color1 !important;
        font-size: math.div(13px, 16px) + rem;
        letter-spacing: -0.25px;
        text-transform: lowercase;
        background: white;
        border: 1px solid $color1;
        border-radius: 500px;

        &s {
          display: flex;
          flex-flow: row wrap;
          gap: 8px;
          padding: 0 16px;

          h2 {
            width: 100%;
            margin: 0;
            padding-top: 16px;
            color: color.mix($text, white, 70%);
            font-size: math.div(16px, 16px) + rem;
            letter-spacing: -0.25px;
            text-transform: uppercase;
          }
        }
      }
    }

    &__core {
      @extend .resource-training__info--desc;
      margin-bottom: 16px;

      &--category {
        display: block;
        padding-top: 8px;
        color: color.mix($text, white, 30);
        color: $gl-indigo;
        font-weight: 300;
        font-size: math.div(14rem, 16);

        // text-transform: uppercase;
        letter-spacing: -0.1px;
      }

      &--info {
        display: flex !important;
        flex-flow: row wrap;
        gap: 16px;
        width: 100% !important;
        padding-top: 16px;
      }

      &--level {
        @extend .resource-trainings__popular--level;
      }

      &--level,
      &--duration,
      &--participants,
      &--published,
      &--counter {
        font-size: math.div(14rem, 16);
        white-space: pre;
      }

      &--duration,
      &--participants,
      &--published,
      &--counter {
        color: color.mix($text, white, 90) !important;
        font-weight: 400;
      }
    }

    &__additional {
      order: 3;
      width: calc(30% - 0px);
      height: fit-content;
      padding: 32px 16px 0;
      background: white;
      border: 2px solid color.mix($text, white, 10%);
      border-color: color.mix($gl-indigo, white, 20%);
      border-radius: 8px;
      @media only screen and (max-width: 640px) {
        order: unset;
        width: calc(50%);
      }

      @media only screen and (max-width: 480px) {
        width: calc(100%);
      }

      &--category {
        padding: 16px 16px 0;
        color: dodgerblue;
      }

      &--price {
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        padding: 0 0 16px;
        color: $text;
        font-weight: 700;
        font-size: math.div(32px, 16px) + rem;
        line-height: 1;
        letter-spacing: -1px;
        text-align: center;
      }

      &--preview {
        &:not(:empty) {
          display: block !important;
          padding: 16px 0 0;
          color: color.mix($text, white, 60%);
          font-weight: 300;
          font-size: math.div(16px, 16px) + rem;

          // text-align: center;
          letter-spacing: -0.1px;
        }
      }

      &--record {
        margin: 20px;
        padding: 4px;
        border: 1px solid color.mix($gl-indigo, white, 30%);

        table {
          width: 100%;
          margin: 0;
          border-collapse: collapse;

          td {
            color: $gl-indigo;

            i {
              padding-right: 1px;
              color: color.mix($gl-indigo, white, 30%);
            }
          }

          td + td + td {
            padding: 4px 8px;
            color: $text;
          }
        }
      }

      &--form {
        button {
          width: 100%;
          padding: 8px 16px;
          color: white;
          font-weight: 700;
          font-size: math.div(20px, 16px) + rem;
          letter-spacing: -0.1px;
          white-space: nowrap;
          background: linear-gradient(color.mix($gl-indigo, white, 80%), $gl-indigo);
          border: 2px solid color.mix($gl-indigo, white, 100%);
          border-radius: 500px;

          &:hover {
            color: color.mix($gl-indigo, white, 40%);
            background: linear-gradient($gl-indigo, color.mix($gl-indigo, white, 80%));
            border: 2px solid color.mix($gl-indigo, white, 100%);
          }

          i {
            color: color.mix($gl-indigo, white, 30%);
          }
        }
      }

      &--na {
        display: block !important;
        width: 100%;
        padding: 0 0 8px;
        color: color.mix($text, white, 40);
        font-size: math.div(26rem, 16);
        text-align: center;

        &-price {
          @extend .resource-training__additional--na;
          padding: 0 0 16px;
          color: color.mix($text, white, 20);
          font-weight: 600;
          font-size: math.div(16rem, 16);

          i {
            color: color.mix($text, white, 10) !important;
          }
        }
      }

      span {
        display: inline-block;
      }
    }

    &__guidance {
      margin: 16px 0 0;
      padding-top: 16px;
      line-height: 1.2;
      border-top: 1px solid color.mix($text, white, 10);
    }

    &-payment {
      &s {
        &__cover {
          @extend .resource-training__cover;
        }

        &__name {
          margin-bottom: 0;
          padding-top: 16px !important;
          color: $gl-indigo;
          font-weight: 600 !important;
          font-size: math.div(24rem, 16);
        }

        &__author {
          margin: 0;
          padding: 0 !important;
          color: color.mix($text, white, 50);
        }

        &__price {
          margin-top: 10px;
          padding: 0 !important;
          color: $gl-indigo;
          font-weight: 300;
          font-size: math.div(24rem, 16);
          list-style-type: none;
        }
      }

      &__guarantee {
        &s {
          padding: 16px;
          list-style-type: none;
          background: white;
          border: 1px solid color.mix($text, white, 10);

          li:before {
            color: $gl-indigo;
            font-family: $fa-style-family, serif;
            content: fa-content($fa-var-check-circle);
          }
        }
      }

      fieldset {
        padding: 0 !important;
      }

      table {
        width: 100%;
        margin: 0;
        border-collapse: collapse;

        th,
        td {
          padding: 4px 8px;

          &:last-of-type {
            color: $gl-indigo;
          }
        }

        th {
          width: calc(100% / 3);
          white-space: nowrap;
          border-bottom: 1px solid color.mix($text, white, 90);
        }

        td {
          font-size: math.div(20rem, 16);
        }
      }
    }

    &__upsell {
      @extend .opportunity-view__upsell;

      p {
        background: $in-green;
      }
    }
  }

  /* ----------  Event  ---------- */

  &-event {
    @extend .resource-training;
    padding: 16px;

    &s {
      @extend .resource-templates;
      gap: 32px;

      h1 {
        margin: 0 -20px;
        padding: 16px;
        background: color.mix($gl-purple, white, 10%);
      }

      &__popular {
        li {
          display: flex;
          flex-flow: row wrap;
          gap: 16px;
          width: calc((100% - (20px * 1)) / 2);
          margin-bottom: 20px;
          padding: 16px;
          background: color.mix($text, white, 0%);
          background: linear-gradient(color.mix($text, white, 0%), color.mix($text, white, 5%));
          border: unset;
          border-radius: 500px;
          box-shadow: 0 2px 4px color.mix($text, white, 5%), 0 2px 16px color.mix($text, white, 10%);

          &:hover {
            background: linear-gradient(color.mix($text, white, 5%), color.mix($text, white, 0%));

            // box-shadow: 0 2px 4px color.mix($text, white, 15%), 0 2px 16px color.mix($text, white, 10%);
          }

          @media only screen and (max-width: 1000px) {
            width: 100%;
          }
          @media only screen and (max-width: 640px) {
            flex-flow: column nowrap;
            gap: 32px;
            align-items: center;
            justify-content: space-around;
          }

          a {
            color: unset;
            text-decoration: none;
          }
        }

        &--date {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: space-around;
          width: 120px;
          height: 120px;
          margin: -16px 0 -16px -16px;
          padding: 16px;
          color: white;
          background: color.mix($text, white, 40%);
          background-blend-mode: multiply;
          border-radius: 500px;

          & > span {
            text-shadow: 2px 2px 4px color.mix($text, white, 100%);

            &:first-child {
              font-weight: 700;
              text-transform: uppercase;

              & + span {
                font-weight: 700;
                font-size: math.div(36rem, 16);

                & + span {
                  font-weight: 700;
                  font-size: math.div(20rem, 16);
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        &--right {
          display: flex;
          flex-flow: column nowrap;
          gap: 4px;
          justify-content: space-between;
          width: calc(100% - (120px));
        }

        &--title {
          font-size: math.div(24rem, 16);
          line-height: 1;
        }

        &--info {
          @extend .resource-trainings__popular--info;
          display: flex;
          flex-flow: row wrap;
          gap: 16px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 8px 0 0;

          @media only screen and (max-width: 640px) {
            flex-flow: column nowrap;
            gap: 8px;
            align-items: center;
            justify-content: space-around;
            padding-top: 16px;
          }
        }

        &--level {
          @extend .resource-trainings__popular--level;
        }

        &--duration {
          @extend .resource-trainings__popular--duration;
          font-size: math.div(14rem, 16);
        }

        &--booked {
          color: color.mix($text, white, 20%);
          font-size: math.div(20rem, 16);
          letter-spacing: -1px;
          text-transform: uppercase;
        }
      }

      &__contact {
        @extend .resource-templates__contact;
      }
    }

    &__one,
    &__two {
      @extend .resource-training__one;
    }

    &__cover {
      @extend .resource-training__cover;
    }

    &__frame {
      @extend .resource-training__frame;
    }

    &__two {
      @extend .resource-training__two;
    }

    &__author {
      @extend .resource-training__author;

      &--profile {
        @extend .resource-training__author--profile;
      }

      &--accounts {
        @extend .resource-training__author--accounts;
      }
    }

    &__info {
      @extend .resource-training__info;

      &--desc {
        @extend .resource-training__info--desc;
      }

      &--skill,
      &--tag {
        @extend .resource-training__info--tag;

        &s {
          @extend .resource-training__info--tags;
        }
      }
    }

    &__core {
      @extend .resource-training__core;

      &--category {
        @extend .resource-training__core--category;
      }

      &--info {
        @extend .resource-training__core--info;
      }

      &--level {
        @extend .resource-training__core--level;
      }

      &--duration,
      &--participants,
      &--published,
      &--counter {
        @extend .resource-training__core--duration;
      }
    }

    &__additional {
      @extend .resource-training__additional;

      &--category {
        @extend .resource-training__additional--category;
      }

      &--price {
        @extend .resource-training__additional--price;
      }

      &--preview {
        @extend .resource-training__additional--preview;
      }

      &--record {
        @extend .resource-training__additional--record;
      }

      &--form {
        @extend .resource-training__additional--form;
      }

      &--na {
        @extend .resource-training__additional--na;
      }
    }

    &-payment {
      &s {
        &__name {
          margin-bottom: 0;
          padding-top: 16px !important;
          color: $gl-indigo;
          font-weight: 600 !important;
          font-size: math.div(24rem, 16);
        }

        &__scheduled {
          margin-top: 0 !important;
          margin-bottom: 0;
          padding-top: 0 !important;
          color: $gl-indigo;
          font-weight: 600 !important;
          font-size: math.div(24rem, 16);
        }

        &__host {
          margin: 0;
          padding: 0 !important;
          color: color.mix($text, white, 50);
        }

        &__price {
          margin-top: 10px;
          padding: 0 !important;
          color: $gl-indigo;
          font-weight: 300;
          font-size: math.div(24rem, 16);
          list-style-type: none;
        }
      }

      &__guarantee {
        &s {
          padding: 16px;
          list-style-type: none;
          background: white;
          border: 1px solid color.mix($text, white, 10);

          li:before {
            color: $gl-indigo;
            font-family: $fa-style-family, serif;
            content: fa-content($fa-var-check-circle);
          }
        }
      }

      fieldset {
        padding: 0 !important;
      }

      table {
        width: 100%;
        margin: 0;
        border-collapse: collapse;

        th,
        td {
          padding: 4px 8px;

          &:last-of-type {
            color: $gl-indigo;
          }
        }

        th {
          width: calc(100% / 3);
          white-space: nowrap;
          border-bottom: 1px solid color.mix($text, white, 90);
        }

        td {
          font-size: math.div(20rem, 16);
        }
      }
    }

    &__upsell {
      @extend .resource-training__upsell;
    }
  }

  /* ----------  Video  ---------- */

  &-video {
    &s {
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        list-style-type: none;

        @media only screen and (max-device-width: 480px) {
          flex-flow: column nowrap;
          padding: 0 20px;
        }

        li {
          $color1: color.mix($text, white, 100%);
          $color2: color.mix($text, white, 70%);
          width: calc((100% - 40px) / 3);
          margin-bottom: 20px;
          padding: 0;
          overflow: hidden;

          // border-radius: 5px;
          background: linear-gradient($color1, $color2);
          box-shadow: 0 2px 2px rgba(black, 0.75);
          transform: scale(1);
          transition: all ease-in-out 0.25s;

          @media only screen and (max-device-width: 480px) {
            width: 100%;

            &:not(:nth-child(3n)) {
              margin-right: 0;
            }

            h2 {
              font-size: math.div(16px, 16px) + rem;
            }
          }

          img {
            opacity: 0.6;
            transition: opacity ease-in-out 0.25s;

            @media only screen and (max-device-width: 768px) {
              opacity: 1;
            }
          }

          &:hover {
            & img {
              opacity: 1;
              transition: opacity ease-in-out 0.25s;
            }

            // transform: scale(1.05);
            // box-shadow: 0 0 8px rgba(slategray, .75);
          }

          &:not(:nth-child(3n)) {
            margin-right: 20px;
          }

          a,
          img {
            display: block;
            width: 100%;
            margin: 0;
          }

          a {
            text-decoration: none;
          }

          h2 {
            margin: 0 !important;
            padding: 0.8em 0.75rem !important;
            color: white;
            font-weight: 400 !important;
            font-size: math.div(18px, 16px) + rem;
            line-height: 1.2;
            text-shadow: 1px 2px 1px $text;
          }
        }
      }

      h1,
      p {
        width: 100%;
      }

      p {
        margin-top: 0;
        padding: 0;
      }

      a {
        margin-right: 0;
      }
    }

    h1 {
      margin: 0;
    }

    p {
      margin-top: 0;
      padding: 0;
    }

    &__frame {
      @extend .full-width;
      position: relative;
      padding-bottom: 56.25%;

      // margin-top: -20px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (max-device-width: 768px) {
      h1 {
        margin-bottom: 20px;
        line-height: 1;
      }

      h1,
      p {
        padding: 0 20px;
      }
    }

    &__published {
      padding: 0.1rem 0.5rem;
      font-size: math.div(16px, 16px) + rem;
      line-height: 1;
      background: $sl-gray;
      border-radius: 3px;

      @media only screen and (max-device-width: 768px) {
        margin-left: 20px;
      }
    }
  }

  /* ----------  Research  ---------- */

  &-research {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    @media only screen and(max-device-width: 1000px) {
      padding: 0;
    }
    @media only screen and(max-device-width: 768px) {
      padding: 0 20px;
    }

    &__set {
      &s {
        display: flex;
        flex-flow: row wrap;

        // justify-content: space-between;
        gap: 16px;
        margin-bottom: 20px;
      }

      &--overlay {
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: color.mix($text, white, 60%);
        background-size: cover;
        transition: background-blend-mode 0.25s ease-in-out;

        &:hover {
          background-blend-mode: multiply;
          transition: background-blend-mode 0.25s ease-in-out;
        }
      }
    }

    h1 + p {
      margin-top: 0;
      margin-bottom: 40px;
      padding: 0;
    }

    article {
      width: calc((100% / 3) - 16px);
      height: 318px;

      // margin: 0 20px 20px 0;
      overflow: hidden;
      background: white;
      box-shadow: 0 1px 1px rgba(black, 0.55);

      @media only screen and(max-device-width: 480px) {
        width: 100%;
        margin-right: 0;
        overflow: auto;
      }

      * {
        text-decoration: none;
      }

      a {
        width: fit-content;
        height: fit-content;
        margin: 0;
        padding: 0;

        div {
          $color1: rgba(black, 0.85);
          $color2: rgba(black, 0);
          position: relative;
          bottom: -75%;
          margin: 0;
          padding: 20px;
          background: linear-gradient($color2, $color1);
          transition: bottom ease-in-out 0.25s;

          @media only screen and(max-device-width: 480px) {
            bottom: -50%;
            background: white;

            h2,
            li {
              color: $text;
              text-shadow: initial;
            }
          }
        }

        &:hover {
          div {
            bottom: -55%;
            transition: bottom ease-in-out 0.25s;

            @media only screen and(max-device-width: 480px) {
              bottom: -50%;
            }
          }
        }
      }

      &:not(:nth-of-type(2n + 1)) {
        margin-right: 0;
      }

      h2 {
        margin: 0 0 16px;
        color: white;
        line-height: 1.2;
        text-shadow: 0 1px 2px black;

        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        @media only screen and(max-device-width: 480px) {
          margin-bottom: 20px;
          overflow: auto;
          white-space: normal;
          text-overflow: initial;
        }
      }

      img {
        width: 100%;
        height: auto;
        margin: 0;
        opacity: 1;
        transition: opacity ease-in-out 0.25s;

        @media only screen and(max-device-width: 480px) {
          margin-bottom: 0;
        }
      }

      ul {
        padding: 0;
        list-style-type: none;

        li {
          color: white;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }

    &__form {
      @extend .resource-research;

      // flex-flow: row nowrap;

      h1 {
        margin-bottom: 0;

        @media only screen and (max-device-width: 768px) {
          margin: 20px 0;
          line-height: 1.2;
          letter-spacing: -0.05em;
        }
      }

      h2,
      p {
        margin: 0 0 10px;
      }

      p {
        padding: 0;
      }

      &-count {
        $color1: $gl-indigo;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: space-around;
        padding: 16px 32px;
        font-weight: 500;
        font-size: math.div(16rem, 16);
        text-align: center;

        &:not(:last-of-type) {
          border-right: 4px solid $color1;
          @media only screen and (max-device-width: 640px) {
            border-right: 0;
            border-bottom: 4px solid $color1;
          }
        }

        & > span.statsCounter {
          font-weight: 600;
          font-size: math.div(64rem, 16);
        }

        &s {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-around;
          padding: 32px 0;

          h1 {
            width: 100%;
          }

          h2 {
            display: flex;
            width: 100%;
            color: $color1;
            line-height: 1;
            border: 4px solid $color1;
            border-radius: 20px;
            @media only screen and (max-device-width: 640px) {
              flex-flow: column wrap;
            }
          }
        }
      }

      &--clear-search {
        clear: left;
        width: auto;
        height: auto;
        margin: 0;
        padding: 15px;
        color: $co-red;

        // display: inline-block;
        text-align: right;
        background: transparent;

        &:hover {
          background: color.mix($text, white, 20%);
        }
      }

      &--help {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 20px;

        h1 {
          width: 100%;
        }

        @media only screen and (max-device-width: 768px) {
          flex-flow: column;
          margin-bottom: 0;
        }
      }

      &--filters {
        $color1: $sl-gray;
        $color2: color.mix($sl-gray, white, 25%);
        order: 1;
        width: calc((100% / 2) - 10px);
        margin-right: 20px;
        padding: 20px;
        background: linear-gradient($color2, $color1);
        border: unset;

        @media only screen and (max-device-width: 768px) {
          width: 100%;
          margin: 0 0 20px;
        }

        select {
          height: 40px;
          padding: 0.25rem 0.5rem;
        }

        span.resource-research__form--filter {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &--company {
        display: inline-block;
        width: calc(100% - 5rem - 15px);
      }

      &--year {
        display: inline-block;
        width: 5rem;
        margin-right: 0 !important;
      }

      &--search-input {
        @extend .resource-faqs__search--input;
      }

      &--sidebar {
        @extend .resource-research__form--filters;
        display: flex;

        // align-items: center;
        // justify-content: space-around;
        flex-flow: row wrap;
        order: 2;
        margin-right: 0;
        background: unset;
        border: 1px solid color.mix($in-yellow, white, 50%);

        ul {
          list-style-type: none;

          li {
            &:before {
              margin: 0 1rem 0 -1.9rem;
              color: $gl-indigo;
              font-weight: 700;
              font-family: $fa-style-family, serif;
              content: fa-content($fa-var-check);
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }

      &--result {
        &s {
          * {
            font-family: Arial, Helvetica, sans-serif;

            // margin-top: 0 !important;
            line-height: 1.2;
            letter-spacing: -0.01rem;

            .fa {
              font-family: $fa-style-family, sans-serif;
            }
          }

          h2 {
            color: dodgerblue;
            font-size: math.div(13px, 16px) + rem;

            span {
              display: block;
            }
          }

          h3 {
            font-weight: 700;
            font-size: math.div(16px, 16px) + rem;
          }

          table {
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;

            th,
            td {
              padding: 0.35em 0.5em;
              border: solid 1px slategray;
            }

            thead {
              text-align: left;

              tr {
                h3 {
                  margin: 0;
                  color: white !important;
                }

                .resource-research__form--result-company,
                .resource-research__form--result-contract,
                .resource-research__form--result-amount {
                  background: color.mix($text, white, 25) !important;
                }
                background: color.mix($text, white, 75%);
              }
            }

            tr {
              &:nth-child(even) {
                background: mintcream;
              }
            }

            .resource-research__form--result-company {
              width: 50%;
            }

            .resource-research__form--result-contract {
              width: auto;
            }

            .resource-research__form--result-amount {
              width: 9em;
              text-align: right;
            }

            td {
              span {
                &:first-of-type {
                  text-transform: capitalize !important;
                }
              }
            }
          }
        }

        &-count {
          display: inline-block;
          padding: 2px 8px !important;
          color: $gl-indigo;
          font-weight: 700;
          background: color.mix($text, white, 0%);
          border: 1px solid $gl-indigo;
          border-radius: 2px;
        }
      }

      &--additional {
        @extend .resource-research__additional;
        margin-bottom: 20px !important;
      }

      &--parameter {
        padding: 4px 8px;
        color: $gl-purple;
        font-size: math.div(13px, 16px) + rem;
        line-height: 1;

        // border-radius: 4px;
        background: color.mix($gl-purple, white, 30%);

        &:nth-child(even) {
          color: color.mix($text, white, 70%);
          background: color.mix($text, white, 10%);
        }

        &s {
          display: flex;
          flex-flow: row wrap;
          gap: 8px;
          padding: 9px !important;
          border: 1px solid color.mix($text, white, 10%);
          border-radius: 4px;

          h2 {
            font-size: math.div(16px, 16px) + rem !important;
          }
        }
      }
    }

    &__additional {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 -20px -20px;
      padding: 20px !important;
      color: dodgerblue;
      background: mintcream;

      i {
        margin-right: 20px;
        color: dodgerblue;
      }

      span {
        font-size: math.div(13px, 16px) + rem;
      }
    }
  }

  /* ----------  Survey  ---------- */

  &-survey {
    svg {
      .a,
      .b {
        fill: transparent !important;
      }

      .c,
      .d {
        fill: color.mix($co-orange, white, 80%) !important;
      }

      .f {
        fill: color.mix($co-red, $co-orange, 20%) !important;
      }
    }

    &__hero {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70vh;
      background: $in-yellow;
      background-position: center;
      background-size: cover;

      @media only screen and (max-device-width: 480px) {
        height: unset;
      }

      &--fold {
        z-index: 9;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
      }

      &--column {
        display: flex;
        flex-flow: column wrap;

        // align-items: left;
        justify-content: space-between;
        width: 50%;
        padding: 20px;

        @media only screen and (max-device-width: 480px) {
          width: 100%;
        }

        h1 {
          font-weight: 600;
          line-height: 1;
        }

        h2 {
          font-weight: 600 !important;
        }

        p {
          font-size: math.div(18px, 16px) + rem;
        }

        form {
          max-width: 300px;
          padding: 40px;
          background: color.mix($in-yellow, white, 40%);
          border-radius: 10px;
        }

        &:first-child {
          h1,
          h2,
          p {
            color: white;
          }

          h1,
          h2 {
            display: inline-block;
            width: fit-content;
            padding: 5px 10px;
            background: rgba($ma-blue, 0.75);
          }

          h1 {
            transform: rotate(2deg);
          }

          h2 {
            transform: rotate(-1deg);
          }

          p {
            padding: 5px 10px;
            color: white;
            text-shadow: 2px 2px 2px $co-orange;
            background: rgba($co-orange, 0.75);

            strong {
              font-weight: 600;
            }
          }
          padding: 40px;
        }

        &:last-child {
          width: 320px;
        }
      }
    }
  }

  /* ----------  FAQ  ---------- */

  &-faq {
    h4 {
      margin: 0;
      color: dodgerblue;
      cursor: pointer;
    }

    p {
      &:last-child {
        margin-bottom: 20px;
        color: color.adjust(mintcream, $lightness: -60%) !important;
      }

      & > button {
        color: color.adjust(mintcream, $lightness: -60%);
        background: none;
        border-color: color.adjust(mintcream, $lightness: -60%);
        border-style: solid;
        border-width: 1px;
      }
    }

    &__answer {
      padding: 20px;
      background: mintcream;

      p {
        margin: 0 0 10px;
        padding: 0;
      }
    }

    &__response {
      margin-bottom: 0 !important;
    }

    &__toggle {
      &__group {
        margin-bottom: 20px;
        text-align: right;

        i {
          transform: rotate(-45deg);
        }

        * {
          color: dodgerblue;
        }
      }
    }

    &s {
      padding: 40px 20px;

      h3 {
        margin: 0;
        font-weight: 600;
      }

      @media only screen and (max-device-width: 480px) {
        padding: 20px;
      }

      &__search {
        $color1: color.mix($in-green, white, 90%);
        $color2: $in-yellow;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        min-height: 140px;
        padding: 40px 20px;
        background: linear-gradient(355deg, $color1 10%, $color2 80%), url('../images/iStock-1151808641-01.jpg');
        background-size: cover;
        background-blend-mode: multiply;

        h1 {
          margin-top: 0;
          color: white;
          line-height: 1;
          text-align: center;
        }

        &--input {
          width: calc(100%);
          height: 50px;
          margin: 0 -99px 0 0;
          padding: 20px 100px 20px 20px;
          border: none !important;
          border-radius: 500px;

          &[value=''] + button {
            visibility: hidden;
          }

          & ~ label {
            color: red !important;
          }

          &::placeholder {
            color: color.mix($text, white, 33%);
          }

          & + button {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            color: color.mix($text, white, 50%);
            border: none;
            border-radius: 500px;

            & + button {
              position: relative;
              top: 6px;
              left: 0;
              width: 50px;
              height: 50px;
              color: $in-green;
              background: none;
              border: none;
              border-radius: 500px;
            }
          }
        }
      }

      &__other {
        background: mintcream;

        &--heading {
          padding: 40px 20px;

          @media only screen and (max-device-width: 480px) {
            padding: 20px;
          }

          h1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;

            @media only screen and (max-device-width: 480px) {
              flex-flow: row nowrap;
              align-items: flex-start;
              line-height: 1;

              i {
                margin-right: 10px;
              }
            }

            a {
              color: dodgerblue;
            }

            i {
              color: dodgerblue;
            }
          }
        }
      }
    }
  }

  /* ----------  News  ---------- */

  &-news {
    padding: 0 20px;

    &__section {
      a {
        $color1: color.mix($gl-indigo, white, 50%);
        display: inline-block;
        padding: 2px 4px;
        color: $color1;
        text-decoration: none;
        border: solid 1px $color1;

        &:hover {
          color: white;
          background: $color1;
        }
      }

      &s {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0 0 20px;
        padding: 0;
        list-style-type: none;
      }

      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }

    &__notice {
      width: calc((100% - (20px * 2)) / 3);
      background: color.mix($text, white, 5%);
      box-shadow: 0 2px 4px color.mix($text, white, 50%);

      @media only screen and (max-width: 768px) {
        width: calc((100% - (20px * 1)) / 2);
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      &:hover {
        background: color.mix($text, white, 0%);
        box-shadow: 0 2px 4px color.mix($text, white, 75%);
        transition: all ease-in-out 0.25s;
      }

      &s {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        justify-content: space-between;
      }

      a {
        text-decoration: none;

        &:hover img {
          opacity: 1;
        }
      }

      article {
        display: flex;
        flex-flow: column nowrap;
        justify-content: stretch;
      }

      img {
        width: 100%;
        opacity: 0.6;
        transition: opacity ease-in-out 0.25s;
      }

      span {
        flex-grow: 0;
        order: 1;
        height: 150px;
        overflow-y: hidden;
      }

      h2 {
        flex-grow: 1;
        order: 2;
        margin: 0;
        padding: 10px 20px 20px;
        font-weight: 600 !important;
        font-size: math.div(16px, 16px) + rem;
        line-height: 1;
      }

      ul {
        flex-grow: 0;
        order: 1;
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-bottom: solid 1px color.mix($text, white, 50%);

        li {
          width: 100%;
          padding: 10px 20px;
          color: $text;
          font-size: math.div(13px, 16px) + rem;
        }
      }

      & > article > ul,
      &s {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding: 0;
        list-style-type: none;
      }
    }
  }

  /* ----------  Notice  ---------- */

  &-notice {
    padding: 0 16px;

    h1 {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.1px;

      & + p {
        padding-top: 8px;
        color: color.mix($text, white, 50);

        span {
          text-transform: uppercase;
        }
      }
    }

    h2,
    h3,
    p {
      margin: 0;
      line-height: 1.2;
      letter-spacing: -0.1px;
    }

    h2 {
      padding-top: 16px;
      font-weight: 700 !important;
      font-size: math.div(18rem, 16);
      text-transform: uppercase;
    }

    section > h3:first-child {
      padding-top: 8px;
    }

    h3 {
      padding-top: 16px;
      font-weight: 500;

      & + p {
        padding-top: 8px;
      }
    }

    p {
      padding: 8px 0 0;
      color: color.mix($text, white, 60);

      a {
        color: dodgerblue;
      }
    }

    &__container {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      padding-top: 16px;

      & > section {
        width: calc(70% - 16px);

        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        & + section {
          width: 30%;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }

          & + section {
            width: 100%;
          }
        }
      }
    }

    &__authority,
    &__overview,
    &__summary {
      @extend .resource-notice__lot;

      h1 {
        margin: 0 !important;
      }

      h2 {
        padding: 0 !important;

        & + a {
          display: inline-block;
          padding-top: 8px;
        }
      }

      h3 {
        padding-bottom: 0 !important;
      }

      a {
        display: inline-block;
        padding-top: 8px;
        color: dodgerblue;
      }
    }

    &__desc {
      margin: 0;
      padding: 8px 0 0;
      color: color.mix($text, white, 60);
      list-style-type: none;

      li {
        line-height: 1.5;

        & + li {
          padding-top: 16px;
        }
      }

      & + a {
        display: inline-block;
        padding-top: 8px;
      }
    }

    &__mainsite {
      @extend .resource-notice__desc;
      display: flex;
      flex-flow: row wrap;
      gap: 8px;

      li {
        & + li {
          padding-top: 0;
        }

        &:not(:last-child) {
          &:after {
            display: inline-block;
            content: ', ';
          }
        }
      }
    }

    &__misc {
      @extend .resource-notice__desc;
      display: flex;
      flex-flow: row wrap;
      gap: 8px 16px;

      li {
        display: flex;
        flex-flow: row wrap;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        padding: 0 8px 0 0;
        line-height: 1;
        background: color.mix($text, white, 0);
        border: solid 1px color.mix($text, white, 5);
        border-radius: 4px;

        &:first-child {
          width: 100%;
        }

        span {
          $color1: color.mix($text, white, 00);
          $color2: color.mix($text, white, 40);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 26px;
          padding: 4px 4px 4px 8px;
          color: $color1;
          font-weight: 500;
          font-size: math.div(16rem, 16);
          letter-spacing: -0.1px;
          text-transform: uppercase;
          background: $color2;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          &:after {
            position: absolute;
            top: 0;
            right: -8px;
            border-color: transparent transparent transparent $color2;
            border-style: solid;
            border-width: 13px 0 13px 8px;
            content: '';
          }
        }

        & + li {
          padding-top: 0;
        }
      }
    }

    &__lot {
      padding: 16px;
      background: white;

      h2 {
        padding: 0 0 8px;
        border-width: 0 0 2px;
      }

      & > h3 {
        padding: 16px 0;
      }

      &s {
        & > .zero__given {
          display: flex;
          flex-flow: row nowrap;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          height: 100px;
          color: color.mix($text, white, 20);
        }
      }

      &-data {
        &--desc,
        &--misc {
          & > ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
          }
        }

        &--desc {
          li {
            & + li {
              padding-top: 8px;
            }
          }
        }

        &--misc {
          & > ul {
            @extend .resource-notice__misc;
          }
        }
      }

      &--misc {
        @extend .resource-notice__misc;

        li {
          &:first-child {
            width: auto;
          }
        }

        &-label {
          padding: 16px 0 0 !important;

          // @extend .resource-notice__cpv--label;
          color: $text;
        }
      }
    }

    &__cpv {
      &--label {
        padding: 0 !important;
        color: dodgerblue;
        text-transform: uppercase;

        & + table tr {
          &:first-child {
            border-top: 0 !important;
          }

          &:not(:first-child) {
            border-color: dodgerblue !important;
          }
        }
      }
    }

    &__award {
      margin: 0;
      padding: 0;
      list-style-type: none;

      &s {
        @extend .resource-notice__lot;
        margin-bottom: 16px;
        background: color.mix(gold, white, 60);

        & > h3 {
          padding-bottom: 0 !important;

          .fa {
            color: dodgerblue;
          }
        }

        h4 {
          margin: 0 !important;
          padding: 16px 0 0;
        }

        ul {
          margin: 0;
          color: color.mix($text, white, 60);
        }
      }

      &--received {
        display: flex;
        flex-flow: row wrap;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li:first-child {
          font-weight: 700 !important;
        }

        & span:not(:empty) {
          padding: 2px 8px;
          color: color.mix($text, white, 60);
          font-weight: 700;
          font-size: math.div(12rem, 16);
          background: color.mix($text, white, 5);
          border: solid 1px color.mix($text, white, 20);
          border-radius: 4px;
        }
      }

      &--supplier {
        padding: 16px;
        list-style-type: none;
        background: color.mix($text, white, 1.25);
        border: solid 1px color.mix($text, white, 5);
        border-radius: 4px;

        &s {
          display: flex;
          flex-flow: row wrap;
          gap: 16px;
          width: 100%;
        }

        li:not(:empty) {
          &:not(:last-child) {
            &:after {
              display: inline;
              content: ',';
            }
          }
        }
      }

      &--offer {
        @extend .resource-notice__award--received;
      }
    }

    &__dates {
      @extend .resource-notice__lot;
    }

    table {
      width: 100%;
      text-align: left;
      table-layout: fixed;
      border-collapse: collapse;

      th,
      td {
        padding: 8px;
        vertical-align: top;
      }

      th {
        color: color.mix($text, white, 80);
        font-size: math.div(14rem, 16);

        & > div {
          height: 20px;
          overflow: hidden;
          letter-spacing: -0.1px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      tr {
        &:not(:first-child) {
          border-top: solid 1px color.mix($text, white, 20);
        }

        &:first-child {
          border-top: solid 2px color.mix($text, white, 100);
        }
      }

      td {
        overflow: hidden;
        color: color.mix($text, white, 60);
        font-size: math.div(14rem, 16);
        line-height: 1.5;
        letter-spacing: -0.1px;
        white-space: normal;
        text-overflow: ellipsis;

        a {
          color: dodgerblue;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &s {
      &__search {
        padding: 0 16px;

        form {
          display: flex;
          flex-flow: column nowrap;
          gap: 8px;
          margin-bottom: 16px;
          padding: 16px;
          background: color.mix($text, white, 5);
          border: solid 1px color.mix($text, white, 20);
          border-radius: 8px;

          input,
          select {
            margin: 0;
            border: solid 1px color.mix($text, white, 20) !important;
          }

          label {
            border-width: 0 !important;
          }

          a {
            font-weight: 600;
            font-size: math.div(12rem, 16);
            letter-spacing: -0.1px;
            text-transform: uppercase;
            text-decoration: none;
          }
        }

        fieldset {
          display: flex;
          gap: 8px;
          margin: 0;
          padding: 0;
          background: none;

          & span {
            width: calc(100% / 4);
          }

          &:first-child {
            & + fieldset {
              // padding-top: 8px;

              span:last-of-type {
                display: flex;
                flex-flow: row nowrap;
                gap: 8px;
                padding-top: 32px;
              }
            }
          }
        }

        table {
          width: 100%;
          text-align: left;
          table-layout: fixed;
          border-collapse: collapse;

          th,
          td {
            padding: 8px;
            vertical-align: top;
          }

          th {
            font-size: math.div(14rem, 16);

            & > div {
              height: 20px;
              overflow: hidden;
              letter-spacing: -0.1px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          tr {
            &:not(:first-child) {
              border-top: solid 1px color.mix($text, white, 20);
            }
          }

          td {
            overflow: hidden;
            font-size: math.div(14rem, 16);
            line-height: 1.5;
            letter-spacing: -0.1px;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
              color: dodgerblue;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }

            &.wrapped {
              overflow: auto;
              white-space: pre-line;
            }
          }
        }

        &--top {
          margin-bottom: 10px;
          padding: 20px;
          text-align: center;
          background: $gl-indigo;

          h1 {
            margin-bottom: 0;
            color: white !important;
            font-weight: 700 !important;
            font-size: math.div(32px, 16px) + rem !important;
            line-height: 1.5;
            letter-spacing: -0.1px;
          }

          h2 {
            margin: 0;
            padding-top: 16px;
            color: white !important;
            font-weight: 300 !important;
            font-size: math.div(16px, 16px);
            line-height: 1.2;
            letter-spacing: -0.1px;
          }
        }

        &--cpvList-cont {
          position: relative;
        }

        &--cpvList-items {
          position: absolute;
          z-index: 99;
          width: 100%;
          margin-top: 0;
          list-style: none;
          background: white;

          li {
            padding: 2px;
            cursor: pointer;
          }

          li:hover {
            color: white;
            background: $gl-purple;
          }
        }
      }
    }
  }
}

.exportLink {
  padding: 8px 16px;
  color: color.mix($text, white, 40);
  background: color.mix($text, white, 5);
  border: 1px solid color.mix($text, white, 20);
  border-radius: 4px;

  // display: none;
}

/* =============================================
=            User            =
============================================= */

.user {
  &__timeout {
    &--content {
      position: fixed;
      top: 25%;
      left: calc(50% - 160px);
      z-index: 999;
      display: flex;
      flex-flow: column wrap;
      gap: 8px;
      justify-content: space-around;
      width: 320px;
      height: auto;
      padding: 16px;
      background: color.mix($text, white, 5);
      border: 1px solid white;

      // outline: 2px solid $text;
      box-shadow: 0 4px 8px color.mix($text, white, 40), 0 6px 12px color.mix($text, white, 80);

      @media only screen and (max-width: 320px) {
        left: 16px;
        width: calc(100% - 32px);
      }

      h1,
      p {
        line-height: 1.2;

        &:not(:empty) {
          + span {
            padding-top: 16px;
          }
        }
      }

      h1 {
        margin: -16px -16px 0 !important;
        padding: 16px !important;
        color: color.mix(red, white, 80);
        font-weight: 700 !important;
        font-size: math.div(16rem, 16);
        letter-spacing: -0.1px;
        border-bottom: 1px solid color.mix(red, white, 80);
      }

      p {
        margin: 0 !important;
        padding: 16px 0 !important;
      }

      span {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid color.mix($text, white, 10);
      }
    }

    &--body {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100%;
      padding: 16px;
      background: color.mix($text, white, 40);
      opacity: 0.9;
    }
  }
}

.survey {
  .tiny {
    display: block;
    height: auto;
    padding: 2px 5px;
    color: $gl-indigo;
    font-weight: 400;
    font-size: math.div(12px, 16px) + rem;
    line-height: 1;
  }

  select[multiple] {
    height: 0 !important;
  }
}

.SumoSelect {
  width: 100%;

  .CaptionCont {
    font-size: math.div(14px, 16px) + rem;
  }

  .search-txt {
    font-size: math.div(14px, 16px) + rem;
  }

  .select-all {
    height: auto;
    padding: 0 0 0 35px;
  }
}

/** TESTIMONIALS **/

.testimonial {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  max-width: 1000px;
  margin: 0 auto;

  & + span {
    display: block;
    padding-top: 32px;
  }

  & + & {
    padding-top: 48px;
  }

  p {
    position: relative;
    margin: 0;
    padding: 32px;
    color: $text;
    background: #fff;
    border: color.mix($text, white, 10) solid 2px;
    box-shadow: 2px 4px 8px color.mix($text, white, 30);

    .fa {
      padding: 4px;
      color: color.mix($gl-indigo, white, 40);
    }

    &:after {
      position: absolute;
      bottom: -28px;
      left: 90%;
      z-index: 1;
      display: block;
      width: 0;
      border-color: transparent #fff;
      border-style: solid;
      border-width: 0 28px 28px 0;
      content: '';
    }

    &:before {
      position: absolute;
      bottom: -32px;
      left: calc(90% - 1.25px);
      z-index: 0;
      display: block;
      width: 0;
      border-color: transparent color.mix($text, white, 10);
      border-style: solid;
      border-width: 0 30px 30px 0;
      transform: scale(1.1);
      content: '';
    }
  }

  figcaption {
    display: inline-block;
    max-width: 1000px;
    margin-top: 50px;
    padding: 16px;
    color: color.mix($gl-indigo, white, 90);
    letter-spacing: -0.2px;
    text-align: right;
    background-color: color.mix($text, white, 90);
    border: 1px solid color.mix($text, white, 10);

    cite {
      display: block;
      padding-top: 8px;
      color: color.mix($text, white, 40);
      font-weight: 700;
      font-style: normal;

      &:before {
        content: '- ';
      }
    }
  }

  &s {
    &.home-v2 {
      background: transparent;
      background-image: unset !important;

      h1 {
        padding-top: 32px;
      }

      blockquote > p {
        box-shadow: 2px 4px 8px color.mix($text, white, 70);
      }
    }

    &:not(body) {
      padding: 0 0 32px;
      background: color.mix($text, white, 5);
      background-image: url('../images/bigstock-Successful-Team--116512568.jpg') !important;
      background-repeat: no-repeat !important;
      background-size: contain;
      background-blend-mode: luminosity;

      & > h1 {
        max-width: 1000px;
        margin: 0 auto !important;
        padding: 32px 0;
        color: white;
        font-weight: 600;
        font-size: math.div(36rem, 16);
        letter-spacing: -1px;
        text-shadow: 2px 2px 2px color.mix($text, white, 90);
      }
    }

    &__more {
      display: block;
      width: 100px;
      margin: 0 auto;
      padding: 8px 16px;
      color: color.mix($text, white, 30);
      text-align: center;
      background: white;
      border: 2px solid color.mix($text, white, 10);
      border-radius: 500px;
      box-shadow: 2px 4px 8px color.mix($text, white, 30);
      animation: ping-button 0.75s infinite alternate ease-in-out;
    }
  }
}

/** FEEDBACK **/

.feedback {
  & .nav__top,
  & .nav__bottom,
  & .footer-wrap,
  & .footer-logo,
  & .always__visible {
    display: none;
  }

  & .nav__bar {
    justify-content: space-around;
    padding: 16px;

    &--logo {
      order: 1;
    }

    &--menus {
      display: none;
    }
  }

  & footer {
    background: transparent;

    & .copyright,
    & .developer {
      color: color.mix($text, white, 80);

      a {
        color: color.mix($text, white, 40);
      }
    }
  }

  &:not(body) {
    @extend .applet, .lite;
    padding: 0 16px;
  }

  h1 {
    margin: unset;
  }

  form {
    padding: 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 2px 4px 8px color.mix($text, white, 5);
  }

  &__emotion {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    // gap: 24px;
    margin: unset;
    padding: 0;
    background: transparent;

    & > input {
      &[type='radio'] {
        display: none;
        width: 0;
      }

      &:checked,
      &:hover {
        & + label {
          &[for='notUseful'] {
            i {
              color: color.mix($text, white, 90);
              background: color.mix(orange, red, 25);
            }
          }

          &[for='needsWork'] {
            i {
              color: color.mix($text, white, 90);
              background: orange;
            }
          }

          &[for='fairlyHelpful'] {
            i {
              color: color.mix($text, white, 90);
              background: color.mix(yellow, orange, 25);
            }
          }

          &[for='veryHelpful'] {
            i {
              color: color.mix($text, white, 60);
              background: color.mix(orange, yellow, 25);
            }
          }
        }
      }
    }

    label {
      width: auto;
      margin: unset;

      i {
        color: color.mix($text, white, 20);
        background: color.mix($text, white, 5);
        border: solid 2px color.mix($text, white, 2.5);
        border-radius: 500px;
      }
    }
  }

  fieldset p {
    width: 100%;
    margin: unset !important;
    padding: 8px 0 4px !important;
    color: color.mix($text, white, 80);
    line-height: 1.2;
    letter-spacing: -0.1px;

    & > input[type='checkbox'] {
      box-sizing: border-box;
      margin: 0;
      padding: 8px;
      line-height: 1;
      border: solid 1px color.mix($text, white, 20);
      border-radius: 2px;

      // color: mix($text, white, 20) !important;
      appearance: none;

      &:checked {
        padding: 0;

        // background: $gl-purple;
        border: 0;

        &:before {
          display: block;
          padding: 0 1px;
          color: $gl-purple;
          font-weight: 700;

          // line-height: 1;
          font-size: math.div(19rem, 16);
          font-family: $fa-style-family, serif;
          content: fa-content($fa-var-check-square);
        }
      }
    }
  }

  &__message {
    margin: unset;
    padding: unset;
    padding-bottom: 16px;
    background: unset;

    // border-top: 1px solid mix($text, white, 10);

    & > textarea {
      width: 100%;
      // height: 64px;
      font-size: math.div(14rem, 16);
      border: solid 1px color.mix($text, white, 10);
      border-color: color.mix($text, white, 20) !important;
      border-width: 1px !important;
      resize: none;
    }
  }
}

// FILTER
.filter {
  display: flex;
  flex-flow: row wrap;
  background-color: transparent;

  &__container {
    display: inline-block;
    order: 1;
    margin-right: 10px;
  }

  label {
    display: block;
    height: auto;
    color: $gl-indigo;
  }

  input {
    margin-bottom: 0;

    &[type='text'],
    &[type='number'],
    &[type='date'] {
      border: solid 2px $sl-gray;
      border-radius: 0 !important;
    }

    &#reference {
      width: 100px;
      border-radius: 0 !important;
    }

    &#resolved {
      width: 150px;
      border-radius: 0 !important;
    }
  }

  select {
    display: inline-block;

    /* reset */
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 0 2.5em 0 0.5em;
    line-height: 1.5em;

    /* styling */
    background-color: white;
    border: solid 2px $sl-gray;
    appearance: none;

    &.minimal {
      background-image: linear-gradient(45deg, transparent 50%, $gl-indigo 50%), linear-gradient(135deg, $gl-indigo 50%, transparent 50%), linear-gradient(to right, rgba($text, 0.5) 50%, rgba($text, 0.5) 50%);
      background-repeat: no-repeat;
      background-position: calc(100% - 20px) calc(0.5em + 3px), calc(100% - 15px) calc(0.5em + 3px), calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 0.75em;
    }

    &.minimal:focus {
      background-image: linear-gradient(45deg, $gl-indigo 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $gl-indigo 50%), linear-gradient(to right, rgba($text, 0.5), rgba($text, 0.5));
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) 0.6em, calc(100% - 20px) 0.6em, calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 0.75em;
      outline: 0;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  &__field {
    width: 1000px;
    padding-top: 5px;
    background: transparent;
    border: solid 1px $sl-gray;
  }

  &__set {
    position: relative;
    top: 0;
    left: 0;
    padding: 0 !important;
    color: $text;
    font-weight: 400;
    font-size: 13px;
    background-color: transparent;

    span {
      position: relative;
      left: 0;
      display: inline-block;
    }
  }
}

// SUPPORT
.support {
  &.support__message:first-child {
    border: solid 1px;
  }

  & .small {
    display: flex;
    background: rgba($sl-gray, 0.75);
    border: white 1px solid;

    &.support__message {
      flex-flow: column nowrap;

      &:first-child {
        border-bottom: solid 3px $text !important;
      }
    }

    &.support__header {
      flex-flow: row wrap;
      background: transparent;

      .support__label,
      .support__info {
        order: unset;
        width: auto;
        color: $gl-indigo;
      }

      .support__label,
      .support__id,
      .support__subject,
      .support__sender,
      .support__date {
        font-weight: 700;
        font-size: calc(13 / 16 * 1rem);
      }
    }

    & + .small {
      margin: 0;
    }

    &:nth-child(odd) {
      background: rgba($sl-gray, 0.5);
    }
  }

  & p {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px 0;

    .support__label {
      display: inline-block;
      order: 1;
      width: calc(110 / 1000 * 100%);
    }

    .support__info {
      display: inline-block;
      order: 2;
      width: calc(510 / 1000 * 100%);
    }
  }

  &__id {
    order: 1;
    width: calc(100 / 1000 * 100%);
    text-align: left;
  }

  &__subject {
    order: 2;
    width: calc(600 / 1000 * 100%);
  }

  &__sender {
    order: 3;
    width: calc(100 / 1000 * 100%);
    text-align: right;
  }

  &__date {
    order: 4;
    width: calc(200 / 1000 * 100%);
    text-align: right;
  }
}

/* =============================================
=            BACKGROUND            =
============================================= */

.background {
  &--none {
    background-color: transparent !important;
  }

  &--lightgray {
    background-color: $sl-gray !important;
  }

  &--black {
    background-color: black !important;
  }

  &--purple {
    background-color: $gl-purple !important;
  }
}

/* =============================================
=            TEXT            =
============================================= */

.text {
  color: $text;

  &--bold {
    font-weight: 700;
  }

  &--italic {
    font-style: italic;
  }

  &--black {
    color: black;

    &:hover {
      color: black !important;
    }
  }

  &--normal {
    font-weight: 400 !important;
    font-size: math.div(16px, 16px) + rem !important;
    font-style: normal !important;
  }

  &--tiny {
    font-size: 12px;
  }

  &--big {
    font-size: math.div(20px, 16px) + rem;
  }

  &--gray {
    color: $text;

    &:hover {
      color: $text !important;
    }
  }

  &--lightgray {
    color: rgba($text, 0.3);

    &:hover {
      color: rgba($text, 0.3) !important;
    }
  }

  &--purple {
    color: $gl-purple;

    &:hover {
      color: $gl-purple !important;
    }
  }

  &--indigo {
    color: $gl-indigo;

    &:hover {
      color: $gl-indigo !important;
    }
  }

  &--success {
    color: green;
  }

  &--failure {
    color: red;
  }

  &--underline {
    text-decoration: underline;
  }

  &--justify {
    text-align: justify;
  }

  &--nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// VIDEO
.video {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 40px);

  &__centered {
    justify-content: center;
    margin: 0 auto;

    &--small {
      width: 320px;
      height: 180px;
    }

    &--medium {
      width: 480px;
      height: 270px;
    }

    &--large {
      width: 640px;
      height: 360px;
    }
  }

  &--backdrop {
    background: rgba(black, 0.9) !important;
  }
}

html.video,
body.video {
  width: 100% !important;
}

.messages {
  p {
    margin: 0 !important;
    padding: 0 !important;

    &:before {
      margin: 0 !important;
    }

    &:after {
      margin: 0 !important;
    }
  }
}

.has {
  &-success {
    .form-control {
      border: solid 1px green;
      border-radius: 3px;

      &:focus {
        box-shadow: 0 0 10px green;
      }
    }
  }

  &-error {
    .form-control {
      border: solid 1px red;
      border-radius: 3px;

      &:focus {
        box-shadow: 0 0 10px red;
      }
    }
  }
}

.width {
  &__native {
    display: block;
    width: unset;
  }

  &__episode {
    width: 4rem;
  }

  &__label {
    display: block;
    height: 15px;
    margin: 0;

    &--hours,
    &--minutes,
    &--seconds {
      display: inline;
      margin-left: 10px;
    }

    &--hours {
      margin-left: 0;
    }
  }

  &__time--hours,
  &__time--minutes,
  &__time--seconds {
    display: inline-block;
    width: 3rem;
  }
}

.border {
  &__top {
    &--thin {
      border-top: solid 1px;
    }
  }

  &__bottom {
    &--thin {
      border-bottom: solid 1px;
    }
  }

  &--none {
    border-width: 0 !important;
  }

  &--lightgray {
    border-color: rgba($text, 0.25);
  }
}

.overlay {
  position: absolute;
  z-index: 10000000;
  float: left;
  background: rgba(black, 0.25);
}

.modal {
  position: relative;
  z-index: 99;
  float: left;
  background: white;
  background: rgba(237, 238, 241, 100%);
  background: linear-gradient(to bottom, rgba(237, 238, 241, 100%) 0%, white 100%);
  border: solid 1px $gl-purple;
  box-shadow: 0 6px 12px $text;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#edeef1', endColorstr='#ffffff', GradientType=0);

  &-close {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
    margin-left: calc(100% - 25px);
    padding: 3px 6px;
    color: black;
    background: white;
    border: solid 1px black;
    border-radius: 50%;
    cursor: pointer;
    transition: ease-in-out 0.75s;

    &:hover {
      background: rgba($text, 0.25);
    }

    &:active {
      background: rgba($gl-indigo, 0.5);
    }
  }

  #modal {
    display: block !important;
  }

  h1,
  h2,
  p {
    font-family: Arial, Helvetica, sans-serif;
  }

  h1,
  h2 {
    color: $gl-purple;
    font-weight: 300 !important;
    line-height: 1.2;

    span,
    em {
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  p {
    color: $text;
  }

  &-open {
    overflow: hidden;
  }
}

.full-width {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;

  @media screen and (max-width: 620px) {
    position: unset;
    right: unset;
    left: unset;
    width: 620px;
    margin: unset;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
    margin-right: -50vw;
    margin-left: -50vw;
  }
}

.pagination {
  margin: 0;
  padding: 10px !important;
  color: $text;
  font-size: math.div(14px, 16px) + rem !important;
  letter-spacing: -0.1px;
  text-align: center;

  &__cont span {
    display: inline;
    padding-right: 20px;
    padding-left: 20px;

    select {
      display: inline-block;
      width: 10%;
    }
  }

  &__acc {
    a {
      font-weight: 700 !important;
      font-size: math.div(14px, 16px) + rem !important;
      text-transform: none !important;
      text-decoration: underline !important;
    }
  }
}

body.modal-open .wrap,
body.modal-open header,
body.modal-open footer {
  filter: blur(5px);
}
